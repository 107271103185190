<template>
  <div class="ai-support-box">
    <img src="@/assets/images/funding/icon-ai_supporter.svg" alt="ai 아이콘">
    <p class="ml-3 mb-0" v-html="xaiMsg"></p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      msg: {
        t0: `
          투자하려는 상점의
          <span style="color: #0AA3F1;">지난달 매출</span>과
          <span style="color: #B50AF1;">AI가 예상한 다음달 매출</span>을
          확인해 보세요!
        `,
        t1: `
          투자하려는 상점의
          <span style="color: #0AA3F1;">지난달 매출</span>과
          <span style="color: #B50AF1;">AI가 예상한 다음달 매출</span>을
          확인해 보세요!
        `,
        t2: `
          <span style="color: #B50AF1;">그래프</span>를 통해 매출 AI 예측에 사용된 상점별 강점과 약점을 쉽게 확인해보세요!
        `,
      }
    }
  },
  computed: {
    xaiMsg() {
      const { params } = this.$route
      const group = params?.group || 't0'
      return this.msg[group]
    },
  },
}
</script>

<style lang="scss" scoped>
.ai-support-box {
  display: flex;
  align-items: center;

  background-color: #ffffff;
  border-radius: 8px;

  padding: 10px 16px;

  p {
    font-size: 13px;
    line-height: 1.3;
    word-break: break-all;
  }

  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
}
</style>
