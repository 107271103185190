<template>
  <canvas ref="radarChart"></canvas>
</template>

<script>
import { jsLoad } from '@/util'

export default {
  name: 'RadarChart',
  props: {
    labels: {
      type: Array,
      required: true
    },
    scores: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      chartLoaded: false,
      chart: null
    }
  },
  created() {
    this.loadChartJS()
  },
  methods: {
    async loadChartJS() {
      if (!window.Chart) {
        await jsLoad({
          id: 'chartjs-script',
          src: 'https://cdn.jsdelivr.net/npm/chart.js',
        })
        window.dispatchEvent(new Event('chartjs-loaded'))
      } else {
        this.chartLoaded = true
        this.createChart()
      }
    },
    createChart() {
      const Chart = window.Chart
      const ctx = this.$refs.radarChart.getContext('2d')
      this.chart = new Chart(ctx, {
        type: 'radar',
        data: {
          labels: this.labels,
          datasets: [{
            data: this.scores,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            pointBackgroundColor: this.scores.map(this.getPointBackgroundColor),
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 3,
            pointRadius: 5,
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            r: {
              angleLines: {
                color: 'rgba(0, 0, 0, 0.1)'
              },
              grid: {
                color: 'rgba(0, 0, 0, 0.1)'
              },
              ticks: {
                display: false,
                stepSize: 1,
                count: 4 // Set the number of grid lines to 4 to get 3 visible lines
              },
              pointLabels: {
                color: context => {
                  const index = context.index
                  const score = context.chart.data.datasets[0].data[index]
                  return this.getPointLabelColor(score)
                },
                font: context => {
                  const index = context.index
                  const score = context.chart.data.datasets[0].data[index]
                  return this.getPointLabelFont(score)
                }
              },
              suggestedMin: 0,
              suggestedMax: 3,
              beginAtZero: true
            }
          },
          plugins: {
            legend: {
              display: false
            }
          }
        }
      })
    },
    getPointBackgroundColor(score) {
      switch (score) {
        case 3:
          return 'rgba(54, 162, 235, 0.8)' // Pale blue
        case 2:
          return 'rgba(102, 204, 102, 0.8)' // Less saturated green
        case 1:
        default:
          return 'rgba(255, 99, 132, 0.8)' // Pale red
      }
    },
    getPointLabelColor(score) {
      switch (score) {
        case 3:
          return 'rgba(54, 162, 235, 1)' // Pale blue
        case 2:
          return 'rgba(102, 204, 102, 1)' // Less saturated green
        case 1:
        default:
          return 'rgba(255, 99, 132, 1)' // Pale red
      }
    },
    getPointLabelFont(score) {
      return {
        size: 18, // Increased font size
        family: 'Arial',
        weight: 'bold' // Apply bold font for all scores
      }
    },
  },
  mounted() {
    if (window.Chart) {
      this.createChart()
    } else {
      window.addEventListener('chartjs-loaded', this.createChart)
    }
  },
  beforeUnmount() {
    window.removeEventListener('chartjs-loaded', this.createChart)
    if (this.chart) {
      this.chart.destroy()
    }
  }
}
</script>

<style scoped>
</style>
