<template>
  <section class="main-new-official-stats-section">
    <div class="container">
      <div class="mnoss-accrue-container d-flex justify-center text-center">
        <div class="position--relative">
          <p>누적 투자액</p>
          <h3 class="margin-all-0 font-weight--black">
            {{ Math.floor(getWebCache.fundingAcc / 100000000) | commaFormat }}억
            {{
              Math.floor((getWebCache.fundingAcc / 10000) % 10000)
                | commaFormat
            }}만원
          </h3>
        </div>
      </div>
      <div class="mnoss-monthly-container">
        <div
          data-official-stats
          class="stats-box d-flex justify-center color--gray"
        >
          <div>
            <p>
              <span> 통합 연체율 </span>
              <span>{{ mainOfficialStats.body.totalOverdueRate }}%</span>
            </p>
            <p>
              <span> 대출 잔액 </span>
              <span
                >{{
                  mainOfficialStats.body.totalLoanBalance | commaFormat
                }}원</span
              >
            </p>
            <p>
              <span> 연체 금액 </span>
              <span
                >{{
                  mainOfficialStats.body.totalOverdueAmount | commaFormat
                }}원</span
              >
            </p>
          </div>
          <div>
            <p>
              <span> 신용 대출 연체율 </span>
              <span>{{ mainOfficialStats.body.creditOverdueRate }}%</span>
            </p>
            <p>
              <span> 대출 잔액 </span>
              <span
                >{{
                  mainOfficialStats.body.creditLoanBalance | commaFormat
                }}원</span
              >
            </p>
            <p>
              <span> 연체 금액 </span>
              <span
                >{{
                  mainOfficialStats.body.creditLoanOverdueBalance | commaFormat
                }}원</span
              >
            </p>
          </div>
          <div>
            <p>
              <span> 동산 대출 연체율 </span>
              <span>{{ mainOfficialStats.body.movableOverdueRate }}%</span>
            </p>
            <p>
              <span> 대출 잔액 </span>
              <span
                >{{
                  mainOfficialStats.body.movableLoanBalance | commaFormat
                }}원</span
              >
            </p>
            <p>
              <span> 연체 금액 </span>
              <span
                >{{
                  mainOfficialStats.body.movableLoanOverdueBalance
                    | commaFormat
                }}원</span
              >
            </p>
          </div>
        </div>
        <div class="explain-box color--gray">
          <p>계산식: 30일 이상 연체중인 채권 잔액 / 채권 총잔액</p>
          <p>
            동산채권 취급 중단 및 지속적인 연체채권의 상환으로 총잔액이 감소하여
            연체율이 상승하고 있습니다.
          </p>
        </div>
      </div>
      <p
        class="text-center mb-0 margin-t-10 color--gray"
        v-if="mainOfficialStats.baseTime"
      >
        {{ mainOfficialStats.baseTime | moment('YYYY년 MM월말 기준') }}
      </p>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      getWebCache: {
        fundingAcc: 0,
        amountRepaymentAcc: 0,
      },
      mainOfficialStats: {
        body: {
          totalLoanBalance: 0,
          totalOverdueAmount: 0,
          totalOverdueRate: 0.0,
          creditLoanBalance: 0,
          creditLoanOverdueBalance: 0,
          creditOverdueRate: 0.0,
          movableLoanBalance: 0,
          movableLoanOverdueBalance: 0,
          movableOverdueRate: 0.0,
        },
        baseTime: null,
      },
    }
  },
  created() {
    this.getMainOfficialStats()
  },
  methods: {
    async getMainOfficialStats() {
      let response = await this.$fundaApi.query(gql`
        {
          getWebCache {
            fundingAcc
            amountRepaymentAcc
          }
          getMainOfficialStats {
            idx
            body {
              totalLoanBalance
              totalOverdueAmount
              totalOverdueRate
              creditLoanBalance
              creditLoanOverdueBalance
              creditOverdueRate
              movableLoanBalance
              movableLoanOverdueBalance
              movableOverdueRate
            }
            baseTime
          }
        }
      `)
      this.getWebCache = response.getWebCache
      this.mainOfficialStats = response.getMainOfficialStats
    },
  },
}
</script>

<style lang="scss" scoped>
.main-new-official-stats-section {
  background-color: #f5f5f5;
  user-select: none;
  -ms-user-select: none;
}
.main-new-official-stats-section .mnoss-accrue-container > div > p {
  font-size: 16px;
}
.main-new-official-stats-section .mnoss-monthly-container {
  border-radius: 8px;
  background-color: #ffffff;
  max-width: 1060px;
  margin: 0 auto;
}

.main-new-official-stats-section
  .mnoss-monthly-container
  > div.stats-box
  > div
  > p {
  display: flex;
  justify-content: space-between;
  margin: 0 0 5px;
}
.main-new-official-stats-section
  .mnoss-monthly-container
  > div.stats-box
  > div
  > p
  > span:first-child {
  font-size: 14px;
}
.main-new-official-stats-section
  .mnoss-monthly-container
  > div.stats-box
  > div
  > p
  > span:last-child {
  font-size: 16px;
  letter-spacing: -0.46px;
}
.main-new-official-stats-section .mnoss-monthly-container > div.explain-box {
  margin-top: 25px;
}
.main-new-official-stats-section .mnoss-monthly-container > div.explain-box {
  border-radius: 4px;
  background-color: #f5f5f5;
  padding: 20px;
}
.main-new-official-stats-section
  .mnoss-monthly-container
  > div.explain-box
  > p {
  margin: 0 0 0 8px;
  position: relative;
  line-height: 22px;
  letter-spacing: -0.37px;
}
.main-new-official-stats-section
  .mnoss-monthly-container
  > div.explain-box
  > p::before {
  content: '∙';
  position: absolute;
  left: -8px;
}

@media screen and (min-width: 992px) {
  .main-new-official-stats-section {
    padding: 100px 0;
  }
  .main-new-official-stats-section .mnoss-accrue-container {
    margin-bottom: 30px;
  }
  .main-new-official-stats-section .mnoss-accrue-container > div {
    padding: 0 100px;
  }
  .main-new-official-stats-section .mnoss-accrue-container > div > p {
    margin-bottom: 12px;
  }
  .main-new-official-stats-section .mnoss-accrue-container > div > h3 {
    font-size: 24px;
  }
  .main-new-official-stats-section .mnoss-monthly-container {
    padding: 30px 35px 25px;
  }
  .main-new-official-stats-section
    .mnoss-monthly-container
    > div.stats-box
    > div:not(:last-child) {
    border-bottom: solid 1px white;
    border-bottom-style: dotted;
    background-image: linear-gradient(
      to top,
      #d3e0f2 33%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: right;
    background-size: 1px 4px;
    background-repeat: repeat-y;
  }
  .main-new-official-stats-section
    .mnoss-monthly-container
    > div.stats-box
    > div:nth-child(1) {
    padding-right: 4.3%;
    width: 32%;
  }
  .main-new-official-stats-section
    .mnoss-monthly-container
    > div.stats-box
    > div {
    position: relative;
    width: 33%;
  }
  .main-new-official-stats-section
    .mnoss-monthly-container
    > div.stats-box
    > div:nth-child(2) {
    padding: 0 4.3%;
    width: 36%;
  }
  .main-new-official-stats-section
    .mnoss-monthly-container
    > div.stats-box
    > div:nth-child(3) {
    padding-left: 4.3%;
    width: 32%;
  }
}

@media screen and (max-width: 991px) {
  .main-new-official-stats-section {
    padding: 50px 0;
  }
  .main-new-official-stats-section .mnoss-accrue-container {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  .main-new-official-stats-section .mnoss-accrue-container > div:first-child {
    margin-bottom: 15px;
    width: 100%;
  }
  .main-new-official-stats-section .mnoss-accrue-container > div > p {
    margin-bottom: 8px;
  }
  .main-new-official-stats-section .mnoss-accrue-container > div > h3 {
    font-size: 22px;
  }
  .main-new-official-stats-section .mnoss-monthly-container {
    padding: 25px;
  }
  .main-new-official-stats-section .mnoss-monthly-container > div.stats-box {
    flex-wrap: wrap;
  }
  .main-new-official-stats-section
    .mnoss-monthly-container
    > div.stats-box
    > div:nth-child(1),
  .main-new-official-stats-section
    .mnoss-monthly-container
    > div.stats-box
    > div:nth-child(2) {
    border-bottom: solid 1px white;
    border-bottom-style: dotted;
    background-image: linear-gradient(
      to right,
      #d3e0f2 33%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 4px 1px;
    background-repeat: repeat-x;
  }
  .main-new-official-stats-section
    .mnoss-monthly-container
    > div.stats-box
    > div:nth-child(1) {
    padding-bottom: 20px;
  }
  .main-new-official-stats-section
    .mnoss-monthly-container
    > div.stats-box
    > div {
    width: 100%;
  }
  .main-new-official-stats-section
    .mnoss-monthly-container
    > div.stats-box
    > div:nth-child(2) {
    padding: 20px 0;
  }
  .main-new-official-stats-section
    .mnoss-monthly-container
    > div.stats-box
    > div:nth-child(3) {
    padding-top: 20px;
  }
  .main-new-official-stats-section .mnoss-monthly-container > div.explain-box {
    margin-top: 20px;
  }
}
</style>
