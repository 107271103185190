<template>
  <v-row no-gutters>
    <v-col cols="12">
      <div class="profit-display">
        <v-row class="text-center" no-gutters>
          <v-col cols="6">
            <p class="mb-3">한주간 수익금</p>

            <p v-if="!!userIdx" class="font-size--15 mb-0">
              <LoadingText
                :isLoading="isLoading"
                :class="getValueClass(myProfit.lastWeek)"
              >
                {{ getMark(myProfit.lastWeek) }}
                {{ myProfit.lastWeek | commaFormat }}
              </LoadingText>
              원
            </p>
            <span
              v-else
              class="color-funda--basic cursor--pointer font-size--15"
              @click="ON_LOGIN_DIALOG()"
            >
              로그인
            </span>
          </v-col>

          <v-col cols="6">
            <p class="mb-3">한달간 수익금</p>

            <p v-if="!!userIdx" class="font-size--15 mb-0">
              <LoadingText
                :isLoading="isLoading"
                :class="getValueClass(myProfit.lastMonth)"
              >
                {{ getMark(myProfit.lastMonth) }}
                {{ myProfit.lastMonth | commaFormat }}
              </LoadingText>
              원
            </p>
            <span
              v-else
              class="color-funda--basic cursor--pointer font-size--15"
              @click="ON_LOGIN_DIALOG()"
            >
              로그인
            </span>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import LoadingText from '@/components/common/text/LoadingText.vue'

export default {
  components: {
    LoadingText,
  },
  data() {
    return {
      isLoading: false,
      myProfit: {
        lastWeek: 0,
        lastMonth: 0,
      },
    }
  },
  computed: {
    ...mapGetters('auth', {
      userIdx: AuthTypes.getters.GET_USER_IDX,
    }),
  },
  watch: {
    userIdx: {
      immediate: true,
      handler(v) {
        if (!!v) {
          this.fetchData()
        }
      },
    },
  },
  methods: {
    ...mapActions('auth', [AuthTypes.actions.ON_LOGIN_DIALOG]),
    getMark(v) {
      if (v === 0) {
        return ''
      }

      if (v > 0) {
        return '+'
      } else {
        return '-'
      }
    },
    getValueClass(v) {
      if (this.isLoading || v === 0) {
        return 'black--text'
      } else if (v > 0) {
        return 'red--text'
      } else if (v < 0) {
        return 'blue--text'
      }
    },
    async getProfit(profitKey) {
      let countType = 'DAY'
      const hasKey = Object.keys(this.myProfit).some(k => k === profitKey)
      if (!hasKey) {
        profitKey = 'lastWeek'
      }

      if (profitKey === 'lastMonth') {
        countType = 'MONTH'
      }

      const q = `
        data: getUserProfit(userIdx: ${this.userIdx}, req: {
          countType: ${countType}
        })
      `

      try {
        const data = await this.$fundaApi.query(gql`{${q}}`)
        this.myProfit[profitKey] = data
      } catch {}
    },
    async fetchData() {
      if (this.isLoading) {
        return
      }

      this.isLoading = true

      const v1 = this.getProfit('lastWeek')
      const v2 = this.getProfit('lastMonth')
      await v1
      await v2

      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.profit-display {
  padding: 15px 25px;
  background-color: #ffffff;
  border: 1px solid #1baaf1;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(23, 25, 26, 0.1);
  font-weight: bold;
}
</style>
