<template>
  <div
    class="custom-popup welcome-popup"
    :class="{ show: showPopup }"
    @click="showPopup = false"
  >
    <div
      class="custom-popup__contents color--body"
      @click.stop="showPopup = true"
    >
      <div class="position--relative">
        <div class="popup-body">
          <div>
            <span class="cursor--pointer" @click.stop="showPopup = false">
              <v-icon large>
                mdi-close
              </v-icon>
            </span>
          </div>
          <div class="body__img">
            <picture>
              <img
                src="//cdn.funda.kr/funding/common/image/funda-live-streaming220530-onair.png"
              />
            </picture>
          </div>
        </div>
        <div class="popup-footer">
          <div class="popup-not-showing" @click.stop="showPopup = false">
            닫기
          </div>
          <div class="popup-close-btn" @click="apply()">
            라이브방송 입장하기
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AuthTypes } from '@/store/types/auth'

export default {
  data() {
    return {
      popupName: 'liveApplicationOnAir',
      programIdx: 1,
      showPopup: false,
      isLoading: false,
      userApplication: null,
    }
  },
  computed: {
    ...mapGetters('auth', {
      profile: AuthTypes.getters.GET_PROFILE,
      userIdx: AuthTypes.getters.GET_USER_IDX,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    isOK() {
      const affiliate = this.profile?.affiliateCode
      return affiliate === 'OK시럽'
    },
  },
  watch: {
    isOK: {
      immediate: true,
      handler(v) {
        if (v === true) {
          this.getApplication()
        }
      },
    },
  },
  methods: {
    getCookie() {
      if (this.popup === null) {
        return
      }

      let v = document.cookie.match(
        '(^|;) ?' + this.popupName + '=([^;]*)(;|$)'
      )

      return !!v ? v[2] : null
    },
    setCookie() {
      let d = new Date()
      let days = 1
      let value = 1

      d.setTime(d.getTime() + 1000 * 60 * 60 * 24 * days)
      document.cookie =
        this.popupName + '=' + value + ';path=/;expires=' + d.toGMTString()
    },
    closeOneDay() {
      this.setCookie()
      this.showPopup = false
    },
    async getApplication() {
      if (this.isLoading) {
        return
      }

      // this.isLoading = true

      // const q = `
      //   data: getUserApplicationByProgram(userIdx: ${this.userIdx}, programIdx: ${this.programIdx}) {
      //     userIdx
      //     createTime
      //   }
      // `

      // try {
      //   const data = await this.$fundaApi.query(gql`{${q}}`)
      //   this.userApplication = data

      //   if (data === null) {
      //     this.showPopup = true
      //   }
      // } finally {
      //   this.isLoading = false
      // }

      const popupCookie = this.getCookie()

      if (!!popupCookie) {
        this.showPopup = false
      } else {
        this.showPopup = true
      }
    },
    async apply() {
      window.open('https://youtu.be/i2nu4_l1rfU', '_blank')
      // if (this.isLoading || !!this.userApplication) {
      //   return
      // }

      // const yes = await this.$swal.basic.confirm(
      //   '라이브방송을 신청하시겠습니까?'
      // )

      // if (yes.isConfirmed === false) {
      //   return
      // }

      // const q = `
      //   data: applyProgram(userIdx: ${this.userIdx}, programIdx: ${this.programIdx}) {
      //     userIdx
      //     createTime

      //     program {
      //       name
      //       description
      //     }
      //   }
      // `

      // try {
      //   const data = await this.$fundaApi.mutation(gql`{${q}}`)
      //   this.userApplication = data

      //   await this.$swal.basic.success(
      //     '접수가 완료되었습니다. 방송 당일 방송링크를 문자로 전달드릴 예정입니다.'
      //   )
      //   this.showPopup = false
      // } finally {
      //   this.isLoading = false
      // }
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-popup.show > .custom-popup__contents {
  position: fixed;
  top: 100px;
  animation: moveDown 0.7s;

  overflow-y: hidden;
}

.custom-popup .popup-body {
  background-color: #eaeff4;
  overflow-y: auto;
}

.custom-popup .body__img {
  max-height: 554px;

  img {
    object-fit: contain;
    height: 100%;
  }

  @media screen and (max-height: 568px) {
    max-height: 350px;
  }
}

@keyframes moveDown {
  0% {
    top: 30px;
  }
  100% {
    top: 100px;
  }
}

@media screen and (max-height: 800px) {
  .custom-popup.show > .custom-popup__contents {
    top: 50px;
  }

  @keyframes moveDown {
    0% {
      top: 0px;
    }
    100% {
      top: 50px;
    }
  }
}

.custom-popup .popup-footer .popup-not-showing {
  width: 40%;
}
.custom-popup .popup-footer .popup-close-btn {
  width: 60%;
  color: #ffffff;
  background-color: #1baaf1;
  font-weight: 900;
}
</style>
