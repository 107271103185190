import { jsLoad } from '@/util'
let loaded = false
const init = async () => {
  if (loaded === true) {
    return true
  }
  await jsLoad({
    id: 'sc-band',
    src: '//developers.band.us/js/share/band-button.js?v=11122017',
  })
  loaded = true
}

export default {
  init
}
