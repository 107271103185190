<template>
  <div>
    <v-card class="mx-auto my-16" max-width="400">
      <v-col cols="12" class="pt-3">
        <validation-observer ref="observer" v-slot="{ invalid }">
          <h2>주주 공지사항 로그인</h2>
          <form @submit.prevent="clickSignin">
            <validation-provider
              v-slot="{ errors }"
              name="email"
              rules="required|email"
            >
              <v-text-field
                v-model="signinForm.email"
                :error-messages="errors"
                label="이메일"
                required
              ></v-text-field>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="password"
              rules="required"
            >
              <v-text-field
                v-model="signinForm.password"
                type="password"
                :error-messages="errors"
                label="비밀번호"
                required
              ></v-text-field>
            </validation-provider>
            <v-btn
              class="mr-4"
              type="submit"
              :disabled="invalid"
              :loading="signinForm.loading"
            >
              로그인
            </v-btn>
          </form>
        </validation-observer>
      </v-col>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Signin',
  components: {},
  data: () => ({
    signinForm: {
      email: '',
      password: '',
      loading: false,
    },
  }),
  methods: {
    async clickSignin() {
      this.signinForm.loading = true

      try {
        await this.$store.dispatch('auth/firebaseSignin', this.signinForm)
        this.$router.push({ name: 'shareholders-notice' })
      } catch (error) {
        if (error.code.startsWith('auth/')) {
          this.$swal.fire({
            html: '이메일 혹은 비밀번호가 올바르지 않습니다.',
            confirmButtonText: '확인',
          })
        }
      } finally {
        this.signinForm.loading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
form {
  min-height: 200px;
}
</style>
