<template>
  <div class="safeplan-list">세이프플랜 {{ safeplan }}기 리스트</div>
</template>

<script>
export default {
  layout: 'legacy',
  props: ['safeplan'],
}
</script>
<style lang="scss" scoped>
#safe_header {
  padding: 80px 0 60px;
  min-height: 300px;
  background-color: #3868ac;

  p {
    color: #ffffff;
    font-size: 25px;
    font-weight: 500;
    line-height: 28px;

    &.side-line:before {
      margin-right: 10px;
    }

    &.side-line:after {
      margin-left: 10px;
    }

    &.side-line:before,
    &.side-line:after {
      display: inline-block;
      width: 60px;
      height: 1px;
      content: '';
      vertical-align: middle;
      background: rgba(255, 255, 255, 0.5);
    }
  }
}
</style>
