<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <h2 class="my-2 pl-1 color--gray-3 font-size--18 font-weight-black">
          {{ tableTitle }}
        </h2>
      </v-col>
    </v-row>
    <v-row no-gutters class="mb-5">
      <v-col cols="12">
        <div class="data-table-wrapper">
          <p class="mb-1 text-right">
            (단위: 명, 원)
          </p>
          <table>
            <thead>
              <tr>
                <th rowspan="2" colspan="3">
                  구 분
                </th>
                <th
                  v-for="header in tableHeader"
                  :key="header.name"
                  style="max-width: 100px"
                  colspan="2"
                >
                  {{ header.title }}
                </th>
              </tr>
              <tr>
                <template v-for="header in tableHeader">
                  <th :key="`${header.name}_count`">투자 자수</th>
                  <th :key="`${header.name}_amount`">투자 잔액</th>
                </template>
              </tr>
            </thead>
            <tbody>
              <template v-for="body in tableBody">
                <tr :key="`${body.name}`">
                  <td rowspan="10">
                    {{ body.title }}
                  </td>
                </tr>
                <tr :key="`${body.name}_credit`">
                  <td colspan="2">신용대출</td>
                  <template v-for="header in tableHeader">
                    <td :key="`${header.name}_count_v`" class="text-right">
                      {{ 12346 | commaFormat }}
                    </td>
                    <td :key="`${header.name}_amount_v`" class="text-right">
                      {{ 100000000 | commaFormat }}
                    </td>
                  </template>
                </tr>
                <tr :key="`${body.name}_estate`">
                  <td rowspan="4">부동산담보대출</td>
                </tr>
                <tr :key="`${body.name}_estate_house`">
                  <td>주택담보대출</td>
                </tr>
                <tr :key="`${body.name}_estate_etc`">
                  <td>기타담보대출</td>
                </tr>
                <tr :key="`${body.name}_estate_total`">
                  <td>소계</td>
                </tr>
                <tr :key="`${body.name}_PF`">
                  <td colspan="2">
                    부동산PF
                  </td>
                </tr>
                <tr :key="`${body.name}_etcsecure`">
                  <td colspan="2">
                    기타담보(어음, 매출채권 제외)
                  </td>
                </tr>
                <tr :key="`${body.name}_secure`">
                  <td colspan="2">
                    어음, 매출채권 담보
                  </td>
                </tr>
                <tr :key="`${body.name}_total`">
                  <td colspan="2">
                    소 계
                  </td>
                </tr>
              </template>
              <tr>
                <td colspan="3">
                  합 계
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    tableTitle: {
      type: String,
      default: '투자자별 연계투자 현황',
    },
  },
  data() {
    return {
      tableHeader: [
        {
          title: '일반 투자자',
          name: 'normal',
        },
        {
          title: '소득적격 개인투자자',
          name: 'qualification',
        },
        {
          title: '개인 전문투자자',
          name: 'expert',
        },
        {
          title: '일반법인',
          name: 'corporation',
        },
        {
          title: '금융기관',
          name: 'financial',
        },
        {
          title: '온라인 투자연계 금융업자 (자기계산투자)',
          name: 'online',
        },
        {
          title: '합 계',
          name: 'total',
        },
      ],
      tableBody: [
        {
          title: '개인 대출',
          name: 'loan',
        },
        {
          title: '개인 사업자',
          name: 'business',
        },
        {
          title: '법인',
          name: 'corporation',
        },
      ],
      tableData: {
        loan: {
          credit: {
            normal: {
              count: 100,
              amount: 100000000,
            },
            qualification: {
              count: 100,
              amount: 100000000,
            },
            expert: {
              count: 100,
              amount: 100000000,
            },
            corporation: {
              count: 100,
              amount: 100000000,
            },
            financial: {
              count: 100,
              amount: 100000000,
            },
            online: {
              count: 100,
              amount: 100000000,
            },
            total: {
              count: 100,
              amount: 100000000,
            },
          },
          estate: {
            house: {},
            etc: {},
            total: {},
          },
          PF: {},
          etcsecure: {},
          secure: {},
          total: {},
        },
        business: {},
        corporation: {},
      },
    }
  },
}
</script>

<style lang="scss" scoped></style>
