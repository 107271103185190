<template>
  <div class="register">
    <div class="custom-popup" :class="{ show: showPopup }">
      <div class="custom-popup__contents">
        <div class="popup-body">
          <div class="notice-wrapper color--body font-nanumsquare text-center">
            <p class="margin-t-30 margin-b-15 text-center">
              <img
                src="@/assets/images/signup/point-pr.png"
                style="width: 54px"
              />
            </p>
            <h4 class="font-size--18" style="line-height: 1.4">
              "<span class="color-funda--basic font-weight-black">
                신규 가입 5,000P</span
              >가
              <br v-if="isMobile" />
              지급되었습니다! "
            </h4>
            <p class="my-0 font-size--16" style="line-height: 1.4">
              투자자 정보를 입력하고
              <br v-if="isMobile" />첫 투자를 시작해보세요!
            </p>
            <p></p>

            <div
              class="popup-close-btn padding-x-10 padding-y-10"
              @click="showPopup = false"
            >
              닫기
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-5">
        <div class="col-md-12">
          <h1 class="text-center font-nanumsquare-bold register_title mb-7">
            투자자 정보 입력
          </h1>
          <h2 class="text-center font-nanumsquare register_sub_title">
            출금계좌 예금주와 동일한 원천징수 정보를 입력해주세요.
            <br class="mobile-none" />
            펀다에서는 투자하신 고객님의 이자수익에 대한 세금을 대신 납부하기
            위해 원천징수 정보가 필요로합니다. <br class="mobile-none" />
            주민등록번호와 주소정보는 암호화되어 원천징수 신고 이외의 용도로는
            사용되지 않습니다.
          </h2>
          <hr class="my-10" />
        </div>
      </div>
      <v-row class="mb-9" no-gutters>
        <v-col cols="12" class="text-center d-flex justify-center">
          <v-row no-gutters>
            <v-col
              class="d-flex justify-end"
              :class="isMobile ? 'mb-1 ' : 'pr-1'"
              md="6"
              sm="12"
            >
              <v-btn
                dark
                :to="{ name: 'register-corporation' }"
                class="font-nanumsquare-bold btn_submit btn_choice"
              >
                혹시 법인회원이신가요?
              </v-btn>
            </v-col>
            <v-col
              class="d-flex justify-start"
              :class="isMobile ? 'mb-1 ' : 'pl-1'"
              md="6"
              sm="12"
            >
              <v-btn
                dark
                :to="{ name: 'register-foreigner' }"
                class="font-nanumsquare-bold btn_submit btn_choice"
              >
                혹시 외국인회원이신가요?
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <div class="col-md-6">
          <div class="form_title">
            <h3><strong>1</strong>출금계좌 정보입력</h3>
            <p></p>
          </div>
          <div class="info_step">
            <ValidAccount @valid-account-form="updateAccountForm" />
          </div>
        </div>
        <div class="col-md-6 mb-7">
          <div class="form_title">
            <h3>
              <strong>2</strong>
              원천징수 정보입력
            </h3>
            <p style="margin-top: 10px">
              안전한 투자금의 상환을 위해 계좌정보의 예금주와 원천징수를 위한
              주민등록상 이름이 동일해야합니다.
            </p>
          </div>

          <div class="info_step">
            <v-row class="mb-4" no-gutters>
              <label> 주민등록번호 </label>
              <v-col cols="12">
                <div class="id_num">
                  <input
                    type="text"
                    maxlength="6"
                    v-model="signupUser.idNum1"
                  />
                  <span>-</span>
                  <input
                    type="password"
                    :rules="rules.idNum"
                    maxlength="7"
                    v-model="signupUser.idNum2"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <label> 주민등록상 주소 </label>
              <v-col cols="12" class="d-flex">
                <v-row class="mb-1 address" no-gutters>
                  <v-col cols="9" class="pr-4">
                    <input
                      readonly
                      type="text"
                      @click="daumAddrOpen = !daumAddrOpen"
                      v-model="signupUser.address1"
                      placeholder="에) 서울시 강남구 논현로 000-00"
                    />
                  </v-col>
                  <v-col cols="3" class="px-0">
                    <button
                      @click="daumAddrOpen = !daumAddrOpen"
                      class="btn-funda--black position--relative width-100 d-flex align-center justify-center common-button vertical-align--middle"
                    >
                      주소검색
                    </button>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <DaumAddr :isOpen.sync="daumAddrOpen" :addr.sync="daumAddr" />
              </v-col>
            </v-row>
            <v-row class="address" no-gutters>
              <v-col cols="12">
                <input
                  type="text"
                  :rules="rules.address"
                  ref="address2"
                  v-model="signupUser.address2"
                  placeholder="예) 101호"
                />
              </v-col>
            </v-row>
          </div>
        </div>
      </v-row>
      <v-row v-if="isMinor" class="my-3" no-gutters>
        <v-col md="6" sm="12" offset-md="6">
          <div class="form_title">
            <h3>
              <strong>3</strong>
              증빙 서류
              <p class="my-0 mt-1 font-size--14">(미성년자 가입시 필수 서류)</p>
            </h3>
          </div>
          <div class="info_step" style="margin-top: 15px; padding-top: 0px">
            <v-row no-gutters>
              <v-col cols="12">
                <label for="fileUploadAddressFront"> 1. 가족관계증명서 </label>
                <div class="file-upload">
                  <input
                    readonly
                    type="text"
                    :value="tempFile1 && tempFile1.orgName"
                  />
                  <S3FileUploadButton
                    ref="fileUploadAddressFront"
                    uploadOnSelect
                    :bucketName="bucketName"
                    :path="path"
                    :tempFile.sync="tempFile1"
                    :savedFile.sync="savedFile1"
                  />
                </div>
              </v-col>
              <v-col cols="12" class="mt-4">
                <label for="fileUploadAddressBack">
                  2. 가입자 명의 통장사본
                </label>
                <div class="file-upload">
                  <input
                    readonly
                    type="text"
                    :value="tempFile2 && tempFile2.orgName"
                  />
                  <S3FileUploadButton
                    ref="fileUploadAddressBack"
                    uploadOnSelect
                    :bucketName="bucketName"
                    :path="path"
                    :tempFile.sync="tempFile2"
                    :savedFile.sync="savedFile2"
                  />
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <hr />
      <v-row no-gutters>
        <v-col cols="12" class="d-flex justify-center mb-5">
          <v-btn
            depressed
            type="submit"
            height="47"
            width="250"
            color="primary"
            :disabled="!isFormValid"
            :loading="signupLoading"
            @click="signup(signupUser)"
          >
            회원가입 완료
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import S3FileUploadButton from '../../components/common/form/S3FileUploadButton.vue'
import ValidAccount from '@/components/ValidAccount'
import DaumAddr from '@/components/DaumAddr'

export default {
  layout: 'legacy',
  components: {
    S3FileUploadButton,
    ValidAccount,
    DaumAddr,
  },
  data() {
    return {
      today: new Date(),
      showPopup: true,
      daumAddrOpen: false,
      daumAddr: null,
      signupLoading: false,
      signupUser: {
        investorType: 1,
        accountBank: '',
        accountNumber: '',
        realAccountName: '',
        idNum1: '',
        idNum2: '',
        address1: '',
        address2: '',
        files: [],
      },
      rules: {
        idNum: [value => !!value || '주민등록번호를 입력해주세요.'],
        address: [value => !!value || '주소를 입력해주세요.'],
      },
      tempFile1: null,
      tempFile2: null,
      savedFile1: null,
      savedFile2: null,
      bucketName: 'funda-user-documents',
      path: '/investor_type/',
    }
  },
  watch: {
    daumAddr: function(value) {
      this.signupUser.address1 = value.address
      this.$refs.address2.focus()
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    isMinor() {
      let isMinor = false

      if (
        !!this.signupUser.idNum2 &&
        parseInt(this.signupUser.idNum2.substr(0, 1)) > 2
      ) {
        let year = this.signupUser.idNum1.substr(0, 2)
        year = '20' + year
        year = parseInt(year)

        if (this.today.getFullYear() - year < 19) {
          isMinor = true
        }
      }

      return isMinor
    },
    isFormValid() {
      let ok =
        this.signupUser.accountBank &&
        this.signupUser.accountNumber &&
        this.signupUser.realAccountName &&
        this.signupUser.idNum1 &&
        this.signupUser.idNum2 &&
        this.signupUser.address1 &&
        this.signupUser.address2

      if (this.isMinor) {
        let files = [this.tempFile1, this.tempFile2]
        let hasNull = files.some(file => !!!file)

        ok = ok && hasNull === false
      }

      return ok
    },
  },
  methods: {
    updateAccountForm(accountForm) {
      this.signupUser.accountBank = accountForm.bankCode
      this.signupUser.accountNumber = accountForm.accountNo
      this.signupUser.realAccountName = accountForm.accountName
    },
    async signup(signupUser) {
      let files = [this.tempFile1, this.tempFile2]

      let hasNull = files.some(file => !!!file)

      if (this.isMinor && hasNull) {
        alert('미성년자 증빙서류는 필수입니다.')
      }

      this.signupLoading = true

      try {
        if (this.isMinor) {
          this.signupUser.investorType = 100
          this.signupUser.files = files

          await this.$fundaApi.mutation(
            gql`
              {
                signupUserStep1(signupUserStep1: $signupUser) {
                  id
                  idx
                }
              }
            `,
            'signupUserStep1($signupUser: SignupUserStep1!)',
            {
              signupUser,
            }
          )
        } else {
          let q = `
            signupUserStep1(
              signupUserStep1: {
                investorType: ${signupUser.investorType}
                accountBank: "${signupUser.accountBank}"
                accountNumber: "${signupUser.accountNumber}"
                realAccountName: "${signupUser.realAccountName}"
                idNum1: "${signupUser.idNum1}"
                idNum2: "${signupUser.idNum2}"
                address1: "${signupUser.address1}"
                address2: "${signupUser.address2}"
                files: []
              }
            ){
              idx
            }
          `

          await this.$fundaApi.mutation(gql`{${q}}`)
        }

        this.$router.push({ name: 'register-complete' })
      } catch (e) {
        this.$swal.basic.alert(e.message)
      } finally {
        this.signupLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
h1,
h2,
h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: #444;
  margin-bottom: 10px;
}

hr {
  margin: 0px 0px 30px 0px;
}

.form_title h1 strong {
  background-color: #333;
}

body {
  background-color: white;
}

.register_title {
  font-size: 27px;
  font-weight: 600;
}

.register_sub_title {
  font-size: 16px;
  line-height: 1.5;
}

form {
  .col-md-6,
  .col-md-12 {
    float: left;
  }

  .col-* {
    padding-left: 0;
  }

  button[type='submit'] {
    color: white;
    font-size: 16px;
  }
}

.custom-popup.show > .custom-popup__contents {
  position: fixed;
  top: 250px;
  animation: moveDown 1s;
}

.popup-close-btn {
  width: 35%;
  padding: 20px;
  background-color: #333333;
  color: white;
  cursor: pointer;
  margin: 23px auto;
  border-radius: 4px;
}

@keyframes moveDown {
  0% {
    top: 100px;
  }
  100% {
    top: 250px;
  }
}

@media screen and (max-height: 800px) {
  .custom-popup > .custom-popup__contents {
    top: 120px;
  }

  @keyframes moveDown {
    0% {
      top: 0px;
    }
    100% {
      top: 120px;
    }
  }
}

.form_title {
  position: relative;
  padding-left: 40px;
  margin-bottom: 10px;

  h3 {
    margin: 0;
    padding: 0;
    padding-top: 4px;
    font-size: 22px;

    strong {
      background-color: #333;
      text-align: center;
      width: 30px;
      height: 30px;
      display: inline-block;
      border-radius: 50%;
      color: #fff;
      font-size: 18px;
      line-height: 30px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  p {
    color: #999;
    margin: 0;
    padding: 0;
    font-size: 12px;
    line-height: 14px;
  }
}

.investor_choice {
  display: inline-block;
}

.investor_choice:first-child .btn_choice {
  margin-right: 10px;
}

.btn_submit {
  width: 250px;
  height: 47px !important;
  background-color: #333333 !important;
  border-radius: 4px;
  color: white !important;
  font-size: 16px;
}

.btn_choice,
.btn-funda-basic,
.btn-funda-basic--reverse {
  background-color: #333333 !important;
  color: white;
}

.id_num {
  display: table;

  input {
    display: table-cell;
    width: 100%;
    height: 46px;
    border-radius: 4px;
    border: solid 1px #e7edf6;
    padding-left: 6px;
    width: 100%;
  }

  span {
    display: table-cell;
    width: 1%;
  }
}

.address {
  input {
    height: 46px;
    border-radius: 4px;
    border: solid 1px #e7edf6;
    padding-left: 6px;
    width: 100%;
  }

  button {
    padding: 12.5px 8px;
  }
}

@media screen and (min-width: 979px) {
  .info_step {
    padding: 20px 20px 20px 40px;
  }
}

@media screen and (max-width: 768px) {
  .register_sub_title {
    font-size: 15px;
  }

  .investor_choice {
    width: 100%;
  }

  .investor_choice:first-child .btn_choice {
    margin-right: 0;
  }

  .btn_submit {
    width: 100%;
  }

  .mobile-display {
    display: block;
  }

  .mobile-none {
    display: none !important;
  }
}

@media screen and (max-height: 800px) {
  @keyframes moveDown {
    0% {
      top: 0px;
    }
    100% {
      top: 120px;
    }
  }
}

@media screen and (max-width: 768px) {
  .mobile-display {
    display: block;
  }
}
</style>
