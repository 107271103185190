<template>
  <v-container fluid>
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12" md="8" sm="12">
        <v-card elevation="0">
          <v-card-title class="text-center justify-center">
            <h1 class="display-1">
              법인/적격 투자자 상담신청
            </h1>
          </v-card-title>

          <v-tabs v-model="tab" background-color="transparent" grow>
            <v-tab v-for="(item, index) in items" :key="index">
              {{ item.tab }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="(item, index) in items" :key="index">
              <v-card elevation="0">
                <component v-bind:is="item.content"></component>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ConsultCorpration from './ConsultCorporation'
import ConsultQualified from './ConsultQualified'

export default {
  components: {
    ConsultCorpration,
    ConsultQualified,
  },
  data() {
    return {
      tab: null,
      items: [
        { tab: '법인 투자자', content: 'ConsultCorpration' },
        { tab: '적격/전문 투자자', content: 'ConsultQualified' },
      ],
    }
  },
}
</script>
<style lang="scss" scoped>
$white: #ffffff;
$blue: #25aae1;
$text-color: #555555;

.container {
  background-color: $white;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  @media (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;

    .v-card__title {
      padding: 0px;
    }
  }
}

.v-tab {
  margin-right: 0;
  font-size: 1.36rem;
  letter-spacing: 0;

  @media (max-width: 768px) {
    font-size: 1.15rem;
  }

  &--active {
    background-color: $white;
    color: $blue !important;
  }
}

.primary--text {
  color: $blue !important;
  caret-color: $blue !important;
}

.display-1 {
  color: #555555;

  @media (max-width: 768px) {
    font-size: 25px !important;
    font-weight: 400;
  }
}
</style>
