export const overdueRateAndDetailsData = {
  '2016-01': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 235000000.0,
      연계대출잔액: 235000000.0,
      평균금리: 0.092,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 1338100000.0,
      연계대출잔액: 1137557993.0,
      평균금리: 0.1026,
      연체채권잔액: 13299652.0,
      연체율: 0.0117,
    },
    법인사업자신용대출: {
      누적연계대출금액: 629000000.0,
      연계대출잔액: 608220589.0,
      평균금리: 0.0752,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    합계: {
      누적연계대출금액: 2202100000.0,
      연계대출잔액: 1980778582.0,
      평균금리: 0.093,
      연체채권잔액: 13299652.0,
      연체율: 0.0067,
    },
  },
  '2016-02': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 275000000.0,
      연계대출잔액: 275000000.0,
      평균금리: 0.0903,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 1628100000.0,
      연계대출잔액: 1366146580.0,
      평균금리: 0.1056,
      연체채권잔액: 52856061.0,
      연체율: 0.0387,
    },
    법인사업자신용대출: {
      누적연계대출금액: 894200000.0,
      연계대출잔액: 860247358.0,
      평균금리: 0.0753,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    합계: {
      누적연계대출금액: 2797300000.0,
      연계대출잔액: 2501393938.0,
      평균금리: 0.0935,
      연체채권잔액: 52856061.0,
      연체율: 0.0211,
    },
  },
  '2016-03': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 285000000.0,
      연계대출잔액: 215000000.0,
      평균금리: 0.0915,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 170000000.0,
      연계대출잔액: 170000000.0,
      평균금리: 0.09,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 140000000.0,
      연계대출잔액: 140000000.0,
      평균금리: 0.14,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 2038100000.0,
      연계대출잔액: 1706478215.0,
      평균금리: 0.1124,
      연체채권잔액: 52777092.0,
      연체율: 0.0309,
    },
    법인사업자신용대출: {
      누적연계대출금액: 1389200000.0,
      연계대출잔액: 1340781041.0,
      평균금리: 0.1018,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    합계: {
      누적연계대출금액: 4022300000.0,
      연계대출잔액: 3572259256.0,
      평균금리: 0.1072,
      연체채권잔액: 52777092.0,
      연체율: 0.0148,
    },
  },
  '2016-04': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 285000000.0,
      연계대출잔액: 215000000.0,
      평균금리: 0.0915,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 978700000.0,
      연계대출잔액: 978700000.0,
      평균금리: 0.082,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 140000000.0,
      연계대출잔액: 140000000.0,
      평균금리: 0.14,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 2408100000.0,
      연계대출잔액: 1921520299.0,
      평균금리: 0.1094,
      연체채권잔액: 111931023.0,
      연체율: 0.0583,
    },
    법인사업자신용대출: {
      누적연계대출금액: 1909200000.0,
      연계대출잔액: 1826348211.0,
      평균금리: 0.1012,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    합계: {
      누적연계대출금액: 5721000000.0,
      연계대출잔액: 5081568510.0,
      평균금리: 0.1013,
      연체채권잔액: 111931023.0,
      연체율: 0.022,
    },
  },
  '2016-05': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 285000000.0,
      연계대출잔액: 185000000.0,
      평균금리: 0.0909,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1278700000.0,
      연계대출잔액: 1278700000.0,
      평균금리: 0.0816,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 595000000.0,
      연계대출잔액: 595000000.0,
      평균금리: 0.1323,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 2563100000.0,
      연계대출잔액: 1905249204.0,
      평균금리: 0.1101,
      연체채권잔액: 89597211.0,
      연체율: 0.047,
    },
    법인사업자신용대출: {
      누적연계대출금액: 2439000000.0,
      연계대출잔액: 2055058247.0,
      평균금리: 0.093,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    합계: {
      누적연계대출금액: 7160800000.0,
      연계대출잔액: 6019007451.0,
      평균금리: 0.0998,
      연체채권잔액: 89597211.0,
      연체율: 0.0149,
    },
  },
  '2016-06': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 335000000.0,
      연계대출잔액: 235000000.0,
      평균금리: 0.0971,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1278700000.0,
      연계대출잔액: 30000000.0,
      평균금리: 0.09,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 595000000.0,
      연계대출잔액: 572081730.0,
      평균금리: 0.1322,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 3061300000.0,
      연계대출잔액: 2239256225.0,
      평균금리: 0.1123,
      연체채권잔액: 89597211.0,
      연체율: 0.04,
    },
    법인사업자신용대출: {
      누적연계대출금액: 2859000000.0,
      연계대출잔액: 2302534234.0,
      평균금리: 0.0976,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    합계: {
      누적연계대출금액: 8129000000.0,
      연계대출잔액: 5378872189.0,
      평균금리: 0.1073,
      연체채권잔액: 89597211.0,
      연체율: 0.0167,
    },
  },
  '2016-07': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 335000000.0,
      연계대출잔액: 235000000.0,
      평균금리: 0.0971,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1278700000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 595000000.0,
      연계대출잔액: 549023620.0,
      평균금리: 0.1321,
      연체채권잔액: 120000000.0,
      연체율: 0.2186,
    },
    개인사업자신용대출: {
      누적연계대출금액: 3299300000.0,
      연계대출잔액: 2180378570.0,
      평균금리: 0.1137,
      연체채권잔액: 89597211.0,
      연체율: 0.0411,
    },
    법인사업자신용대출: {
      누적연계대출금액: 2909000000.0,
      연계대출잔액: 2048877663.0,
      평균금리: 0.0963,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    합계: {
      누적연계대출금액: 8417000000.0,
      연계대출잔액: 5013279853.0,
      평균금리: 0.1078,
      연체채권잔액: 209597211.0,
      연체율: 0.0418,
    },
  },
  '2016-08': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 335000000.0,
      연계대출잔액: 235000000.0,
      평균금리: 0.0971,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1278700000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 595000000.0,
      연계대출잔액: 530824156.0,
      평균금리: 0.1321,
      연체채권잔액: 115000000.0,
      연체율: 0.2166,
    },
    개인사업자신용대출: {
      누적연계대출금액: 3829300000.0,
      연계대출잔액: 2477710897.0,
      평균금리: 0.1163,
      연체채권잔액: 93044558.0,
      연체율: 0.0376,
    },
    법인사업자신용대출: {
      누적연계대출금액: 3200800000.0,
      연계대출잔액: 2229195855.0,
      평균금리: 0.1002,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    합계: {
      누적연계대출금액: 9238800000.0,
      연계대출잔액: 5472730908.0,
      평균금리: 0.1105,
      연체채권잔액: 208044558.0,
      연체율: 0.038,
    },
  },
  '2016-09': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 335000000.0,
      연계대출잔액: 155000000.0,
      평균금리: 0.0927,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1278700000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 595000000.0,
      연계대출잔액: 517481808.0,
      평균금리: 0.1321,
      연체채권잔액: 115000000.0,
      연체율: 0.2222,
    },
    개인사업자신용대출: {
      누적연계대출금액: 4172300000.0,
      연계대출잔액: 2521878108.0,
      평균금리: 0.1166,
      연체채권잔액: 93044558.0,
      연체율: 0.0369,
    },
    법인사업자신용대출: {
      누적연계대출금액: 3610800000.0,
      연계대출잔액: 2480193312.0,
      평균금리: 0.1037,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    합계: {
      누적연계대출금액: 9991800000.0,
      연계대출잔액: 5674553228.0,
      평균금리: 0.1117,
      연체채권잔액: 208044558.0,
      연체율: 0.0367,
    },
  },
  '2016-10': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 335000000.0,
      연계대출잔액: 125000000.0,
      평균금리: 0.0934,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1278700000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 595000000.0,
      연계대출잔액: 500995029.0,
      평균금리: 0.1322,
      연체채권잔액: 112000000.0,
      연체율: 0.2236,
    },
    개인사업자신용대출: {
      누적연계대출금액: 4600300000.0,
      연계대출잔액: 2676661517.0,
      평균금리: 0.1178,
      연체채권잔액: 137531766.0,
      연체율: 0.0514,
    },
    법인사업자신용대출: {
      누적연계대출금액: 3940800000.0,
      연계대출잔액: 2652942902.0,
      평균금리: 0.1048,
      연체채권잔액: 59922335.0,
      연체율: 0.0226,
    },
    합계: {
      누적연계대출금액: 10749800000.0,
      연계대출잔액: 5955599448.0,
      평균금리: 0.1127,
      연체채권잔액: 309454101.0,
      연체율: 0.052,
    },
  },
  '2016-11': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 335000000.0,
      연계대출잔액: 125000000.0,
      평균금리: 0.0934,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1278700000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 595000000.0,
      연계대출잔액: 487362255.0,
      평균금리: 0.1322,
      연체채권잔액: 112000000.0,
      연체율: 0.2298,
    },
    개인사업자신용대출: {
      누적연계대출금액: 5225200000.0,
      연계대출잔액: 3054041855.0,
      평균금리: 0.1186,
      연체채권잔액: 136514391.0,
      연체율: 0.0447,
    },
    법인사업자신용대출: {
      누적연계대출금액: 4520800000.0,
      연계대출잔액: 2974621330.0,
      평균금리: 0.1088,
      연체채권잔액: 210219018.0,
      연체율: 0.0707,
    },
    합계: {
      누적연계대출금액: 11954700000.0,
      연계대출잔액: 6641025440.0,
      평균금리: 0.1147,
      연체채권잔액: 458733409.0,
      연체율: 0.0691,
    },
  },
  '2016-12': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 335000000.0,
      연계대출잔액: 125000000.0,
      평균금리: 0.0934,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1278700000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 595000000.0,
      연계대출잔액: 475828744.0,
      평균금리: 0.13232501775891034,
      연체채권잔액: 114246837.0,
      연체율: 0.2401007472554033,
    },
    개인사업자신용대출: {
      누적연계대출금액: 5975200000.0,
      연계대출잔액: 3480641949.0,
      평균금리: 0.12013413448635075,
      연체채권잔액: 168646485.0,
      연체율: 0.04845269564381728,
    },
    법인사업자신용대출: {
      누적연계대출금액: 4779800000.0,
      연계대출잔액: 2978197290.0,
      평균금리: 0.10790858956157334,
      연체채권잔액: 166800938.0,
      연체율: 0.05600735000333037,
    },
    합계: {
      누적연계대출금액: 12963700000.0,
      연계대출잔액: 7059667983.0,
      평균금리: 0.11524400688494121,
      연체채권잔액: 449694260.0,
      연체율: 0.06369906645509167,
    },
  },
  '2017-01': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 335000000.0,
      연계대출잔액: 20000000.0,
      평균금리: 0.085,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1278700000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 609652386.0,
      평균금리: 0.1318,
      연체채권잔액: 112000000.0,
      연체율: 0.1837,
    },
    개인사업자신용대출: {
      누적연계대출금액: 6340200000.0,
      연계대출잔액: 3527369215.0,
      평균금리: 0.1208,
      연체채권잔액: 167731279.0,
      연체율: 0.0476,
    },
    법인사업자신용대출: {
      누적연계대출금액: 5079800000.0,
      연계대출잔액: 2850337838.0,
      평균금리: 0.1112,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    합계: {
      누적연계대출금액: 13778700000.0,
      연계대출잔액: 7007359439.0,
      평균금리: 0.1177,
      연체채권잔액: 279731279.0,
      연체율: 0.0399,
    },
  },
  '2017-02': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 335000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1528700000.0,
      연계대출잔액: 250000000.0,
      평균금리: 0.105,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 537197077.0,
      평균금리: 0.132,
      연체채권잔액: 112000000.0,
      연체율: 0.2085,
    },
    개인사업자신용대출: {
      누적연계대출금액: 6741319848.0,
      연계대출잔액: 3479444285.0,
      평균금리: 0.1223,
      연체채권잔액: 162454415.0,
      연체율: 0.0467,
    },
    법인사업자신용대출: {
      누적연계대출금액: 5347800000.0,
      연계대출잔액: 2879552552.0,
      평균금리: 0.1083,
      연체채권잔액: 119446595.0,
      연체율: 0.0415,
    },
    합계: {
      누적연계대출금액: 14697819848.0,
      연계대출잔액: 7146193914.0,
      평균금리: 0.1168,
      연체채권잔액: 393901010.0,
      연체율: 0.0551,
    },
  },
  '2017-03': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 335000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1528700000.0,
      연계대출잔액: 242060138.0,
      평균금리: 0.105,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 506743411.0,
      평균금리: 0.1321,
      연체채권잔액: 112000000.0,
      연체율: 0.221,
    },
    개인사업자신용대출: {
      누적연계대출금액: 7131319848.0,
      연계대출잔액: 3322194830.0,
      평균금리: 0.1264,
      연체채권잔액: 164094443.0,
      연체율: 0.0494,
    },
    법인사업자신용대출: {
      누적연계대출금액: 6064800000.0,
      연계대출잔액: 3388194236.0,
      평균금리: 0.1068,
      연체채권잔액: 110634982.0,
      연체율: 0.0327,
    },
    합계: {
      누적연계대출금액: 15804819848.0,
      연계대출잔액: 7459192615.0,
      평균금리: 0.1172,
      연체채권잔액: 386729425.0,
      연체율: 0.0518,
    },
  },
  '2017-04': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 335000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1528700000.0,
      연계대출잔액: 234050803.0,
      평균금리: 0.105,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 431465792.0,
      평균금리: 0.1325,
      연체채권잔액: 126513237.0,
      연체율: 0.2932,
    },
    개인사업자신용대출: {
      누적연계대출금액: 7412319848.0,
      연계대출잔액: 3178228615.0,
      평균금리: 0.1314,
      연체채권잔액: 190755128.0,
      연체율: 0.06,
    },
    법인사업자신용대출: {
      누적연계대출금액: 6637800000.0,
      연계대출잔액: 3675554277.0,
      평균금리: 0.0955,
      연체채권잔액: 172020976.0,
      연체율: 0.0468,
    },
    합계: {
      누적연계대출금액: 16658819848.0,
      연계대출잔액: 7519299487.0,
      평균금리: 0.1131,
      연체채권잔액: 489289341.0,
      연체율: 0.0651,
    },
  },
  '2017-05': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 335000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1528700000.0,
      연계대출잔액: 225971386.0,
      평균금리: 0.105,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 414043494.0,
      평균금리: 0.1325,
      연체채권잔액: 123633472.0,
      연체율: 0.2986,
    },
    개인사업자신용대출: {
      누적연계대출금액: 8078729848.0,
      연계대출잔액: 3425848224.0,
      평균금리: 0.1337,
      연체채권잔액: 228634064.0,
      연체율: 0.0667,
    },
    법인사업자신용대출: {
      누적연계대출금액: 7039800000.0,
      연계대출잔액: 3527217015.0,
      평균금리: 0.097,
      연체채권잔액: 129651774.0,
      연체율: 0.0368,
    },
    합계: {
      누적연계대출금액: 17727229848.0,
      연계대출잔액: 7593080119.0,
      평균금리: 0.1157,
      연체채권잔액: 481919310.0,
      연체율: 0.0635,
    },
  },
  '2017-06': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 335000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1528700000.0,
      연계대출잔액: 217821274.0,
      평균금리: 0.105,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 398343538.0,
      평균금리: 0.1326,
      연체채권잔액: 122633472.0,
      연체율: 0.3079,
    },
    개인사업자신용대출: {
      누적연계대출금액: 9053729848.0,
      연계대출잔액: 3961841096.0,
      평균금리: 0.1346,
      연체채권잔액: 311087355.0,
      연체율: 0.0785,
    },
    법인사업자신용대출: {
      누적연계대출금액: 7431800000.0,
      연계대출잔액: 3292086132.0,
      평균금리: 0.0953,
      연체채권잔액: 140251056.0,
      연체율: 0.0426,
    },
    합계: {
      누적연계대출금액: 19094229848.0,
      연계대출잔액: 7870092040.0,
      평균금리: 0.1172,
      연체채권잔액: 573971883.0,
      연체율: 0.0729,
    },
  },
  '2017-07': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 335000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1528700000.0,
      연계대출잔액: 209599849.0,
      평균금리: 0.105,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 381380616.0,
      평균금리: 0.1327,
      연체채권잔액: 120529633.0,
      연체율: 0.316,
    },
    개인사업자신용대출: {
      누적연계대출금액: 9725289848.0,
      연계대출잔액: 4115319864.0,
      평균금리: 0.1371,
      연체채권잔액: 559145341.0,
      연체율: 0.1359,
    },
    법인사업자신용대출: {
      누적연계대출금액: 8084685114.0,
      연계대출잔액: 3555226220.0,
      평균금리: 0.0957,
      연체채권잔액: 135089788.0,
      연체율: 0.038,
    },
    합계: {
      누적연계대출금액: 20418674962.0,
      연계대출잔액: 8261526549.0,
      평균금리: 0.1183,
      연체채권잔액: 814764762.0,
      연체율: 0.0986,
    },
  },
  '2017-08': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 335000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1528700000.0,
      연계대출잔액: 201306486.0,
      평균금리: 0.105,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 352231050.0,
      평균금리: 0.133,
      연체채권잔액: 106400000.0,
      연체율: 0.3021,
    },
    개인사업자신용대출: {
      누적연계대출금액: 10938509848.0,
      연계대출잔액: 4927257799.0,
      평균금리: 0.1383,
      연체채권잔액: 617719529.0,
      연체율: 0.1254,
    },
    법인사업자신용대출: {
      누적연계대출금액: 9049685114.0,
      연계대출잔액: 3871228949.0,
      평균금리: 0.1052,
      연체채권잔액: 145085565.0,
      연체율: 0.0375,
    },
    합계: {
      누적연계대출금액: 22596894962.0,
      연계대출잔액: 9352024284.0,
      평균금리: 0.1237,
      연체채권잔액: 869205094.0,
      연체율: 0.0929,
    },
  },
  '2017-09': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 545000000.0,
      연계대출잔액: 210000000.0,
      평균금리: 0.08,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1528700000.0,
      연계대출잔액: 192940556.0,
      평균금리: 0.105,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 337048526.0,
      평균금리: 0.1331,
      연체채권잔액: 106400000.0,
      연체율: 0.3157,
    },
    개인사업자신용대출: {
      누적연계대출금액: 11611509848.0,
      연계대출잔액: 5049827974.0,
      평균금리: 0.1369,
      연체채권잔액: 594173540.0,
      연체율: 0.1177,
    },
    법인사업자신용대출: {
      누적연계대출금액: 9970685114.0,
      연계대출잔액: 4193454594.0,
      평균금리: 0.1075,
      연체채권잔액: 153308329.0,
      연체율: 0.0366,
    },
    합계: {
      누적연계대출금액: 24400894962.0,
      연계대출잔액: 9983271650.0,
      평균금리: 0.1226,
      연체채권잔액: 853881869.0,
      연체율: 0.0855,
    },
  },
  '2017-10': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 545000000.0,
      연계대출잔액: 210000000.0,
      평균금리: 0.08,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1528700000.0,
      연계대출잔액: 184501424.0,
      평균금리: 0.105,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 321701652.0,
      평균금리: 0.1332,
      연체채권잔액: 106400000.0,
      연체율: 0.3307,
    },
    개인사업자신용대출: {
      누적연계대출금액: 12079509848.0,
      연계대출잔액: 4968767154.0,
      평균금리: 0.1356,
      연체채권잔액: 580927538.0,
      연체율: 0.1169,
    },
    법인사업자신용대출: {
      누적연계대출금액: 10619685114.0,
      연계대출잔액: 4428134971.0,
      평균금리: 0.1084,
      연체채권잔액: 133914994.0,
      연체율: 0.0302,
    },
    합계: {
      누적연계대출금액: 25517894962.0,
      연계대출잔액: 10113105201.0,
      평균금리: 0.1219,
      연체채권잔액: 821242532.0,
      연체율: 0.0812,
    },
  },
  '2017-11': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 545000000.0,
      연계대출잔액: 210000000.0,
      평균금리: 0.08,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1528700000.0,
      연계대출잔액: 175988450.0,
      평균금리: 0.105,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 306188648.0,
      평균금리: 0.1334,
      연체채권잔액: 106400000.0,
      연체율: 0.3475,
    },
    개인사업자신용대출: {
      누적연계대출금액: 12707509848.0,
      연계대출잔액: 5030368481.0,
      평균금리: 0.1334,
      연체채권잔액: 573827438.0,
      연체율: 0.1141,
    },
    법인사업자신용대출: {
      누적연계대출금액: 11084685114.0,
      연계대출잔액: 4081466786.0,
      평균금리: 0.1061,
      연체채권잔액: 130768596.0,
      연체율: 0.032,
    },
    합계: {
      누적연계대출금액: 26610894962.0,
      연계대출잔액: 9804012365.0,
      평균금리: 0.1204,
      연체채권잔액: 810996034.0,
      연체율: 0.0827,
    },
  },
  '2017-12': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 545000000.0,
      연계대출잔액: 210000000.0,
      평균금리: 0.08,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1528700000.0,
      연계대출잔액: 167400987.0,
      평균금리: 0.10500000218039336,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 290507716.0,
      평균금리: 0.13359917779257885,
      연체채권잔액: 106400000.0,
      연체율: 0.36625533209589517,
    },
    개인사업자신용대출: {
      누적연계대출금액: 13505509848.0,
      연계대출잔액: 5134306351.0,
      평균금리: 0.13280010879506632,
      연체채권잔액: 619823345.0,
      연체율: 0.12072192475995867,
    },
    법인사업자신용대출: {
      누적연계대출금액: 11540685114.0,
      연계대출잔액: 3968822897.0,
      평균금리: 0.10835367718853392,
      연체채권잔액: 119852250.0,
      연체율: 0.03019843744869425,
    },
    합계: {
      누적연계대출금액: 27864894962.0,
      연계대출잔액: 9771037951.0,
      평균금리: 0.12127152507354698,
      연체채권잔액: 846075595.0,
      연체율: 0.08659014520697976,
    },
  },
  '2018-01': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 545000000.0,
      연계대출잔액: 210000000.0,
      평균금리: 0.08,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1528700000.0,
      연계대출잔액: 158738384.0,
      평균금리: 0.105,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 274657037.0,
      평균금리: 0.1338,
      연체채권잔액: 106400000.0,
      연체율: 0.3874,
    },
    개인사업자신용대출: {
      누적연계대출금액: 14348509848.0,
      연계대출잔액: 5364313863.0,
      평균금리: 0.1317,
      연체채권잔액: 819774772.0,
      연체율: 0.1528,
    },
    법인사업자신용대출: {
      누적연계대출금액: 12719685114.0,
      연계대출잔액: 4039247615.0,
      평균금리: 0.1097,
      연체채권잔액: 206677488.0,
      연체율: 0.0512,
    },
    합계: {
      누적연계대출금액: 29886894962.0,
      연계대출잔액: 10046956899.0,
      평균금리: 0.1214,
      연체채권잔액: 1132852260.0,
      연체율: 0.1128,
    },
  },
  '2018-02': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 211889452.0,
      평균금리: 0.1219,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1528700000.0,
      연계대출잔액: 149999983.0,
      평균금리: 0.105,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 258634775.0,
      평균금리: 0.1341,
      연체채권잔액: 106400000.0,
      연체율: 0.4114,
    },
    개인사업자신용대출: {
      누적연계대출금액: 15388849848.0,
      연계대출잔액: 5790109360.0,
      평균금리: 0.1316,
      연체채권잔액: 860276329.0,
      연체율: 0.1486,
    },
    법인사업자신용대출: {
      누적연계대출금액: 13742685114.0,
      연계대출잔액: 3909260857.0,
      평균금리: 0.1119,
      연체채권잔액: 186150751.0,
      연체율: 0.0476,
    },
    합계: {
      누적연계대출금액: 32100234962.0,
      연계대출잔액: 10319894427.0,
      평균금리: 0.1236,
      연체채권잔액: 1152827080.0,
      연체율: 0.1117,
    },
  },
  '2018-03': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 210000000.0,
      평균금리: 0.1224,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1528700000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 242439072.0,
      평균금리: 0.1343,
      연체채권잔액: 106400000.0,
      연체율: 0.4389,
    },
    개인사업자신용대출: {
      누적연계대출금액: 16516409848.0,
      연계대출잔액: 6100191495.0,
      평균금리: 0.1323,
      연체채권잔액: 814782790.0,
      연체율: 0.1336,
    },
    법인사업자신용대출: {
      누적연계대출금액: 15528685114.0,
      연계대출잔액: 3881005202.0,
      평균금리: 0.1137,
      연체채권잔액: 289622168.0,
      연체율: 0.0746,
    },
    합계: {
      누적연계대출금액: 35013794962.0,
      연계대출잔액: 10433635769.0,
      평균금리: 0.1252,
      연체채권잔액: 1210804958.0,
      연체율: 0.116,
    },
  },
  '2018-04': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 210000000.0,
      평균금리: 0.1224,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1528700000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 226068050.0,
      평균금리: 0.1347,
      연체채권잔액: 106400000.0,
      연체율: 0.4707,
    },
    개인사업자신용대출: {
      누적연계대출금액: 17557519848.0,
      연계대출잔액: 6417358150.0,
      평균금리: 0.1341,
      연체채권잔액: 773675344.0,
      연체율: 0.1206,
    },
    법인사업자신용대출: {
      누적연계대출금액: 17461685114.0,
      연계대출잔액: 3584202233.0,
      평균금리: 0.1141,
      연체채권잔액: 257199577.0,
      연체율: 0.0718,
    },
    합계: {
      누적연계대출금액: 37987904962.0,
      연계대출잔액: 10437628433.0,
      평균금리: 0.127,
      연체채권잔액: 1137274921.0,
      연체율: 0.109,
    },
  },
  '2018-05': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 150000000.0,
      평균금리: 0.1233,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1528700000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 209519812.0,
      평균금리: 0.135,
      연체채권잔액: 106400000.0,
      연체율: 0.5078,
    },
    개인사업자신용대출: {
      누적연계대출금액: 19283529848.0,
      연계대출잔액: 7311414817.0,
      평균금리: 0.1362,
      연체채권잔액: 795998232.0,
      연체율: 0.1089,
    },
    법인사업자신용대출: {
      누적연계대출금액: 19814685114.0,
      연계대출잔액: 3334062734.0,
      평균금리: 0.119,
      연체채권잔액: 256699577.0,
      연체율: 0.077,
    },
    합계: {
      누적연계대출금액: 42066914962.0,
      연계대출잔액: 11004997363.0,
      평균금리: 0.1308,
      연체채권잔액: 1159097809.0,
      연체율: 0.1053,
    },
  },
  '2018-06': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 150000000.0,
      평균금리: 0.1233,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1528700000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 86392439.0,
      평균금리: 0.1299,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 21014519271.0,
      연계대출잔액: 7213229020.0,
      평균금리: 0.1394,
      연체채권잔액: 94789216.0,
      연체율: 0.0131,
    },
    법인사업자신용대출: {
      누적연계대출금액: 22455245114.0,
      연계대출잔액: 3125449993.0,
      평균금리: 0.1208,
      연체채권잔액: 12166042.0,
      연체율: 0.0039,
    },
    합계: {
      누적연계대출금액: 46438464385.0,
      연계대출잔액: 10575071452.0,
      평균금리: 0.1336,
      연체채권잔액: 106955258.0,
      연체율: 0.0101,
    },
  },
  '2018-07': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 150000000.0,
      평균금리: 0.1233,
      연체채권잔액: 150000000.0,
      연체율: 1.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1578700000.0,
      연계대출잔액: 50000000.0,
      평균금리: 0.15,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 69483993.0,
      평균금리: 0.1299,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 23243941563.0,
      연계대출잔액: 8094807537.0,
      평균금리: 0.1421,
      연체채권잔액: 152470911.0,
      연체율: 0.0188,
    },
    법인사업자신용대출: {
      누적연계대출금액: 25746915114.0,
      연계대출잔액: 2786113003.0,
      평균금리: 0.1183,
      연체채권잔액: 12166042.0,
      연체율: 0.0044,
    },
    합계: {
      누적연계대출금액: 52009556677.0,
      연계대출잔액: 11150404533.0,
      평균금리: 0.1359,
      연체채권잔액: 314636953.0,
      연체율: 0.0282,
    },
  },
  '2018-08': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 150000000.0,
      평균금리: 0.1233,
      연체채권잔액: 150000000.0,
      연체율: 1.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1628700000.0,
      연계대출잔액: 93087285.0,
      평균금리: 0.15,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 52392513.0,
      평균금리: 0.1299,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 25480351563.0,
      연계대출잔액: 8804008068.0,
      평균금리: 0.143,
      연체채권잔액: 183296889.0,
      연체율: 0.0208,
    },
    법인사업자신용대출: {
      누적연계대출금액: 27729915114.0,
      연계대출잔액: 2467040061.0,
      평균금리: 0.1179,
      연체채권잔액: 12166042.0,
      연체율: 0.0049,
    },
    합계: {
      누적연계대출금액: 56278966677.0,
      연계대출잔액: 11566527927.0,
      평균금리: 0.1374,
      연체채권잔액: 345462931.0,
      연체율: 0.0299,
    },
  },
  '2018-09': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 150000000.0,
      평균금리: 0.1233,
      연체채권잔액: 150000000.0,
      연체율: 1.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1798700000.0,
      연계대출잔액: 255225043.0,
      평균금리: 0.15,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 52392513.0,
      평균금리: 0.1299,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 27261621563.0,
      연계대출잔액: 8923825291.0,
      평균금리: 0.1437,
      연체채권잔액: 230378174.0,
      연체율: 0.0258,
    },
    법인사업자신용대출: {
      누적연계대출금액: 29176725114.0,
      연계대출잔액: 2091099701.0,
      평균금리: 0.1156,
      연체채권잔액: 12166042.0,
      연체율: 0.0058,
    },
    합계: {
      누적연계대출금액: 59677046677.0,
      연계대출잔액: 11472542548.0,
      평균금리: 0.1384,
      연체채권잔액: 392544216.0,
      연체율: 0.0342,
    },
  },
  '2018-10': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 150000000.0,
      평균금리: 0.1233,
      연체채권잔액: 150000000.0,
      연체율: 1.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 1898700000.0,
      연계대출잔액: 347264522.0,
      평균금리: 0.15,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 52392513.0,
      평균금리: 0.1299,
      연체채권잔액: 52392513.0,
      연체율: 1.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 29503431563.0,
      연계대출잔액: 9121452475.0,
      평균금리: 0.1451,
      연체채권잔액: 321420311.0,
      연체율: 0.0352,
    },
    법인사업자신용대출: {
      누적연계대출금액: 32594725114.0,
      연계대출잔액: 2935118528.0,
      평균금리: 0.1051,
      연체채권잔액: 12166042.0,
      연체율: 0.0041,
    },
    합계: {
      누적연계대출금액: 65436856677.0,
      연계대출잔액: 12606228038.0,
      평균금리: 0.1356,
      연체채권잔액: 535978866.0,
      연체율: 0.0425,
    },
  },
  '2018-11': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 150000000.0,
      평균금리: 0.1233,
      연체채권잔액: 150000000.0,
      연체율: 1.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 2098700000.0,
      연계대출잔액: 535316579.0,
      평균금리: 0.1537,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 47858667.0,
      평균금리: 0.1299,
      연체채권잔액: 47858667.0,
      연체율: 1.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 31616479378.0,
      연계대출잔액: 9155827692.0,
      평균금리: 0.1452,
      연체채권잔액: 345201833.0,
      연체율: 0.0377,
    },
    법인사업자신용대출: {
      누적연계대출금액: 34766055114.0,
      연계대출잔액: 4769775374.0,
      평균금리: 0.0966,
      연체채권잔액: 80634840.0,
      연체율: 0.0169,
    },
    합계: {
      누적연계대출금액: 69921234492.0,
      연계대출잔액: 14658778312.0,
      평균금리: 0.1294,
      연체채권잔액: 623695340.0,
      연체율: 0.0425,
    },
  },
  '2018-12': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 150000000.0,
      평균금리: 0.12333333333333334,
      연체채권잔액: 150000000.0,
      연체율: 1.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 2098700000.0,
      연계대출잔액: 523219285.0,
      평균금리: 0.15382248955139335,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 22008163.0,
      평균금리: 0.12989998301993674,
      연체채권잔액: 22008163.0,
      연체율: 1.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 33575037663.0,
      연계대출잔액: 9060798663.0,
      평균금리: 0.14585483368001861,
      연체채권잔액: 345231506.0,
      연체율: 0.038101663974695915,
    },
    법인사업자신용대출: {
      누적연계대출금액: 37025725114.0,
      연계대출잔액: 3959403840.0,
      평균금리: 0.10052662347268926,
      연체채권잔액: 75940622.0,
      연체율: 0.019179812181017636,
    },
    합계: {
      누적연계대출금액: 74139462777.0,
      연계대출잔액: 13715429951.0,
      평균금리: 0.13279501890542564,
      연체채권잔액: 593180291.0,
      연체율: 0.043249121108066384,
    },
  },
  '2019-01': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 150000000.0,
      평균금리: 0.1233,
      연체채권잔액: 150000000.0,
      연체율: 1.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 2613700000.0,
      연계대출잔액: 916654003.0,
      평균금리: 0.1679,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 22008163.0,
      평균금리: 0.1299,
      연체채권잔액: 22008163.0,
      연체율: 1.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 36242559378.0,
      연계대출잔액: 9606977918.0,
      평균금리: 0.147,
      연체채권잔액: 483587947.0,
      연체율: 0.0503,
    },
    법인사업자신용대출: {
      누적연계대출금액: 38904025114.0,
      연계대출잔액: 1600057111.0,
      평균금리: 0.1205,
      연체채권잔액: 71877893.0,
      연체율: 0.0449,
    },
    합계: {
      누적연계대출금액: 79200284492.0,
      연계대출잔액: 12295697195.0,
      평균금리: 0.1448,
      연체채권잔액: 727474003.0,
      연체율: 0.0592,
    },
  },
  '2019-02': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 150000000.0,
      평균금리: 0.1233,
      연체채권잔액: 150000000.0,
      연체율: 1.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 2898700000.0,
      연계대출잔액: 975027812.0,
      평균금리: 0.1725,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 22008163.0,
      평균금리: 0.1299,
      연체채권잔액: 22008163.0,
      연체율: 1.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 39114719378.0,
      연계대출잔액: 9964612231.0,
      평균금리: 0.147,
      연체채권잔액: 545225349.0,
      연체율: 0.0547,
    },
    법인사업자신용대출: {
      누적연계대출금액: 39720225114.0,
      연계대출잔액: 1684975343.0,
      평균금리: 0.1265,
      연체채권잔액: 71877893.0,
      연체율: 0.0427,
    },
    합계: {
      누적연계대출금액: 83173644492.0,
      연계대출잔액: 12796623549.0,
      평균금리: 0.1459,
      연체채권잔액: 789111405.0,
      연체율: 0.0617,
    },
  },
  '2019-03': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 150000000.0,
      평균금리: 0.1233,
      연체채권잔액: 150000000.0,
      연체율: 1.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 3450700000.0,
      연계대출잔액: 1371085801.0,
      평균금리: 0.1745,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 22008163.0,
      평균금리: 0.1299,
      연체채권잔액: 22008163.0,
      연체율: 1.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 41948069378.0,
      연계대출잔액: 10111598802.0,
      평균금리: 0.1389,
      연체채권잔액: 668439963.0,
      연체율: 0.0661,
    },
    법인사업자신용대출: {
      누적연계대출금액: 40330825114.0,
      연계대출잔액: 1698983879.0,
      평균금리: 0.1348,
      연체채권잔액: 71877893.0,
      연체율: 0.0423,
    },
    합계: {
      누적연계대출금액: 87169594492.0,
      연계대출잔액: 13353676645.0,
      평균금리: 0.1419,
      연체채권잔액: 912326019.0,
      연체율: 0.0683,
    },
  },
  '2019-04': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 150000000.0,
      평균금리: 0.1233,
      연체채권잔액: 150000000.0,
      연체율: 1.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 4313700000.0,
      연계대출잔액: 2190021672.0,
      평균금리: 0.1756,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 22008163.0,
      평균금리: 0.1299,
      연체채권잔액: 22008163.0,
      연체율: 1.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 45154349378.0,
      연계대출잔액: 10643600632.0,
      평균금리: 0.1323,
      연체채권잔액: 720807924.0,
      연체율: 0.0677,
    },
    법인사업자신용대출: {
      누적연계대출금액: 41032845114.0,
      연계대출잔액: 1679528798.0,
      평균금리: 0.1313,
      연체채권잔액: 71877893.0,
      연체율: 0.0428,
    },
    합계: {
      누적연계대출금액: 91940894492.0,
      연계대출잔액: 14685159265.0,
      평균금리: 0.1385,
      연체채권잔액: 964693980.0,
      연체율: 0.0657,
    },
  },
  '2019-05': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 150000000.0,
      평균금리: 0.1233,
      연체채권잔액: 150000000.0,
      연체율: 1.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 4938700000.0,
      연계대출잔액: 2508908885.0,
      평균금리: 0.175,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 22008163.0,
      평균금리: 0.1299,
      연체채권잔액: 22008163.0,
      연체율: 1.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 48171659378.0,
      연계대출잔액: 11010737877.0,
      평균금리: 0.1292,
      연체채권잔액: 830955218.0,
      연체율: 0.0755,
    },
    법인사업자신용대출: {
      누적연계대출금액: 41473325114.0,
      연계대출잔액: 1644728308.0,
      평균금리: 0.1237,
      연체채권잔액: 117584532.0,
      연체율: 0.0715,
    },
    합계: {
      누적연계대출금액: 96023684492.0,
      연계대출잔액: 15336383233.0,
      평균금리: 0.1361,
      연체채권잔액: 1120547913.0,
      연체율: 0.0731,
    },
  },
  '2019-06': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 150000000.0,
      평균금리: 0.1233,
      연체채권잔액: 150000000.0,
      연체율: 1.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 5713700000.0,
      연계대출잔액: 2566613729.0,
      평균금리: 0.1763,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 22008163.0,
      평균금리: 0.1299,
      연체채권잔액: 22008163.0,
      연체율: 1.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 50923199378.0,
      연계대출잔액: 10641056753.0,
      평균금리: 0.1292,
      연체채권잔액: 288047950.0,
      연체율: 0.0271,
    },
    법인사업자신용대출: {
      누적연계대출금액: 41888365114.0,
      연계대출잔액: 1552554857.0,
      평균금리: 0.1205,
      연체채권잔액: 74320669.0,
      연체율: 0.0479,
    },
    합계: {
      누적연계대출금액: 99965264492.0,
      연계대출잔액: 14932233502.0,
      평균금리: 0.1363,
      연체채권잔액: 534376782.0,
      연체율: 0.0358,
    },
  },
  '2019-07': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 150000000.0,
      평균금리: 0.1233,
      연체채권잔액: 150000000.0,
      연체율: 1.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 6629500000.0,
      연계대출잔액: 3339575634.0,
      평균금리: 0.1776,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 22008163.0,
      평균금리: 0.1299,
      연체채권잔액: 22008163.0,
      연체율: 1.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 54621619378.0,
      연계대출잔액: 10927206006.0,
      평균금리: 0.1294,
      연체채권잔액: 310340300.0,
      연체율: 0.0284,
    },
    법인사업자신용대출: {
      누적연계대출금액: 42193105114.0,
      연계대출잔액: 1580070669.0,
      평균금리: 0.1172,
      연체채권잔액: 54399519.0,
      연체율: 0.0344,
    },
    합계: {
      누적연계대출금액: 104884224492.0,
      연계대출잔액: 16018860472.0,
      평균금리: 0.1382,
      연체채권잔액: 536747982.0,
      연체율: 0.0335,
    },
  },
  '2019-08': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 150000000.0,
      평균금리: 0.1233,
      연체채권잔액: 150000000.0,
      연체율: 1.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 7159410000.0,
      연계대출잔액: 3537910805.0,
      평균금리: 0.1782,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 22008163.0,
      평균금리: 0.1299,
      연체채권잔액: 22008163.0,
      연체율: 1.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 58534949378.0,
      연계대출잔액: 11894966867.0,
      평균금리: 0.1242,
      연체채권잔액: 419189158.0,
      연체율: 0.0352,
    },
    법인사업자신용대출: {
      누적연계대출금액: 42533645114.0,
      연계대출잔액: 1501428727.0,
      평균금리: 0.1195,
      연체채권잔액: 54399519.0,
      연체율: 0.0362,
    },
    합계: {
      누적연계대출금액: 109668004492.0,
      연계대출잔액: 17106314562.0,
      평균금리: 0.135,
      연체채권잔액: 645596840.0,
      연체율: 0.0377,
    },
  },
  '2019-09': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 150000000.0,
      평균금리: 0.1233,
      연체채권잔액: 150000000.0,
      연체율: 1.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 8742020000.0,
      연계대출잔액: 4369318964.0,
      평균금리: 0.1787,
      연체채권잔액: 150000000.0,
      연체율: 0.0343,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 22008163.0,
      평균금리: 0.1299,
      연체채권잔액: 22008163.0,
      연체율: 1.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 62128189378.0,
      연계대출잔액: 12943632005.0,
      평균금리: 0.1183,
      연체채권잔액: 454511927.0,
      연체율: 0.0351,
    },
    법인사업자신용대출: {
      누적연계대출금액: 42914005114.0,
      연계대출잔액: 1627141552.0,
      평균금리: 0.1169,
      연체채권잔액: 54399519.0,
      연체율: 0.0334,
    },
    합계: {
      누적연계대출금액: 115224214492.0,
      연계대출잔액: 19112100684.0,
      평균금리: 0.1321,
      연체채권잔액: 830919609.0,
      연체율: 0.0435,
    },
  },
  '2019-10': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 150000000.0,
      평균금리: 0.1233,
      연체채권잔액: 150000000.0,
      연체율: 1.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 10556700000.0,
      연계대출잔액: 4948650749.0,
      평균금리: 0.1746,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 22008163.0,
      평균금리: 0.1299,
      연체채권잔액: 22008163.0,
      연체율: 1.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 66388019378.0,
      연계대출잔액: 13889699819.0,
      평균금리: 0.1133,
      연체채권잔액: 565161753.0,
      연체율: 0.0407,
    },
    법인사업자신용대출: {
      누적연계대출금액: 42945325114.0,
      연계대출잔액: 1429230921.0,
      평균금리: 0.1162,
      연체채권잔액: 54399519.0,
      연체율: 0.0381,
    },
    합계: {
      누적연계대출금액: 121330044492.0,
      연계대출잔액: 20439589652.0,
      평균금리: 0.1284,
      연체채권잔액: 791569435.0,
      연체율: 0.0387,
    },
  },
  '2019-11': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 150000000.0,
      평균금리: 0.1233,
      연체채권잔액: 150000000.0,
      연체율: 1.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12046700000.0,
      연계대출잔액: 5804881920.0,
      평균금리: 0.1682,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 22008163.0,
      평균금리: 0.1299,
      연체채권잔액: 22008163.0,
      연체율: 1.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 71186222089.0,
      연계대출잔액: 15452067794.0,
      평균금리: 0.1071,
      연체채권잔액: 608821715.0,
      연체율: 0.0394,
    },
    법인사업자신용대출: {
      누적연계대출금액: 43469555114.0,
      연계대출잔액: 1657609167.0,
      평균금리: 0.1089,
      연체채권잔액: 54399519.0,
      연체율: 0.0328,
    },
    합계: {
      누적연계대출금액: 128142477203.0,
      연계대출잔액: 23086567044.0,
      평균금리: 0.1227,
      연체채권잔액: 835229397.0,
      연체율: 0.0362,
    },
  },
  '2019-12': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 150000000.0,
      평균금리: 0.1233,
      연체채권잔액: 150000000.0,
      연체율: 1.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12372700000.0,
      연계대출잔액: 5521696732.0,
      평균금리: 0.1661,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 22008163.0,
      평균금리: 0.1299,
      연체채권잔액: 22008163.0,
      연체율: 1.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 76040133699.0,
      연계대출잔액: 17096707364.0,
      평균금리: 0.1025,
      연체채권잔액: 480132103.0,
      연체율: 0.0281,
    },
    법인사업자신용대출: {
      누적연계대출금액: 43917675114.0,
      연계대출잔액: 1688995828.0,
      평균금리: 0.1053,
      연체채권잔액: 40470806.0,
      연체율: 0.024,
    },
    합계: {
      누적연계대출금액: 133770508813.0,
      연계대출잔액: 24479408087.0,
      평균금리: 0.11715784219116153,
      연체채권잔액: 692611072.0,
      연체율: 0.028293620071958236,
    },
  },
  '2020-01': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 150000000.0,
      평균금리: 0.1233,
      연체채권잔액: 150000000.0,
      연체율: 1.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12748700000.0,
      연계대출잔액: 5263230327.0,
      평균금리: 0.163,
      연체채권잔액: 187317500.0,
      연체율: 0.0356,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 22008163.0,
      평균금리: 0.1299,
      연체채권잔액: 22008163.0,
      연체율: 1.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 81414855222.0,
      연계대출잔액: 19846045330.0,
      평균금리: 0.0974,
      연체채권잔액: 851181791.0,
      연체율: 0.0429,
    },
    법인사업자신용대출: {
      누적연계대출금액: 44037505114.0,
      연계대출잔액: 1579245843.0,
      평균금리: 0.103,
      연체채권잔액: 50026459.0,
      연체율: 0.0317,
    },
    합계: {
      누적연계대출금액: 139641060336.0,
      연계대출잔액: 26860529663.0,
      평균금리: 0.1108,
      연체채권잔액: 1260533913.0,
      연체율: 0.0469,
    },
  },
  '2020-02': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 150000000.0,
      평균금리: 0.1233,
      연체채권잔액: 150000000.0,
      연체율: 1.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12924700000.0,
      연계대출잔액: 4862516065.0,
      평균금리: 0.1624,
      연체채권잔액: 173083333.0,
      연체율: 0.0356,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 22008163.0,
      평균금리: 0.1299,
      연체채권잔액: 22008163.0,
      연체율: 1.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 86123334831.0,
      연계대출잔액: 20829107036.0,
      평균금리: 0.0955,
      연체채권잔액: 933319041.0,
      연체율: 0.0448,
    },
    법인사업자신용대출: {
      누적연계대출금액: 44383085114.0,
      연계대출잔액: 1573912666.0,
      평균금리: 0.1032,
      연체채권잔액: 50026459.0,
      연체율: 0.0318,
    },
    합계: {
      누적연계대출금액: 144871119945.0,
      연계대출잔액: 27437543930.0,
      평균금리: 0.108,
      연체채권잔액: 1328436996.0,
      연체율: 0.0484,
    },
  },
  '2020-03': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 4621576192.0,
      평균금리: 0.1614,
      연체채권잔액: 810440000.0,
      연체율: 0.1754,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 89299604831.0,
      연계대출잔액: 19816810131.0,
      평균금리: 0.0947,
      연체채권잔액: 1156098788.0,
      연체율: 0.0583,
    },
    법인사업자신용대출: {
      누적연계대출금액: 44807385114.0,
      연계대출잔액: 1654139870.0,
      평균금리: 0.1036,
      연체채권잔액: 50026459.0,
      연체율: 0.0302,
    },
    합계: {
      누적연계대출금액: 148521689945.0,
      연계대출잔액: 26092526193.0,
      평균금리: 0.1071,
      연체채권잔액: 2016565247.0,
      연체율: 0.0773,
    },
  },
  '2020-04': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 4363561941.0,
      평균금리: 0.1606,
      연체채권잔액: 881640000.0,
      연체율: 0.202,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 92159457831.0,
      연계대출잔액: 18516783811.0,
      평균금리: 0.0952,
      연체채권잔액: 1238526203.0,
      연체율: 0.0669,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45087385114.0,
      연계대출잔액: 1662806990.0,
      평균금리: 0.1039,
      연체채권잔액: 50026459.0,
      연체율: 0.0301,
    },
    합계: {
      누적연계대출금액: 151661542945.0,
      연계대출잔액: 24543152742.0,
      평균금리: 0.1074,
      연체채권잔액: 2170192662.0,
      연체율: 0.0884,
    },
  },
  '2020-05': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 4176824363.0,
      평균금리: 0.1606,
      연체채권잔액: 2531750000.0,
      연체율: 0.6061,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 97042517831.0,
      연계대출잔액: 18223108122.0,
      평균금리: 0.095,
      연체채권잔액: 1369738678.0,
      연체율: 0.0752,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45257385114.0,
      연계대출잔액: 1621720574.0,
      평균금리: 0.104,
      연체채권잔액: 144466223.0,
      연체율: 0.0891,
    },
    합계: {
      누적연계대출금액: 156714602945.0,
      연계대출잔액: 24021653059.0,
      평균금리: 0.107,
      연체채권잔액: 4045954901.0,
      연체율: 0.1684,
    },
  },
  '2020-06': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 3967228295.0,
      평균금리: 0.1613,
      연체채권잔액: 3434750000.0,
      연체율: 0.8658,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 103537177831.0,
      연계대출잔액: 18055515835.0,
      평균금리: 0.0939,
      연체채권잔액: 1029519970.0,
      연체율: 0.057,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45382045114.0,
      연계대출잔액: 1517567308.0,
      평균금리: 0.1034,
      연체채권잔액: 134910570.0,
      연체율: 0.0889,
    },
    합계: {
      누적연계대출금액: 163333922945.0,
      연계대출잔액: 23540311438.0,
      평균금리: 0.1059,
      연체채권잔액: 4599180540.0,
      연체율: 0.1954,
    },
  },
  '2020-07': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 3776958788.0,
      평균금리: 0.1613,
      연체채권잔액: 3489542260.0,
      연체율: 0.9239,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 112238577831.0,
      연계대출잔액: 17931230126.0,
      평균금리: 0.0922,
      연체채권잔액: 1176588023.0,
      연체율: 0.0656,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45388235114.0,
      연계대출잔액: 1277339052.0,
      평균금리: 0.1037,
      연체채권잔액: 151639191.0,
      연체율: 0.1187,
    },
    합계: {
      누적연계대출금액: 172041512945.0,
      연계대출잔액: 22985527966.0,
      평균금리: 0.1042,
      연체채권잔액: 4817769474.0,
      연체율: 0.2096,
    },
  },
  '2020-08': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 3691664068.0,
      평균금리: 0.1614,
      연체채권잔액: 3471924260.0,
      연체율: 0.9405,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 124275388831.0,
      연계대출잔액: 16075886385.0,
      평균금리: 0.0914,
      연체채권잔액: 1430210667.0,
      연체율: 0.089,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45388235114.0,
      연계대출잔액: 1072042136.0,
      평균금리: 0.1041,
      연체채권잔액: 164305622.0,
      연체율: 0.1533,
    },
    합계: {
      누적연계대출금액: 184078323945.0,
      연계대출잔액: 20839592589.0,
      평균금리: 0.1045,
      연체채권잔액: 5066440549.0,
      연체율: 0.2431,
    },
  },
  '2020-09': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 3435751093.0,
      평균금리: 0.1616,
      연체채권잔액: 3308924260.0,
      연체율: 0.9631,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 138696260711.0,
      연계대출잔액: 13827031022.0,
      평균금리: 0.1143,
      연체채권잔액: 1561068484.0,
      연체율: 0.1129,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45388235114.0,
      연계대출잔액: 879472544.0,
      평균금리: 0.1053,
      연체채권잔액: 164305622.0,
      연체율: 0.1868,
    },
    합계: {
      누적연계대출금액: 198499195825.0,
      연계대출잔액: 18142254659.0,
      평균금리: 0.1228,
      연체채권잔액: 5034298366.0,
      연체율: 0.2775,
    },
  },
  '2020-10': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 3332098518.0,
      평균금리: 0.1616,
      연체채권잔액: 3272424260.0,
      연체율: 0.9821,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 142894380131.0,
      연계대출잔액: 9561964351.0,
      평균금리: 0.1162,
      연체채권잔액: 1794132527.0,
      연체율: 0.1876,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45388235114.0,
      연계대출잔액: 733136833.0,
      평균금리: 0.1074,
      연체채권잔액: 183217088.0,
      연체율: 0.2499,
    },
    합계: {
      누적연계대출금액: 202697315245.0,
      연계대출잔액: 13627199702.0,
      평균금리: 0.1268,
      연체채권잔액: 5249773875.0,
      연체율: 0.3852,
    },
  },
  '2020-11': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 3156669797.0,
      평균금리: 0.1618,
      연체채권잔액: 3114391109.0,
      연체율: 0.9866,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 150661694446.0,
      연계대출잔액: 7830083678.0,
      평균금리: 0.1181,
      연체채권잔액: 1847085466.0,
      연체율: 0.2359,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45388235114.0,
      연계대출잔액: 604365258.0,
      평균금리: 0.1086,
      연체채권잔액: 202638576.0,
      연체율: 0.3353,
    },
    합계: {
      누적연계대출금액: 210464629560.0,
      연계대출잔액: 11591118733.0,
      평균금리: 0.1295,
      연체채권잔액: 5164115151.0,
      연체율: 0.4455,
    },
  },
  '2020-12': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 2779493979.0,
      평균금리: 0.1615635408433457,
      연체채권잔액: 2754857301.0,
      연체율: 0.9911362722185627,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 158580531745.0,
      연계대출잔액: 5374103622.0,
      평균금리: 0.11630156914752546,
      연체채권잔액: 1104470399.0,
      연체율: 0.20551713861240467,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45388235114.0,
      연계대출잔액: 347532388.0,
      평균금리: 0.11377452682194328,
      연체채권잔액: 113916826.0,
      연체율: 0.3277876535639608,
    },
    합계: {
      누적연계대출금액: 218383466859.0,
      연계대출잔액: 8501129989.0,
      평균금리: 0.13119039610753017,
      연체채권잔액: 3973244526.0,
      연체율: 0.4673783992411788,
    },
  },
  '2021-01': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 2775028855.0,
      평균금리: 0.1615,
      연체채권잔액: 2754857301.0,
      연체율: 0.9927,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 166832943320.0,
      연계대출잔액: 4130162796.0,
      평균금리: 0.1164,
      연체채권잔액: 677070383.0,
      연체율: 0.1639,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45388235114.0,
      연계대출잔액: 195759216.0,
      평균금리: 0.1116,
      연체채권잔액: 38903985.0,
      연체율: 0.1987,
    },
    합계: {
      누적연계대출금액: 226635878434.0,
      연계대출잔액: 7100950867.0,
      평균금리: 0.1339,
      연체채권잔액: 3470831669.0,
      연체율: 0.4888,
    },
  },
  '2021-02': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 2764028855.0,
      평균금리: 0.1615,
      연체채권잔액: 2761780595.0,
      연체율: 0.9992,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 174240812293.0,
      연계대출잔액: 4323497353.0,
      평균금리: 0.1161,
      연체채권잔액: 654298655.0,
      연체율: 0.1513,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45388235114.0,
      연계대출잔액: 126358380.0,
      평균금리: 0.1138,
      연체채권잔액: 38903985.0,
      연체율: 0.3079,
    },
    합계: {
      누적연계대출금액: 234043747407.0,
      연계대출잔액: 7213884588.0,
      평균금리: 0.1334,
      연체채권잔액: 3454983235.0,
      연체율: 0.4789,
    },
  },
  '2021-03': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 2639857301.0,
      평균금리: 0.1614,
      연체채권잔액: 2639857301.0,
      연체율: 1.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 186264865127.0,
      연계대출잔액: 5451828551.0,
      평균금리: 0.1454,
      연체채권잔액: 411011540.0,
      연체율: 0.0754,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45388235114.0,
      연계대출잔액: 76859530.0,
      평균금리: 0.1125,
      연체채권잔액: 36769208.0,
      연체율: 0.4784,
    },
    합계: {
      누적연계대출금액: 246067800241.0,
      연계대출잔액: 8168545382.0,
      평균금리: 0.1503,
      연체채권잔액: 3087638049.0,
      연체율: 0.378,
    },
  },
  '2021-04': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 2622857301.0,
      평균금리: 0.1614,
      연체채권잔액: 2622857301.0,
      연체율: 1.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 197029075127.0,
      연계대출잔액: 5678664388.0,
      평균금리: 0.1523,
      연체채권잔액: 376084938.0,
      연체율: 0.0662,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45388235114.0,
      연계대출잔액: 45311200.0,
      평균금리: 0.1042,
      연체채권잔액: 36769208.0,
      연체율: 0.8115,
    },
    합계: {
      누적연계대출금액: 256832010241.0,
      연계대출잔액: 8346832889.0,
      평균금리: 0.1549,
      연체채권잔액: 3035711447.0,
      연체율: 0.3637,
    },
  },
  '2021-05': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 2614857301.0,
      평균금리: 0.1616,
      연체채권잔액: 2614857301.0,
      연체율: 1.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 206726636127.0,
      연계대출잔액: 6420195415.0,
      평균금리: 0.1542,
      연체채권잔액: 360638551.0,
      연체율: 0.0562,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45388235114.0,
      연계대출잔액: 36769208.0,
      평균금리: 0.1006,
      연체채권잔액: 36769208.0,
      연체율: 1.0,
    },
    합계: {
      누적연계대출금액: 266529571241.0,
      연계대출잔액: 9071821924.0,
      평균금리: 0.1561,
      연체채권잔액: 3012265060.0,
      연체율: 0.332,
    },
  },
  '2021-06': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 2511857301.0,
      평균금리: 0.1615,
      연체채권잔액: 2511857301.0,
      연체율: 1.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 220176896127.0,
      연계대출잔액: 7957309199.0,
      평균금리: 0.1595,
      연체채권잔액: 373842495.0,
      연체율: 0.047,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45388235114.0,
      연계대출잔액: 36769208.0,
      평균금리: 0.1006,
      연체채권잔액: 36769208.0,
      연체율: 1.0,
    },
    합계: {
      누적연계대출금액: 279979831241.0,
      연계대출잔액: 10505935708.0,
      평균금리: 0.1598,
      연체채권잔액: 2922469004.0,
      연체율: 0.2782,
    },
  },
  '2021-07': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 2430857301.0,
      평균금리: 0.1617,
      연체채권잔액: 2430857301.0,
      연체율: 1.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 226584585127.0,
      연계대출잔액: 7561575587.0,
      평균금리: 0.158,
      연체채권잔액: 352438267.0,
      연체율: 0.0466,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45388235114.0,
      연계대출잔액: 36769208.0,
      평균금리: 0.1006,
      연체채권잔액: 36769208.0,
      연체율: 1.0,
    },
    합계: {
      누적연계대출금액: 286387520241.0,
      연계대출잔액: 10029202096.0,
      평균금리: 0.1587,
      연체채권잔액: 2820064776.0,
      연체율: 0.2812,
    },
  },
  '2021-08': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 2320755301.0,
      평균금리: 0.1621,
      연체채권잔액: 2320755301.0,
      연체율: 1.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 229008728127.0,
      연계대출잔액: 6810203587.0,
      평균금리: 0.1558,
      연체채권잔액: 356793690.0,
      연체율: 0.0524,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45388235114.0,
      연계대출잔액: 36769208.0,
      평균금리: 0.1006,
      연체채권잔액: 36769208.0,
      연체율: 1.0,
    },
    합계: {
      누적연계대출금액: 288811663241.0,
      연계대출잔액: 9167728096.0,
      평균금리: 0.1572,
      연체채권잔액: 2714318199.0,
      연체율: 0.2961,
    },
  },
  '2021-09': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 2297658854.0,
      평균금리: 0.1621,
      연체채권잔액: 2297658854.0,
      연체율: 1.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 230383690127.0,
      연계대출잔액: 5429923452.0,
      평균금리: 0.1534,
      연체채권잔액: 350438198.0,
      연체율: 0.0645,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45388235114.0,
      연계대출잔액: 36769208.0,
      평균금리: 0.1006,
      연체채권잔액: 36769208.0,
      연체율: 1.0,
    },
    합계: {
      누적연계대출금액: 290186625241.0,
      연계대출잔액: 7764351514.0,
      평균금리: 0.1557,
      연체채권잔액: 2684866260.0,
      연체율: 0.3458,
    },
  },
  '2021-10': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 2293930794.0,
      평균금리: 0.1622,
      연체채권잔액: 2293930794.0,
      연체율: 1.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 231895835127.0,
      연계대출잔액: 4335738305.0,
      평균금리: 0.1509,
      연체채권잔액: 354209139.0,
      연체율: 0.0817,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45388235114.0,
      연계대출잔액: 16923644.0,
      평균금리: 0.093,
      연체채권잔액: 16923644.0,
      연체율: 1.0,
    },
    합계: {
      누적연계대출금액: 291698770241.0,
      연계대출잔액: 6646592743.0,
      평균금리: 0.1546,
      연체채권잔액: 2665063577.0,
      연체율: 0.401,
    },
  },
  '2021-11': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 2292930794.0,
      평균금리: 0.1622,
      연체채권잔액: 2292930794.0,
      연체율: 1.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 235044707127.0,
      연계대출잔액: 4409019151.0,
      평균금리: 0.1505,
      연체채권잔액: 91581323.0,
      연체율: 0.0208,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45388235114.0,
      연계대출잔액: 16923644.0,
      평균금리: 0.093,
      연체채권잔액: 16923644.0,
      연체율: 1.0,
    },
    합계: {
      누적연계대출금액: 294847642241.0,
      연계대출잔액: 6718873589.0,
      평균금리: 0.1544,
      연체채권잔액: 2401435761.0,
      연체율: 0.3574,
    },
  },
  '2021-12': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 2269910794.0,
      평균금리: 0.1625,
      연체채권잔액: 2269910794.0,
      연체율: 1.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 237945714127.0,
      연계대출잔액: 4101808945.0,
      평균금리: 0.1502,
      연체채권잔액: 117619412.0,
      연체율: 0.0287,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45388235114.0,
      연계대출잔액: 16923644.0,
      평균금리: 0.093,
      연체채권잔액: 16923644.0,
      연체율: 1.0,
    },
    합계: {
      누적연계대출금액: 297748649241.0,
      연계대출잔액: 6388643383.0,
      평균금리: 0.1544,
      연체채권잔액: 2404453850.0,
      연체율: 0.3764,
    },
  },
  '2022-01': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 2262890794.0,
      평균금리: 0.1626,
      연체채권잔액: 2262890794.0,
      연체율: 1.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 240267211127.0,
      연계대출잔액: 3913022633.0,
      평균금리: 0.1502,
      연체채권잔액: 268753927.0,
      연체율: 0.0687,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45388235114.0,
      연계대출잔액: 16923644.0,
      평균금리: 0.093,
      연체채권잔액: 16923644.0,
      연체율: 1.0,
    },
    합계: {
      누적연계대출금액: 300070146241.0,
      연계대출잔액: 6192837071.0,
      평균금리: 0.1545,
      연체채권잔액: 2548568365.0,
      연체율: 0.4115,
    },
  },
  '2022-02': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 2258870794.0,
      평균금리: 0.1625,
      연체채권잔액: 2258870794.0,
      연체율: 1.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 242543889127.0,
      연계대출잔액: 4084756259.0,
      평균금리: 0.1503,
      연체채권잔액: 327122042.0,
      연체율: 0.0801,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45388235114.0,
      연계대출잔액: 16923644.0,
      평균금리: 0.093,
      연체채권잔액: 16923644.0,
      연체율: 1.0,
    },
    합계: {
      누적연계대출금액: 302346824241.0,
      연계대출잔액: 6360550697.0,
      평균금리: 0.1545,
      연체채권잔액: 2602916480.0,
      연체율: 0.4092,
    },
  },
  '2022-03': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 2244350794.0,
      평균금리: 0.1627,
      연체채권잔액: 2244350794.0,
      연체율: 1.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 245380758127.0,
      연계대출잔액: 4120148685.0,
      평균금리: 0.1502,
      연체채권잔액: 128243016.0,
      연체율: 0.0311,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45388235114.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    합계: {
      누적연계대출금액: 305183693241.0,
      연계대출잔액: 6364499479.0,
      평균금리: 0.1546,
      연체채권잔액: 2372593810.0,
      연체율: 0.3728,
    },
  },
  '2022-04': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 2227830794.0,
      평균금리: 0.1627,
      연체채권잔액: 2227830794.0,
      연체율: 1.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 248193050127.0,
      연계대출잔액: 4428346491.0,
      평균금리: 0.1493,
      연체채권잔액: 127708993.0,
      연체율: 0.0288,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45388235114.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    합계: {
      누적연계대출금액: 307995985241.0,
      연계대출잔액: 6656177285.0,
      평균금리: 0.1538,
      연체채권잔액: 2355539787.0,
      연체율: 0.3539,
    },
  },
  '2022-05': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 695000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 2217430794.0,
      평균금리: 0.1628,
      연체채권잔액: 2217430794.0,
      연체율: 1.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 251243871505.0,
      연계대출잔액: 4824120396.0,
      평균금리: 0.1478,
      연체채권잔액: 130431419.0,
      연체율: 0.027,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45388235114.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    합계: {
      누적연계대출금액: 311046806619.0,
      연계대출잔액: 7041551190.0,
      평균금리: 0.1526,
      연체채권잔액: 2347862213.0,
      연체율: 0.3334,
    },
  },
  '2022-06': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 715000000.0,
      연계대출잔액: 20000000.0,
      평균금리: 0.07,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 2200530794.0,
      평균금리: 0.1629,
      연체채권잔액: 2200530794.0,
      연체율: 1.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 254230641717.0,
      연계대출잔액: 4813793725.0,
      평균금리: 0.1484,
      연체채권잔액: 103554808.0,
      연체율: 0.0215,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45388235114.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    합계: {
      누적연계대출금액: 314053576831.0,
      연계대출잔액: 7034324519.0,
      평균금리: 0.1527,
      연체채권잔액: 2304085602.0,
      연체율: 0.3275,
    },
  },
  '2022-07': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 715000000.0,
      연계대출잔액: 20000000.0,
      평균금리: 0.07,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 2188730794.0,
      평균금리: 0.163,
      연체채권잔액: 2188730794.0,
      연체율: 1.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 256940545980.0,
      연계대출잔액: 4881871787.0,
      평균금리: 0.1484,
      연체채권잔액: 102176442.0,
      연체율: 0.0209,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45388235114.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    합계: {
      누적연계대출금액: 316763481094.0,
      연계대출잔액: 7090602581.0,
      평균금리: 0.1527,
      연체채권잔액: 2290907236.0,
      연체율: 0.3231,
    },
  },
  '2022-08': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 715000000.0,
      연계대출잔액: 20000000.0,
      평균금리: 0.07,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 2177030794.0,
      평균금리: 0.1631,
      연체채권잔액: 2177030794.0,
      연체율: 1.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 259483086173.0,
      연계대출잔액: 4651747576.0,
      평균금리: 0.1487,
      연체채권잔액: 105694161.0,
      연체율: 0.0227,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45388235114.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    합계: {
      누적연계대출금액: 319306021287.0,
      연계대출잔액: 6848778370.0,
      평균금리: 0.1531,
      연체채권잔액: 2282724955.0,
      연체율: 0.3333,
    },
  },
  '2022-09': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 715000000.0,
      연계대출잔액: 20000000.0,
      평균금리: 0.07,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 2173330794.0,
      평균금리: 0.1631,
      연체채권잔액: 2173330794.0,
      연체율: 1.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 262109174868.0,
      연계대출잔액: 4635336855.0,
      평균금리: 0.149,
      연체채권잔액: 118080098.0,
      연체율: 0.0255,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45388235114.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    합계: {
      누적연계대출금액: 321932109982.0,
      연계대출잔액: 6828667649.0,
      평균금리: 0.1532,
      연체채권잔액: 2291410892.0,
      연체율: 0.3356,
    },
  },
  '2022-10': {
    부동산PF: {
      누적연계대출금액: 0.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    부동산담보: {
      누적연계대출금액: 715000000.0,
      연계대출잔액: 20000000.0,
      평균금리: 0.07,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    '기타담보(어음.매출채권담보제외)': {
      누적연계대출금액: 12974700000.0,
      연계대출잔액: 2166630794.0,
      평균금리: 0.1632,
      연체채권잔액: 2166630794.0,
      연체율: 1.0,
    },
    '어음.매출채권담보': {
      누적연계대출금액: 745000000.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    개인사업자신용대출: {
      누적연계대출금액: 264629239206.0,
      연계대출잔액: 4500160798.0,
      평균금리: 0.149,
      연체채권잔액: 136006680.0,
      연체율: 0.0302,
    },
    법인사업자신용대출: {
      누적연계대출금액: 45388235114.0,
      연계대출잔액: 0.0,
      평균금리: 0.0,
      연체채권잔액: 0.0,
      연체율: 0.0,
    },
    합계: {
      누적연계대출금액: 324452174320.0,
      연계대출잔액: 6686791592.0,
      평균금리: 0.1533,
      연체채권잔액: 2302637474.0,
      연체율: 0.3444,
    },
  },
}
