<template>
  <div class="valid-account">
    <v-row class="margin-b-10" no-gutters>
      <v-col cols="12" sm="12" md="12" class="position--relative px-0">
        <div
          class="d-flex account__select align-center justify-space-between"
          @click="onClickCodeSelectBar"
          :style="isDisabled ? 'cursor: not-allowed;' : ''"
          style="height: 46px; border-radius: 4px; border: solid 1px #e7edf6"
        >
          <template v-if="inputBankCode">
            <p class="d-flex ma-0 align-center width-100">
              <img
                class="margin-r-10 bank__image"
                :src="
                  require('@/assets/images/common/banks/' +
                    inputBankCode +
                    '.jpg')
                "
              />
              {{ inputBankName }}
            </p>
          </template>
          <template v-else>
            <p class="d-flex ma-0 align-center font-weight-bold">
              은행을 선택해 주세요.
            </p>
          </template>
          <span class="detail-arrow" :class="{ active: showAllowBanks }"></span>
        </div>
        <ul v-show="showAllowBanks" class="account__bank-list margin-t-5 px-0">
          <template v-if="allowBanks">
            <li v-for="allowBank in allowBanks" :key="allowBank.code">
              <p
                class="d-flex ma-0 align-center"
                :class="{ active: inputBankCode == allowBank.code }"
                @click="selectBank(allowBank)"
              >
                <img
                  class="margin-r-10 bank__image"
                  :src="
                    require('@/assets/images/common/banks/' +
                      allowBank.code +
                      '.jpg')
                  "
                />
                {{ allowBank.name }}
              </p>
            </li>
          </template>
          <template v-else>
            <li>
              <p class="d-flex ma-0 align-center">
                현재 은행목록을 불러올 수 없습니다.
              </p>
            </li>
          </template>
        </ul>
      </v-col>
    </v-row>

    <v-row class="margin-b-5" no-gutters>
      <v-col cols="12" sm="12" md="12" class="px-0">
        <v-row no-gutters>
          <v-col cols="12" sm="12" md="12" class="margin-b-10">
            <input
              v-model="inputAccountNo"
              :disabled="isDisabled"
              class="width-100"
              placeholder="계좌번호를 입력해주세요"
              numberonly
              style="
                font-size: 15px;
                height: 46px;
                border-radius: 4px;
                border: solid 1px #e7edf6;
                box-shadow: none;
                padding: 6px 12px;
              "
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="12" md="12" class="px-0">
            <p
              class="color--error font-size--13 line-height--16 margin-t-7 mb-0"
            >
              ※ 적금, 휴면 계좌의 경우 송금이 불가능하오니 예금 계좌로
              입력해주세요.
            </p>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="12" md="12" class="width-100 px-0 mt-3">
            <v-btn
              color="primary"
              class="width-100 font-size--16"
              style="padding-top: 15px; padding-bottom: 15px"
              :disabled="
                valid.validLoading ||
                  !!!this.inputBankCode ||
                  !!!this.inputAccountNo
              "
              @click="validAccount()"
            >
              1원 인증하기
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { CodeTypes } from '@/store/types/code'

export default {
  props: {
    isDisabled: Boolean,
  },
  data() {
    return {
      showAllowBanks: false,
      accountForm: {
        bankCode: null,
        accountNo: null,
        accountName: null,
      },
      inputBankCode: null,
      inputBankName: null,
      inputAccountNo: null,
      valid: {
        validLoading: false,
      },
    }
  },
  computed: {
    ...mapGetters('code', {
      allowBanks: CodeTypes.getters.GET_BANKS,
    }),
  },
  watch: {
    inputBankCode: function(v) {
      if (!!this.accountForm.accountName && this.accountForm.bankCode !== v) {
        this.accountForm.accountName = null
      }
    },
    inputAccountNo: function(v) {
      if (!!this.accountForm.accountName && this.accountForm.accountNo !== v) {
        this.accountForm.accountName = null
      }
    },
    'accountForm.accountName': function() {
      this.$emit('valid-account-form', this.accountForm)
    },
  },
  async created() {
    await this.getBanks()
  },
  methods: {
    ...mapActions('code', {
      getBanks: CodeTypes.actions.GET_BANKS,
    }),
    onClickCodeSelectBar() {
      if (this.isDisabled) {
        return
      }
      this.showAllowBanks = !this.showAllowBanks
    },
    selectBank(bank) {
      this.inputBankCode = bank.code
      this.inputBankName = bank.name
      this.showAllowBanks = false
    },
  },
}
</script>

<style lang="scss" scoped>
.valid-account .btn-funda-basic,
.valid-account .btn-funda-basic--reverse {
  padding: 10px 24px;
  border-radius: 3px;
}

.valid-account .bank__image {
  max-width: 100px;
}

.valid-account .account__select {
  width: 100%;
  height: 40px;
  padding: 0 12px;
  border: solid 1px #ccc;
  border-radius: 3px;
  cursor: pointer;
}

.valid-account .account__select .detail-arrow {
  content: '';
  margin-top: 0;
  left: unset;
  right: 15px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #747474;
}

.valid-account .account__select .detail-arrow.active {
  border-top: unset;
  border-bottom: 6px solid #747474;
}

.valid-account .account__bank-list {
  position: absolute;
  width: 100%;
  max-height: 500px;
  background-color: white;
  border: solid 1px #ccc;
  box-shadow: none;
  list-style: none;
  overflow: scroll;
  z-index: 99;
}

.valid-account .account__bank-list li p {
  padding: 10px 12px;
  border-bottom: solid 1px #ddd;
  cursor: pointer;
}

.valid-account .account__bank-list li p:hover,
.valid-account .account__bank-list li p.active {
  background-color: #f9f9f9 !important;
  font-weight: bold;
}

.valid-account .attention {
  color: #f9356a;
  font-size: 13px;
}
</style>
