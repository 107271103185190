<template>
  <div id="is-all-depositable" style="height: 26px">
    <label class="funda-switch" @click="updateIsAllDepositable()">
      <input
        :checked="!this.isAllDepositable"
        type="checkbox"
        disabled
        readonly
      />
      <span class="slider round"></span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    isAllDepositable: Boolean,
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async updateIsAllDepositable() {
      let btnResult = await this.$swal.basic.confirm(
        '입금계좌 제한을 변경하시겠습니까?'
      )
      if (btnResult.isConfirmed !== true) {
        return
      }

      if (this.loading === true) {
        return
      }

      this.loading = true

      let q = `
              data: wwwUpdateAllDepositable(isAllDepositable: ${!this
                .isAllDepositable}){
                  type
                  isAllDepositable
                }
            `

      try {
        let data = await this.$fundaApi.mutation(gql`{${q}}`)

        if (data == null) {
          this.$swal.basic.alert(
            '입금계좌 제한 처리중 에러가 발생했습니다. 문제가 지속되면 문의바랍니다.'
          )
        } else {
          // 현재 isAllDepositable 이 사용되는 곳이 BalanceDepositAccount.vue 밖에 없어서
          // BalanceDepositAccount.vue에서는 'update:is-all-depositable' event를 받았을 때
          // 계좌 정보 조회를 새로 하는 게 아니라
          // isAllDepositable 값만 event value로 변경하게 되어 있음
          this.$emit('update:is-all-depositable', data.isAllDepositable)
          this.$swal.basic.alert(
            '입금계좌 제한 서비스가 정상적으로 ' +
              (data.isAllDepositable ? 'OFF' : 'ON') +
              ' 되었습니다.'
          )
        }
      } catch (e) {
        this.$swal.basic.alert(e.message)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
