<template>
  <div>
    <Header />
    <Article fluid />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/legacy/Header.vue'
import Article from './Article.vue'
import Footer from '@/components/legacy/Footer.vue'

export default {
  components: {
    Header,
    Article,
    Footer,
  },
}
</script>
