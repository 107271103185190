<template>
  <v-row class="position--relative" no-gutters>
    <v-col cols="12">
      <p class="ani-twinkle display-title">
        <v-icon class="font-size--16" color="#f47f1e">
          mdi-store
        </v-icon>
        사장님들의 한마디!
      </p>
    </v-col>
    <v-col cols="12">
      <div class="comment-display">
        <v-row no-gutters>
          <v-col cols="12">
            <div class="comment">
              <p v-html="selectedComment.comment" class="comment__txt"></p>
              <p class="comment__name">
                - {{ getStoreName(selectedComment.fnStore.storeName) }}
              </p>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      comments: [],
      selectedComment: null,
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    getRandomNum(min, max) {
      return Math.floor(Math.random() * (max - min)) + min
    },
    getStoreName(storeName) {
      if (!!storeName) {
        return `${storeName} 사장님`
      }
      return '상점명 비공개'
    },
    getComments() {
      this.comments = [
        {
          comment:
            '투자자분들 덕분에 열심히 일할수 있습니다. 감사합니다. 더 열심히 일하겠습니다!',
          fnStore: {
            storeName: '상하치과',
          },
        },
        {
          comment: '코로나시대 힘내서 맛있는 커피를 만들겠습니다!!!',
          fnStore: {
            storeName: '제이든커피',
          },
        },
        {
          comment: '어려운 시기 투자자분들 덕분에 큰힘이 됐습니다. 감사합니다.',
          fnStore: {
            storeName: '원조부안집',
          },
        },
        {
          comment: `교동면옥 북구점입니다~
          어려운 시기에 조금이나마 도움주셔서 감사합니다~ 저희 매장을 믿고 투자하신만큼 더 힘내 최선을 다하겠습니다~ㅎㅎ
          `,
          fnStore: {
            storeName: '교동면옥 북구점',
          },
        },
        {
          comment: `코로나때문에 힘든데 투자자분들 덕분에 살고있어요. 최선을 다해서 장사하겠습니다. 감사합니다~
          `,
          fnStore: {
            storeName: '고가네칼국수',
          },
        },

        {
          comment: `요즘 같은 어려움에 투자자분들 덕분에 힘이 납니다!! 으라찻차!!
          `,
          fnStore: {
            storeName: '신전떡볶이',
          },
        },
        {
          comment: `어려운시기 투자자님들 덕분에 잘 <br />
          이겨내고있어요~도움주시는 투자자님들 위해 더더 <br />
          열심히 장사할게요ღ'ᴗ'ღ 모두 화이팅!!୧ʕ•̀ᴥ•́ʔ୨
          `,
          fnStore: {
            storeName: '십원집 입암점',
          },
        },
        {
          comment: `힘든시기에 정말 감사해요. 앞으로도 잘 부탁드려요. 더욱더 열심히해서 꼭 보답할께요. 잘 부탁드려요.
          `,
          fnStore: {
            storeName: '철인7호 화성향남점',
          },
        },
        {
          comment: `항상 감사해요.
          `,
          fnStore: {
            storeName: '피자알볼로 봉천점',
          },
        },
        {
          comment: `맛차이를 운영중인 중식 20년차 오너쉐프입니다. 열심히 하겠습니다.
          `,
          fnStore: {
            storeName: '맛차이',
          },
        },
        {
          comment: `투자자 덕분에 힘이 납니다. 투자자를 위해서라도 열심히 장사할게요!!! 감사합니다.
          `,
          fnStore: {
            storeName: '만불등갈비',
          },
        },
        {
          comment: `힘든시기에 도움 주셔서 잘 버티고 있습니다~감사합니다^^
          `,
          fnStore: {
            storeName: '원조옛날빈대떡',
          },
        },
        {
          comment: `^^ 항상 감사합니다.
          `,
          fnStore: {
            storeName: '횡성생고기',
          },
        },
        {
          comment: `투자자분들 투자하신만큼 미래에 큰 득이 되어 돌아갈 수 있게 열심히 장사할게요.
          `,
          fnStore: {
            storeName: '미라클PC',
          },
        },
        {
          comment: `투자해주셔서 감사합니다. <br />
          더 열심히 힘내서 화이팅하겠습니다^^
          `,
          fnStore: {
            storeName: '삼화영동대리점',
          },
        },
        {
          comment: `새해 복 많이 받으세용♡
          `,
          fnStore: {
            storeName: '도노',
          },
        },
        {
          comment: `저희 가게에 투자해주신 분들 위해서 더욱 더 열심히 <br />
          운영하겠습니다. 감사합니다.
          `,
          fnStore: {
            storeName: '아미아트피자치킨',
          },
        },
        {
          comment: `감사합니다.
          `,
          fnStore: {
            storeName: '주가리곱창',
          },
        },
        {
          comment: `명랑핫도그 수원터미널점입니다. <br />
          어려운 시기 펀다로 도움 받고 있어요. <br />
          서로 윈윈할 수 있도록 노력할게요. <br />
          많은 투자 부탁드려요 ~~^^
          `,
          fnStore: {
            storeName: '명랑핫도그 수원터미널점',
          },
        },
        {
          comment: `안녕하세요. 청주에서 다사랑이란 치킨집을 운영중입니다. <br />
          금방 끝날거 같았던 코로나가 길어지면서 힘든 시기에 <br />
          펀다를 통해 많은 도움을 받게 되었습니다. <br />
          다 같이 어려운 시기에 믿고 투자해주시는 투자자님들 펀다에 계신 모든분들 진심 다 해 감사드립니다. <br />
          2022년 새해 복 많이 받으세요~
          `,
          fnStore: {
            storeName: '다사랑치킨피자',
          },
        },
        {
          comment: `감사합니다! 맛있는 커피 많이 팔아보겠습니다!
          `,
          fnStore: {
            storeName: '카페0521',
          },
        },
        {
          comment: `투자자님들 덕분에 한고비 한고비 넘김니다. 감사합니다 .
          `,
          fnStore: {
            storeName: '한우장터국밥',
          },
        },
        {
          comment: `투자자님 덕분에 더욱 더 힘내겠습니다!
          `,
          fnStore: {
            storeName: '청연 센텀점',
          },
        },
        {
          comment: `투자자분들 덕분에!! 힘든시기 잘 극복하고있어요. 감사합니다💚
          `,
          fnStore: {
            storeName: '박김밥',
          },
        },
        {
          comment: `정말 어려운 시기임에도 <br />
          많이 도와주셔서 정말 감사할 따름입니다. <br />
          저도 투자자분들처럼 누군가에게 베풀면서 사는 그런 사회적 기둥이 될 수 있도록 열심히 살아보겠습니다!!!
          `,
          fnStore: {
            storeName: '타코파파',
          },
        },
        {
          comment: `어려운 시기에 덕분에 잘 이겨내고 있습니다! 힘이 납니다!! 더 화이팅 하겠습니다! 앞으로도 잘 부탁드립니다^^
          `,
          fnStore: {
            storeName: '에스와이푸드',
          },
        },
        {
          comment: `투자해주셔서 감사합니다.
          `,
          fnStore: {
            storeName: '오라',
          },
        },
        {
          comment: `다 함께 힘내고 열심히 장사할께요. ^^
          `,
          fnStore: {
            storeName: '진주국밥',
          },
        },
        {
          comment: `열심히 살아가려고 노력중인 1인입니다. 투자자분들이 도와주시면 더욱 더 분발하여 더욱 더 열심히 살아가겠습니다.
          `,
          fnStore: {
            storeName: '동대문떡볶이',
          },
        },
        {
          comment: `투자자님~~ 오늘도 열심히 벌고 있는 모무꼬를 위해 투자 마니 부탁드립니다~ 따뜻한 명절 보내세요♡
          `,
          fnStore: {
            storeName: '모무꼬',
          },
        },
      ]
    },
    async fetchData() {
      if (this.isLoading) {
        return
      }
      this.isLoading = true

      this.getComments()
      const ranNum = this.getRandomNum(0, this.comments.length)
      this.selectedComment = this.comments[ranNum]

      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.display-title {
  // color: #fa7d56;
  color: #f47f1e;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 3px;
}

.comment-display {
  padding: 10px 15px;
  // background-color: #ffffff;
  background-color: rgb(27, 170, 241, 0.1);
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(23, 25, 26, 0.1);
  font-weight: bold;
}

.comment {
  &__txt {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 4px;
  }

  &__name {
    margin: 0;
    text-align: right;
  }
}
</style>
