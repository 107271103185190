<template>
  <div class="register-complete">
    <div class="container">
      <v-row no-gutters>
        <div class="hero d-flex justify-center align-center mx-auto">
          <h1>회원가입이 <br /><b>완료되었습니다</b></h1>
          <div class="mobile-display mb-5">
            <h2 class="text-center">회원가입이 <br /><b>완료되었습니다</b></h2>
          </div>
        </div>
      </v-row>
      <v-row v-if="!waitingChangeInvestorType" no-gutters>
        <div style="background-color: #25aae1; width: 100%">
          <h4
            style="
              color: white;
              text-align: center;
              margin: 0px;
              padding-top: 30px;
              padding-bottom: 30px;
            "
          >
            아래의 가상계좌에 투자 예치금을 충전하고
            <br class="mobile-display" />지금 바로 투자를 시작해보세요!
          </h4>
        </div>
        <div class="col-md-12">
          <div class="virtual-account text-center mx-auto mb-3">
            <h4>
              <strong>{{ profile.name }}</strong> 고객님의
              <br class="visible-lg" />가상계좌
            </h4>
            <div class="seperate-line"></div>
            <h4 v-if="profile.newBankAccountFunda">
              <span style="color: #444; font-size: 14px">
                {{ profile.newBankAccountFunda.bank }} (예금주 :
                {{ profile.newBankAccountFunda.accountHolder }}) </span
              ><br />
              <span style="font-size: 22px">
                <b>{{ profile.newBankAccountFunda.account }}</b>
              </span>
              <button
                class="clipboard"
                @click="
                  copyRecommenderCode(profile.newBankAccountFunda.account)
                "
              >
                복사하기
              </button>
            </h4>
          </div>
        </div>
        <div class="col-md-12 text-center">
          <span class="text-blue">
            ※ 충전된 예치금과 거래내역은 ‘마이펀다 > 입출금내역’ 에서 확인하실
            수 있습니다.
          </span>
        </div>
      </v-row>
      <v-row v-else no-gutters>
        <v-col cols="12" style="background-color: #25aae1; width: 100%">
          <h3
            style="
              color: white;
              text-align: center;
              margin: 0px;
              padding-top: 30px;
              padding-bottom: 30px;
            "
          >
            서류 심사 후 승인이 되면
            <br class="mobile-display" />투자를 시작하실 수 있습니다.
          </h3>
        </v-col>
        <v-col cols="12" v-if="profile.investmentLimit.type === '법인투자자'">
          <v-row class="d-flex justify-center benefit-title" no-gutters>
            <h3>법인 투자자 <span>혜택 세가지!</span></h3>
            <div class="line-black"></div>
          </v-row>
          <v-row class="mt-10 benefit-contents" no-gutters>
            <v-col cols="12" md="4" sm="12" class="d-flex justify-center">
              <figure>
                <img
                  class="hidden-xs"
                  src="@/assets/images/consult/pc_img01.png"
                  alt="order_1"
                />
              </figure>
              <div class="contents-detail">
                <img
                  class="visible-xs"
                  src="@/assets/images/consult/mo_img01.png"
                  alt="order_1"
                />
                <h4>투자한도 무제한</h4>
                <p>
                  법인 투자자는 펀다 상품 뿐만 아니라<br class="hidden-xs" />
                  타 P2P 상품에도 투자금액 제한이<br />
                  없습니다
                </p>
              </div>
            </v-col>
            <v-col cols="12" md="4" sm="12" class="d-flex justify-center">
              <figure>
                <img
                  class="hidden-xs"
                  src="@/assets/images/consult/pc_img02.png"
                  alt="order_2"
                />
              </figure>
              <div class="contents-detail">
                <img
                  class="visible-xs"
                  src="@/assets/images/consult/mo_img02.png"
                  alt="order_2"
                />
                <h4>
                  제휴 회계 법인의<br />
                  투자 법인 전문 컨설팅
                </h4>
                <p>
                  제휴 회계법인에서 투자 법인 설립과<br class="hidden-xs" />
                  절세 방안에 대해 상세히 안내 드리고<br />
                  실제 행정처리를 도와드립니다.
                </p>
              </div>
            </v-col>
            <v-col cols="12" md="4" sm="12" class="d-flex justify-center">
              <figure>
                <img
                  class="hidden-xs"
                  src="@/assets/images/consult/pc_img03.png"
                  alt="order_3"
                />
              </figure>
              <div class="contents-detail">
                <img
                  class="visible-xs"
                  src="@/assets/images/consult/mo_img03.png"
                  alt="order_3"
                />
                <h4>자동 투자 지원</h4>
                <p>
                  자동 분산투자'를 통해 편리하고 안전한<br class="hidden-xs" />
                  자산관리 솔루션을 제공합니다
                </p>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <div class="col-md-12 d-flex mt-10">
          <v-btn
            :to="{ name: 'invest' }"
            color="primary"
            class="btn_full mx-auto"
            depressed
          >
            투자상품 둘러보러 가기
          </v-btn>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import { AuthTypes } from '@/store/types/auth'
import { mapActions } from 'vuex'
import { textCopy } from '@/util'

export default {
  layout: 'legacy',
  data() {
    return {
      profile: {},
      waitingChangeInvestorType: false,
    }
  },
  async created() {
    this.profile = await this.getProfile()
  },
  watch: {
    'profile.requestInvestorChange.status': function(newVal) {
      if (newVal === 2) {
        this.waitingChangeInvestorType = true
      }
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  methods: {
    ...mapActions('auth', {
      getProfile: AuthTypes.actions.GET_PROFILE,
    }),
    copyRecommenderCode(value) {
      textCopy(value)
      this.$swal.basic.alert('가상계좌번호가 복사되었습니다')
    },
  },
}
</script>

<style lang="scss" scoped>
.register-complete {
  padding-bottom: 150px;
  background-color: white;

  .container {
    padding: 0;

    .hero {
      width: 100%;
      height: 235px;
      background: url('~@/assets/images/signup_complete_bg.png');
      background-repeat: no-repeat;
      background-position: right;

      @media screen and (max-width: 768px) {
        background-position: unset;
      }
    }
  }
}
.benefit {
  &-title {
    h3 {
      padding-top: 30px;
      text-align: center;
      font-size: 28px;
      font-weight: 400;

      span {
        font-weight: 500;
        border-bottom: 1px solid #cccccc;
      }

      @media (max-width: 768px) {
        font-size: 25px;
      }
    }
  }

  &-contents {
    figure {
      width: 33px;
      display: inline-block;
      vertical-align: top;

      @media (max-width: 768px) {
        display: none;
      }
    }

    .contents-detail {
      margin-top: 10px;

      p {
        font-size: 16px;
        margin-top: 30px;
      }

      @media (max-width: 768px) {
        height: 100%;
      }
    }

    h4 {
      font-size: 2rem;
      height: 44px;
    }

    @media (max-width: 768px) {
      padding-top: 0px;

      .container {
        padding-bottom: 0;

        .contents {
          text-align: center;
          padding-left: 0px;
          padding-right: 0px;

          &:nth-child(1) {
            .contents-detail img {
              width: 12.2%;
            }
          }

          &:nth-child(2) {
            margin: 15px 0 30px;

            .contents-detail {
              h4 {
                top: 30px;
                right: 16px;
              }

              p {
                margin-top: 35px;
              }
            }
          }
        }
      }

      h4 {
        display: inline-block;
        height: auto;
        font-size: 18px;
        position: relative;
        top: 25px;
      }

      .contents-detail {
        img {
          width: 14%;
          display: inline-block !important;
        }

        p {
          margin-top: 30px;
        }
      }
    }
  }
}

.pc-display-inline-block {
  display: inline-block;
}

.mobile-display,
.mobile-display-table,
.mobile-inline-block {
  display: none;
}

h1,
.h1 {
  font-size: 28px;
  color: #444;
}

h2,
.h2 {
  font-size: 26px;
  color: #444;
}

h4,
.h4 {
  font-size: 18px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.virtual-account {
  margin: 40px auto 0;
  border: 1px solid #dfdfdf;
  max-width: 456px;
  padding: 20px 10px;

  h4 {
    display: inline-block;
    line-height: 25px;
    text-align: left;

    span {
      color: #25aae1;
    }
  }
}

.text-blue {
  color: #25aae1;
}

.btn_full {
  color: #ffffff;
  width: 465px;
  height: 44px !important;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.seperate-line {
  border-right: 1px solid #dfdfdf;
  display: inline-block;
  height: 35px;
  margin: 0 15px;

  @media screen and (max-width: 970px) {
    display: none;
  }
}

button.clipboard {
  margin-left: 10px;
  background-color: white;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  font-size: 13px;
  padding: 0px 10px;
  vertical-align: top;
}

@media screen and (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
}
</style>
