<template>
  <div class="text-popup">
    <p class="text-body mb-1">
      <span class="color-funda--basic font-weight-bold">
        ※ 본인인증 불가 회원 예치금 출금서비스 지원 안내
      </span>
      <a
        class="text-decoration--underline"
        target="_blank"
        href="https://blog.naver.com/fundamaster/222527522736"
      >
        ( 상세보기 )
      </a>
    </p>
    <p class="text-body mb-1">
      <b>1. 투자자 가상계좌 변경</b> <br />
      중앙기록관리기관, 예치기관 연동으로 투자자 가상계좌가 변경되었습니다. 입금
      및 출금에 참고해 주시길 부탁드립니다.
    </p>
    <p class="text-body mb-1">
      <b>2. 입금계좌 제한 안내</b> <br />
      예치금 입출금은 본인인증 과정에서 등록한 '인증 계좌'에서만 진행이
      가능합니다. 금융 사고 예방을 위한 권고 사항으로 투자자분들의 양해
      부탁드립니다.
    </p>
    <p class="text-body mb-1">
      <b>3. 포인트 사용 일시 중단 안내</b> <br />
      현재 포인트 사용은 불가하며, 지연기간만큼 포인트 사용기간은 자동으로
      연장됩니다. 포인트 사용가능 일정은 추가 공지를 통하여 안내드리겠습니다.
    </p>
    <p class="text-body mb-1">
      <b>4. 수수료 오표기 및 일부 과수취 반환건 처리 작업 중 안내</b> <br />
      일부 상품의 수수료 오표기 및 수수료 과수취건에 대하여 현재 데이터 수정과
      수수료 과수취분에 대한 반환처리 작업이 진행 중에 있습니다. 투자서비스
      이용에 불편을 드려 죄송합니다. 최대한 빠르게 데이터가 정상 반영될 수
      있도록 노력하겠습니다.
    </p>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
