<template>
  <div style="background-color: #ffffff">
    <section id="hero_2" class="main-300">
      <v-row class="intro_title" no-gutters>
        <v-col cols="12" sm="12" md="12">
          <p class="side-line">
            <span> 세이프플랜 {{ fundNum }}기 펀드 </span>
          </p>
          <p class="utd-line text-white margin-t-30 font-weight-bold">
            업데이트 기준: {{ updateYMD }}
          </p>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <img src="@/assets/images/safeplan/img01.png" class="safe_bgimg" />
        </v-col>
      </v-row>
    </section>

    <template v-if="!!safeplanTemplate">
      <div
        v-html="safeplanTemplate"
        class="container__inner"
        style="padding-bottom: 5%"
      ></div>
    </template>
    <template v-else>
      <div class="text-center">
        <MyLoader />
      </div>
    </template>
  </div>
</template>
<script>
import MyLoader from '@/components/MyLoader'

export default {
  layout: 'legacy',
  props: {
    fundNum: {
      type: String,
      default: '4',
    },
  },
  components: {
    MyLoader,
  },
  data() {
    return {
      tab: null,
      safeplanFund: null,
      safeplanTemplate: null,
    }
  },
  computed: {
    updateYMD() {
      if (this.safeplanFund === null) {
        return
      }
      let ud = new Date(this.safeplanFund.updateDate)

      return `${ud.getFullYear()}년 
      ${parseInt(ud.getMonth() + 1)}월 
      ${ud.getDate()}일`
    },
  },
  async created() {
    _seo({
      titles: ['세이프플랜 운영 현황'],
    })
    this.getSafeplanFund()
    this.getSafeplanTemplate()
  },
  methods: {
    async getSafeplanFund() {
      let q = `data: getProvisionFund(inning: ${this.fundNum}) {
                title
                status
                inning
                savedAmount
                useAmount
                updateDate
              }`

      let data = await this.$fundaApi.query(gql`{${q}}`)
      this.safeplanFund = data
    },
    async getSafeplanTemplate() {
      let q = `data : getSafeplanHtml(provisionFundIdx: ${this.fundNum})`
      let data = await this.$fundaApi.query(gql`{${q}}`)

      this.safeplanTemplate = data
    },
  },
}
</script>

<style lang="scss" scoped>
#hero_2 {
  position: relative;
  color: #fff;
  width: 100%;
  background-color: #3868ac;
  font-size: 16px;
  display: table;
  text-align: center;

  &.main-300 {
    height: 300px;
    padding: 80px 0 60px;

    @media (max-width: 991px) {
      height: 150px;
    }

    .side-line {
      span {
        position: relative;
        font-size: 30px;
        color: #fff;

        @media (max-width: 768px) {
          font-size: 25px;
        }

        &::before {
          content: '';
          position: absolute;
          height: 5px;
          right: 100%;
          margin-right: 15px;
          border-top: 1px solid white;
          top: 10px;
          width: 80px;

          @media (max-width: 768px) {
            width: 30px;
          }
        }

        &::after {
          content: '';
          position: absolute;
          height: 5px;
          left: 100%;
          margin-left: 15px;
          border-top: 1px solid white;
          top: 10px;
          width: 80px;

          @media (max-width: 768px) {
            width: 30px;
          }
        }
      }
    }

    .utd-line {
      font-size: 25px;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }

    .safe_bgimg {
      margin-top: 35px;
      width: 170px;
    }
  }

  .intro_title {
    display: table-cell;
    vertical-align: middle;
  }
}
</style>
