<template>
  <div id="ch-plugin"></div>
</template>

<script>
import { AuthTypes } from '@/store/types/auth'
import { InvestmentTypes } from '@/store/types/investment'
import { mapGetters, mapActions } from 'vuex'
import { jsLoad, uuid } from '@/util'

export default {
  props: {
    pluginId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      initialized: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      userIdx: AuthTypes.getters.GET_USER_IDX,
      profile: AuthTypes.getters.GET_PROFILE,
      userValidation: AuthTypes.getters.GET_USER_VALIDATION,
    }),
    ...mapGetters('investment', {
      lastInvestment: InvestmentTypes.getters.GET_LAST_INVESTMENT,
    }),
  },
  watch: {
    async profile(value) {
      let user = {
        profile: value,
      }
      if (value) {
        await this.getLastInvestment()

        const add = {
          lastInvestTime: this.$options.filters.dateFormat(
            this.lastInvestment?.datetime
          ),
          lastInvestDiffDays: this.lastInvestment?.investDateDiff,
          isValid: this.userValidation?.isValid,
          smsType: this.userIdx % 10,
        }

        user.profile = {
          ...user.profile,
          ...add,
        }

        user.memberId = value.id
      } else {
        user.memberId = uuid()
      }
      this.init(user)
    },
  },
  async created() {
    if (
      navigator.userAgent.toLowerCase().indexOf('prerender') === -1 &&
      location.pathname.indexOf('oauth/login') === -1
    ) {
      await jsLoad({
        id: 'scChannelIdPlugin',
        src: '//cdn.channel.io/plugin/ch-plugin-web.js',
      })

      this.init()
    }
  },
  methods: {
    ...mapActions('investment', {
      getLastInvestment: InvestmentTypes.actions.GET_LAST_INVESTMENT,
    }),
    init(option = {}) {
      if (!window.ChannelIO) {
        setTimeout(this.init, 500)
        return
      }
      window.ChannelIO(
        'boot',
        Object.assign(option, {
          pluginKey: this.pluginId,
          customLauncherSelector: '#funda-launcher',
        }),
        function onBoot(error, user) {
          if (error) {
            console.error(error)
          }
        }
      )
    },
  },
}
</script>

<style></style>
