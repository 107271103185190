<template>
  <div id="corporation">
    <h3 class="consult_info">
      현재 법인이 없는 개인투자자 분들을 위해<br />
      법인 설립 및 절세방안에 대한 컨설팅을 진행하고 있습니다
    </h3>
    <div class="benefit">
      <h3 class="benefit_title">법인 투자자 <span>혜택 세가지!</span></h3>
      <div class="line-black"></div>
      <div class="benefit_contents">
        <div class="container">
          <v-row no-gutters>
            <v-col cols="12" md="4" sm="12" class="contents">
              <figure>
                <img
                  class="hidden-xs"
                  src="@/assets/images/consult/pc_img01.png"
                  alt="order_1"
                />
              </figure>
              <div class="contents-detail">
                <h4 style="height: 20px">
                  <img
                    class="visible-xs"
                    src="@/assets/images/consult/mo_img01.png"
                    alt="order_1"
                    style="width: 15%"
                  />
                  투자한도 무제한
                </h4>
                <p>
                  법인 투자자는 펀다 상품 뿐만 아니라<br class="hidden-xs" />
                  타 P2P 상품에도 투자금액 제한이<br />
                  없습니다
                </p>
              </div>
            </v-col>
            <v-col cols="12" md="4" sm="12" class="contents">
              <figure>
                <img
                  class="hidden-xs"
                  src="@/assets/images/consult/pc_img02.png"
                  alt="order_2"
                />
              </figure>
              <div class="contents-detail">
                <h4 style="height: 20px">
                  <img
                    class="visible-xs"
                    src="@/assets/images/consult/mo_img02.png"
                    alt="order_2"
                    style="width: 21%"
                  />
                  제휴 회계 법인의<br />
                  투자 법인 전문 컨설팅
                </h4>
                <p>
                  제휴 회계법인에서 투자 법인 설립과<br class="hidden-xs" />
                  절세 방안에 대해 상세히 안내 드리고<br />
                  실제 행정처리를 도와드립니다!
                </p>
              </div>
            </v-col>
            <v-col cols="12" md="4" sm="12" class="contents">
              <figure>
                <img
                  class="hidden-xs"
                  src="@/assets/images/consult/pc_img03.png"
                  alt="order_3"
                />
              </figure>
              <div class="contents-detail">
                <h4 style="height: 20px">
                  <img
                    class="visible-xs"
                    src="@/assets/images/consult/mo_img03.png"
                    alt="order_3"
                  />
                  자동 투자 지원
                </h4>
                <p>
                  자동 분산투자'를 통해 편리하고 안전한<br class="hidden-xs" />
                  자산관리 솔루션을 제공합니다
                </p>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <div class="application">
      <h3 class="application_title">상담신청</h3>
      <div class="line-black"></div>
      <div class="application_form">
        <div class="application_guide">
          <p>
            법인 투자자 등록 을 원하시는 투자회원님은 아래정보를 기입하시면<br />
            기입 하신 상담 가능 시간에 전문 상담원이 친절히 진행 도와
            드리겠습니다
          </p>
        </div>
        <v-container>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  label="법인명(현재 법인 소유자만 적어주세요)"
                  v-model="consult.corpName"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="성명"
                  v-model="consult.personInCharge"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="전화번호"
                  v-model="consult.phone"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  outlined
                  label="문의내용"
                  v-model="consult.consultContent"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-card-actions class="justify-center">
              <v-btn
                :disabled="!isFormValid"
                :loading="addConsultLoading"
                outlined
                rounded
                text
                @click="addConsult(consult)"
              >
                상담 신청하기
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-container>
      </div>
    </div>
    <div class="page_guide">
      <h3 class="page_title">서류안내</h3>
      <div class="line-black"></div>
      <div class="page_content">
        <p>
          상담이 필요없이 바로 등록을 원하시는 고객은 `등록 필요 서류 보기`에
          나와있는 서류를 Contact@funda.kr로<br />
          발송해주시면 투자한도를 바로 증액하여 드리겠습니다
        </p>
      </div>
      <div class="btn-wrapper">
        <a
          href="https://blog.naver.com/fundamaster/222077418411"
          target="_blank"
          >등록 필요 서류 보기<img src="@/assets/images/left_icon_arrow.png"
        /></a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      consult: {},
      addConsultLoading: false,
      rules: {
        required: value => !!value || '값을 입력해주세요.',
      },
    }
  },
  created() {
    this.init()
  },
  computed: {
    isFormValid() {
      return this.consult.phone && this.consult.personInCharge
    },
  },
  methods: {
    init() {
      this.consult = {
        corpName: '',
        personInCharge: '',
        phone: '',
        consultContent: '',
        consultGroup: '법인',
      }
    },
    async addConsult(consult) {
      let btnResult = await this.$swal.basic.confirm({
        text: '등록하시겠습니까?',
      })

      if (btnResult.isConfirmed !== true) {
        return
      }
      if (this.addConsultLoading) {
        return
      }
      this.addConsultLoading = true
      try {
        await this.$fundaApi.mutation(
          gql`
            {
              addConsultApplicationData(inputConsultApplicationData: $value) {
                idx
                corpName
                personInCharge
                phone
                consultGroup
              }
            }
          `,
          'test($value: InputConsultApplicationData)',
          {
            value: consult,
          }
        )
        await this.$swal.basic.alert('접수가 완료 되었습니다.')
        this.init()
      } finally {
        this.addConsultLoading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
$white: #ffffff;
$blue: #25aae1;
$text-color: #555555;

#corporation {
  padding-top: 30px;

  .consult_info {
    text-align: center;
    color: $text-color;
    font-size: 1.57rem;
    font-weight: 400;
    border-bottom: 1px dashed #dad8d8;
    padding: 20px 0 50px;

    @media (max-width: 768px) {
      padding-bottom: 40px;
      font-size: 1.28rem;
      margin-bottom: 5px;
    }
  }

  .benefit {
    .benefit_title {
      padding-top: 45px;
      text-align: center;
      color: $text-color;
      font-size: 2.3rem;
      font-weight: 400;

      span {
        font-weight: 500;
        color: $text-color;
        border-bottom: 1px solid #cccccc;
      }

      @media (max-width: 768px) {
        font-size: 25px;
        padding-top: 34px;
      }
    }

    .benefit_contents {
      padding-top: 20px;

      .container {
        padding: 0 0 60px;
      }

      figure {
        width: 33px;
        display: inline-block;
        vertical-align: top;

        @media (max-width: 768px) {
          display: none;
        }
      }

      .contents-detail {
        margin-top: 10px;
        display: inline-block;
        width: calc(100% - 38px);
        vertical-align: top;
        height: 38px;

        p {
          font-size: 16px;
          color: $text-color;
          margin-top: 29px;
          line-height: 22px;
        }

        @media (max-width: 768px) {
          height: 100%;
        }
      }

      h4 {
        font-size: 1.43rem;
        height: 44px;
        color: $text-color;
      }

      @media (max-width: 768px) {
        padding-top: 0px;

        .container {
          padding-bottom: 0;

          .contents {
            text-align: center;
            padding-left: 0px;
            padding-right: 0px;

            &:nth-child(1) {
              .contents-detail img {
                width: 12.2%;
              }
            }

            &:nth-child(2) {
              margin: 15px 0 30px;

              .contents-detail {
                h4 {
                  top: 30px;
                  right: 16px;
                }

                p {
                  margin-top: 35px;
                }
              }
            }
          }
        }

        h4 {
          display: inline-block;
          height: auto;
          font-size: 1.21rem;
          position: relative;
          top: 20px;
          right: 10px;
        }

        .contents-detail {
          img {
            width: 15%;
            display: inline-block !important;
          }

          p {
            margin-top: 25px;
          }
        }
      }
    }
  }

  .application {
    padding-top: 60px;

    .container {
      padding-left: 0px;
      padding-right: 0px;
    }

    form {
      margin-top: 40px;

      label {
        font-weight: 400;
        color: $text-color;
        font-size: 17px;
      }

      input {
        border-radius: 2px;
        height: 38px;
      }

      button {
        border-radius: 50px;
        background-color: #f8f9fb;
        border: 1px solid $text-color;
        color: $text-color;
        display: block;
        width: 200px;
        height: 44px;
        margin: 30px auto 50px;
        font-size: 16px;
      }

      @media (max-width: 768px) {
        margin-top: 15px;

        .form-group {
          padding-left: 0px;
          padding-right: 0px;
        }

        button {
          margin: 30px auto 20px;
        }
      }
    }

    .application_title {
      padding-top: 30px;
      text-align: center;
      color: $text-color;
      font-size: 2.35em;
      font-weight: 400;

      @media (max-width: 768px) {
        font-size: 25px;
      }
    }

    .application_guide {
      text-align: center;
      border: 1px solid #ebebeb;
      border-radius: 5px;
      padding: 15px 20px;
      background-color: #f8f9fb;

      p {
        margin: 0;
        font-size: 16px;
        line-height: 22px;
      }
    }

    @media (max-width: 768px) {
      padding-top: 30px;
    }
  }

  .page_guide {
    .page_title {
      text-align: center;
      color: $text-color;
      font-size: 2.35rem;
      font-weight: 400;

      @media (max-width: 768px) {
        font-size: 25px;
      }
    }

    .page_content {
      text-align: center;
      border: 1px solid #ebebeb;
      border-radius: 5px;
      padding: 15px 20px;
      background-color: #f8f9fb;

      p {
        margin: 0;
        font-size: 16px;
        line-height: 22px;
      }
    }

    .btn-wrapper {
      text-align: center;
      a {
        border-radius: 50px;
        background-color: #f8f9fb;
        border: 1px solid $text-color;
        color: $text-color;
        padding: 12px 0;
        display: block;
        width: 200px;
        margin: 40px auto;
        font-size: 16px;

        &:hover,
        &:visited,
        &:active,
        &:focus {
          text-decoration: none;
        }
      }

      img {
        width: 5%;
        position: relative;
        left: 5px;
        bottom: 2px;
      }
    }
  }

  h2 {
    text-align: center;
    margin-bottom: 50px;
    color: $text-color;
  }

  .line-black {
    width: 35px;
    border-top: 1px solid #333333;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  @media (max-width: 768px) {
    padding-top: 1rem;

    h2 {
      font-size: 25px;
      font-weight: 400;
      margin-bottom: 28px;
    }
  }
}
</style>
