<template>
  <section class="main-structure-section">
    <div class="container">
      <div class="mss-container">
        <h1>나의 투자금은 <br class="mss-m-break" />어떻게 이용되나요?</h1>
        <h3>
          <span>대출 상점의 카드사 매출을 직접 </span>
          <span>펀다가 수취하여 안정성을 높임과 동시에 </span>
          <span>매일 상환이 이뤄 집니다.</span>
        </h3>
        <div class="mss-icons-wrapper">
          <div class="mss-icon-wrapper bottom-text">
            <img src="@/assets/images/ic-ic-new-creditcard-w.svg" />
            <p>카드사</p>
          </div>
          <div class="mss-icon-wrapper">
            <p>카드매출<br />입금</p>
            <img src="@/assets/images/etc-etc-new-arrow-r.svg" />
          </div>
          <div class="mss-icon-wrapper bottom-text">
            <img src="@/assets/images/ic-ic-new-shop-w.svg" />
            <p>상점</p>
          </div>
          <div class="mss-icon-wrapper mss-double-icons">
            <div>
              <img src="@/assets/images/etc-etc-new-arrow-r-w.svg" />
              <p>카드매출<br />자동입금</p>
            </div>
          </div>
          <div class="mss-icon-wrapper">
            <img src="@/assets/images/logo-funda-white.svg" />
          </div>
          <div class="mss-icon-wrapper">
            <p>투자금<br />상환</p>
            <img src="@/assets/images/etc-new-arrow-r-b.svg" />
          </div>
          <div class="mss-icon-wrapper bottom-text">
            <img src="@/assets/images/ic-ic-new-man-b.svg" />
            <p>투자자</p>
          </div>
        </div>
        <div class="mss-icons-wrapper-m">
          <div class="mss-icons-row">
            <div>
              <img src="@/assets/images/ic-ic-new-creditcard-w.svg" />
              <p>카드사</p>
            </div>
            <div>
              <p>카드매출<br />입금</p>
              <img src="@/assets/images/etc-etc-new-arrow-r.svg" />
            </div>
            <div>
              <img src="@/assets/images/ic-ic-new-shop-w.svg" />
              <p>상점</p>
            </div>
          </div>
          <div class="mss-icons-row">
            <div>
              <img src="@/assets/images/etc-new-arrow-down.svg" />
              <p>카드매출<br />자동입금</p>
            </div>
          </div>
          <div class="mss-icons-row">
            <div>
              <img src="@/assets/images/logo-funda-white.svg" />
            </div>
            <div>
              <p>투자금<br />상환</p>
              <img src="@/assets/images/etc-new-arrow-r-b.svg" />
            </div>
            <div>
              <img src="@/assets/images/ic-ic-new-man-b.svg" />
              <p>투자자</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.main-structure-section {
  min-height: 506px;
  background-color: #3e4650;
}
.main-structure-section .mss-container {
  margin: 0 auto;
  max-width: 620px;
}
.main-structure-section .mss-container h1 {
  font-size: 32px;
  font-weight: 900;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  margin: 100px 0 14px;
}
.main-structure-section .mss-container h3 {
  width: 400px;
  text-align: center;
  margin: 0 auto 80px;
}
.main-structure-section .mss-container h3 span {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}
.main-structure-section .mss-icons-wrapper {
  display: flex;
  height: 121px;
  align-items: center;
}
.main-structure-section .mss-icons-wrapper .mss-icon-wrapper:not(:last-child) {
  margin-right: 40px;
}
.main-structure-section .mss-icons-wrapper .mss-icon-wrapper {
  position: relative;
}
.main-structure-section .mss-icons-wrapper .mss-icon-wrapper img {
  display: block;
}
.main-structure-section .mss-icons-wrapper .bottom-text p {
  top: 33px;
}
.main-structure-section .mss-icons-wrapper .mss-icon-wrapper p {
  position: absolute;
  text-align: center;
  margin: 14px auto;
  font-size: 13px;
  font-weight: 900;
  line-height: 18px;
  color: #e1e9f3;
  bottom: 0;
  width: 100%;
}
.main-structure-section .mss-icons-wrapper .mss-icon-wrapper:not(:last-child) {
  margin-right: 40px;
}
.main-structure-section .mss-icons-wrapper .mss-icon-wrapper {
  position: relative;
}
.main-structure-section .mss-icons-wrapper .mss-icon-wrapper:not(:last-child) {
  margin-right: 40px;
}
.mss-icons-wrapper-m {
  display: none;
  width: 100%;
  height: 85px;
  padding: 0 8px;
  min-height: 260px;
}

.mss-m-break {
  display: none;
}

.mss-icons-wrapper-m .mss-icons-row div {
  position: relative;
  display: flex;
  align-items: center;
  width: 60px;
  height: 85px;
}
.mss-icons-wrapper-m .mss-icons-row div img {
  display: block;
  margin: 0 auto;
}
.mss-icons-wrapper-m .mss-icons-row div p {
  position: absolute;
  margin: 0;
  text-align: center;
  font-size: 13px;
  font-weight: 900;
  line-height: 18px;
  color: #e1e9f3;
  bottom: 0;
  width: 100%;
}
.mss-icons-wrapper-m .mss-icons-row:nth-child(2) div {
  width: 10px;
}
.mss-icons-wrapper-m .mss-icons-row:nth-child(2) div p {
  width: 66px;
  margin-top: 22px;
  margin: 0;
}
.mss-icons-wrapper-m .mss-icons-row:nth-child(1) div:nth-child(2) p {
  bottom: 52px;
}
.mss-icons-wrapper-m .mss-icons-row:nth-child(1) {
  position: relative;
}
.mss-icons-wrapper-m .mss-icons-row:nth-child(2) {
  display: flex;
  justify-content: center;
}
.mss-icons-wrapper-m .mss-icons-row:nth-child(3) {
  display: flex;
  position: relative;
  margin-top: -15px;
}
.mss-icons-wrapper-m .mss-icons-row:nth-child(1) div:last-child {
  position: absolute;
  top: 0;
  margin-left: 50%;
  transform: translateX(-50%);
}
.mss-icons-wrapper-m .mss-icons-row:nth-child(1) div:nth-child(2) {
  position: absolute;
  top: 0;
  margin-left: 30%;
  transform: translateX(-50%);
}
.mss-icons-wrapper-m .mss-icons-row:nth-child(2) div {
  width: 10px;
}
.mss-icons-wrapper-m .mss-icons-row:nth-child(2) div:first-child p {
  right: 0;
  margin: 0 8px 24px;
}
.mss-icons-wrapper-m .mss-icons-row:nth-child(2) div:last-child p {
  left: 0;
  margin: 0 18px 24px;
}
.mss-icons-wrapper-m .mss-icons-row:nth-child(3) {
  display: flex;
  position: relative;
  margin-top: -15px;
}
.mss-icons-wrapper-m .mss-icons-row:nth-child(3) div:nth-child(1) {
  position: absolute;
  top: 0;
  margin-left: 50%;
  transform: translateX(-50%);
  width: 87px;
}
.mss-icons-wrapper-m .mss-icons-row:nth-child(3) div:nth-child(2) {
  position: absolute;
  top: 0;
  margin-left: 75%;
  transform: translateX(-50%);
}
.mss-icons-wrapper-m .mss-icons-row:nth-child(3) div:nth-child(3) {
  position: absolute;
  top: 0;
  right: 0;
}
@media screen and (max-width: 767px) {
  .main-structure-section .mss-container h1 {
    font-size: 25px;
    line-height: 33px;
    margin: 50px 0 14px;
  }
  .main-structure-section .mss-container h3 {
    margin: 0 auto 45px;
  }
}

@media screen and (max-width: 700px) {
  .main-structure-section .mss-container {
    margin-bottom: 25px;
  }
  .mss-m-break {
    display: block;
  }
  .main-structure-section .mss-icons-wrapper {
    display: none;
  }
  .mss-icons-wrapper-m {
    display: block;
  }
}

@media screen and (max-width: 480px) {
  .main-structure-section .mss-container h1 {
    font-size: 25px;
    margin: 50px 0 14px;
  }
  .main-structure-section .mss-container h3 {
    width: 80%;
    margin: 0 auto 45px;
  }
  .main-structure-section .mss-container h3 span {
    font-size: 15px;
  }
}
@media screen and (max-width: 401px) {
  .main-structure-section .mss-container h3 span {
    display: block;
  }
  .main-structure-section .mss-container h3 {
    width: 100%;
    margin: 25px auto 45px;
  }
}
</style>
