<template>
  <div class="signUp">
    <section class="signUp-box-header" style="background: white">
      <div class="container container__inner">
        <div id="dimmer"></div>
        <div class="loader-box">
          <h4>가입 진행 중입니다<br />잠시만 기다려주세요</h4>
          <div class="sp sp-3balls"></div>
        </div>
        <div class="row">
          <div class="col-md-8 tablet-none pa-0">
            <div class="register">
              <p>
                투자를 통해 상생의 가치를 실현하는 ‘관계 금융’의 내일, 지금,
                펀다와 함께 시작하세요!
              </p>

              <h3 class="text-blue">
                IBK가 인정한 펀다의 상점 매출 분석 기술력 펀다 에이전트
              </h3>
              <p>
                펀다는 상점의 POS 매출 데이터 분석을 바탕으로 상점의 대출적격
                여부를 심사해 5 ~ 17.9% 수준의 중금리 신용 대출 서비스를
                제공합니다.
              </p>

              <h3 class="text-blue">초저금리 시대, 새로운 재테크의 시작</h3>
              <p class="mb-0">
                투자자들은 펀다가 엄선한 상점들의 대출채권 투자를 통해 연 10%
                초반의 수익을 기대할 수 있습니다.(세전 기준)
              </p>
              <p class="mb-0">
                펀다는 동산 및 부동산의 담보 등의 장치를 통해 채권의 안정성을
                강화해나가는 한편,
              </p>
              <p class="last">
                다양한 리워드와 추가 금리 혜택 등으로 투자의 즐거움을
                제공합니다.
              </p>
              <p style="font-size: 11px; color: #ccc">
                * 리워드와 추가 금리 적용은 각 채권상품에 따라 상이하게
                적용됩니다.
              </p>
              <p style="font-size: 14px; font-weight: 700">
                “펀다는 좋은 투자처만 엄선합니다”
              </p>

              <picture>
                <img src="@/assets/images/signup/WELCOME_NEW_MEMBER.png" />
              </picture>
            </div>
          </div>
          <div class="col-md-4 px-0">
            <picture class="mobile-display">
              <img src="@/assets/images/signup/WELCOME_NEW_MEMBER.png" />
            </picture>
            <v-form @submit.prevent="signup(signupUser)">
              <div id="register-box" class="signUp-box">
                <h3>회원가입</h3>

                <hr />

                <button
                  v-if="!!!signupSocial"
                  class="btn__social btn__social--kakao"
                  :disabled="signupLoading"
                  @click="clickKakao()"
                >
                  카카오 간편 회원가입
                </button>

                <p class="sign-up__sub-header color-black mt-5">필수항목</p>

                <div><v-icon small>mdi-account</v-icon>아이디/이메일</div>
                <v-text-field
                  solo
                  dense
                  single-line
                  v-model="signupUser.id"
                  :rules="rules.email"
                  :error-messages="idDuplicateMessage"
                  name="id"
                  autocomplete="off"
                  label="이메일을 입력해주세요"
                  :disabled="isSocial"
                ></v-text-field>
                <template v-if="isSocial === false">
                  <div><v-icon small>mdi-key-variant</v-icon>비밀번호</div>
                  <v-text-field
                    solo
                    dense
                    single-line
                    v-model="signupUser.password"
                    :rules="rules.password"
                    name="password"
                    type="password"
                    label="비밀번호를 입력해주세요(6~15자)"
                  ></v-text-field>

                  <div>
                    <v-icon small>mdi-key-variant</v-icon>비밀번호 재입력
                  </div>
                  <v-text-field
                    solo
                    dense
                    single-line
                    v-model="signupUser.rePassword"
                    :rules="rules.rePassword"
                    name="rePassword"
                    type="password"
                    label="비밀번호를 다시 입력해주세요"
                  ></v-text-field>
                </template>
                <v-text-field
                  solo
                  dense
                  single-line
                  v-model="signupUser.phone"
                  @input="formatPhone($event)"
                  autocomplete="off"
                  :rules="rules.phone"
                  name="phone"
                  label="휴대폰번호를 입력해주세요('-' 제외)"
                >
                  <template v-slot:append-outer>
                    <v-btn
                      depressed
                      @click="requestPhoneValid(signupUser)"
                      :loading="requestPhoneValidLoading"
                      :disabled="phoneValid"
                      color="primary"
                    >
                      인증
                    </v-btn>
                  </template>
                </v-text-field>
                <template v-if="signupUser.phoneUserAuthIdx">
                  <v-text-field
                    solo
                    dense
                    single-line
                    v-model="signupUser.phoneVerifyWord"
                    :disabled="phoneValid"
                    name="certi_num"
                    label="인증번호를 입력해주세요"
                  >
                    <template v-slot:append-outer>
                      <v-btn
                        depressed
                        @click="confirmPhoneValid(signupUser)"
                        :disabled="isVerifyWordValid || phoneValid"
                        :loading="confirmPhoneValidLoading"
                        color="primary"
                      >
                        인증 확인
                      </v-btn>
                    </template>
                  </v-text-field>
                </template>

                <div><v-icon small>mdi-magnify</v-icon>펀다를 알게된 경로</div>
                <v-select
                  solo
                  dense
                  name="referral"
                  :items="referralOptions"
                  :rules="rules.referral"
                  v-model="signupUser.referral"
                >
                </v-select>

                <p class="sign-up__sub-header color-black">선택항목</p>

                <div>추천인 코드</div>
                <v-text-field
                  solo
                  dense
                  single-line
                  :success="codeValid"
                  :error-messages="codeValidateMessage"
                  v-model="signupUser.code"
                  name="code"
                  label="공유받은 추천인 코드를 입력해주세요"
                ></v-text-field>

                <div>프로모션(파트너사) 코드</div>
                <v-text-field
                  solo
                  dense
                  single-line
                  :success="affiliateCodeValid"
                  :error-messages="affiliateCodeValidateMessage"
                  v-model="signupUser.affiliateCode"
                  name="affiliate-code"
                  label="공유받은 프로모션(파트너사) 코드를 입력해주세요"
                ></v-text-field>

                <hr class="mt-0" />

                <div
                  id="pass-info"
                  class="clearfix"
                  style="margin-bottom: 15px"
                ></div>
                <div id="terms">
                  <v-checkbox
                    hide-details
                    class="check-all"
                    @click="checkAll"
                    label="투자알림 및 약관에 모두 동의합니다."
                  ></v-checkbox>
                </div>
                <div id="policy" style="margin-top: 0px; padding: 0px">
                  <v-checkbox
                    hide-details
                    v-model="checkOptions.noticeAgree"
                    label="휴대폰으로 투자알림 받기"
                  ></v-checkbox>
                  <small
                    class="text-plain"
                    style="margin-left: 34px; font-size: 11px; display: block"
                    >투자할 상품의 정보를 문자로 알려드립니다</small
                  >

                  <v-checkbox
                    :rules="rules.serviceAgree"
                    v-model="checkOptions.serviceAgree"
                  >
                    <template v-slot:label>
                      <div>
                        서비스 이용약관에 동의합니다.
                        <v-tooltip>
                          <template v-slot:activator="{ on }">
                            <a
                              target="_blank"
                              href="/terms?mode=service"
                              @click.stop
                              v-on="on"
                            >
                              [보기]
                            </a>
                          </template>
                        </v-tooltip>
                      </div>
                    </template>
                  </v-checkbox>
                  <v-checkbox
                    :rules="rules.infoAgree"
                    v-model="checkOptions.infoAgree"
                  >
                    <template v-slot:label>
                      <div>
                        개인정보 취급방침에 동의합니다.
                        <v-tooltip>
                          <template v-slot:activator="{ on }">
                            <a
                              target="_blank"
                              href="/terms?mode=info"
                              @click.stop
                              v-on="on"
                            >
                              [보기]
                            </a>
                          </template>
                        </v-tooltip>
                      </div>
                    </template>
                  </v-checkbox>
                </div>
                <v-btn
                  depressed
                  color="primary"
                  type="submit"
                  height="44"
                  width="100%"
                  :disabled="!isFormValid"
                  :loading="signupLoading"
                >
                  가입하기
                </v-btn>
              </div>
            </v-form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import AuthService from '@/service/auth/AuthService'
import Regex from '@/const/Regex'
import { v4 as uuidv4 } from 'uuid'

export default {
  layout: 'legacy',
  props: ['promotionCode', 'recommenderCode'],
  data() {
    return {
      isSocial: false,
      valid: true,
      idValid: false,
      emailValid: false,
      phoneValid: false,
      codeValid: false,
      affiliateCodeValid: false,
      signupLoading: false,
      requestPhoneValidLoading: false,
      confirmPhoneValidLoading: false,
      idDuplicateMessage: [],
      emailDuplicateMessage: [],
      codeValidateMessage: [],
      affiliateCodeValidateMessage: [],
      checkOptions: {
        isCheckAll: false,
        noticeAgree: true,
        serviceAgree: false,
        infoAgree: false,
      },
      signupUser: {
        id: '',
        password: '',
        rePassword: '',
        phone: '',
        email: '',
        referral: '선택해주세요',
        phoneVerifyWord: '',
        phoneUserAuthIdx: 0,
        code: null,
        affiliateCode: null,
        newsletterPhone: 'checked',
        agree: ['n', 'n'],
      },
      referralOptions: [
        '선택해주세요',
        'P2P, 재태크 등 검색',
        '펀다 카카오톡 채널',
        '온라인 광고',
        '언론보도(신문, TV 등)',
        '재테크 관련 커뮤니티 (블로그, 카페 등)',
        'SNS (페이스북, 트위터, 밴드 등)',
        '지인 소개',
        '프로모션(파트너사) 코드',
      ],
      rules: {
        id: [
          value => !!value || '아이디를 입력해주세요.',
          value =>
            /^[a-zA-Z0-9]{4,}$/.test(value) ||
            '아이디는 영문, 숫자 4자리이상 입력해주세요',
        ],
        password: [
          value => !!value || '비밀번호를 입력해주세요.',
          value =>
            Regex.PASSWORD.REGEX.test(value) ||
            '비밀번호는 영문, 숫자, 특수문자(!@#$%^*+=-) 조합 6~15자로 입력해주세요',
        ],
        rePassword: [
          value =>
            value === this.signupUser.password ||
            '비밀번호가 일치하지 않습니다',
        ],
        phone: [
          value => !!value || '휴대전화번호를 입력해주세요',
          value =>
            /^01\d{8,9}$/.test(value) ||
            '휴대폰전화번호를 잘 못 입력하셨습니다',
        ],
        email: [
          value =>
            /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) ||
            '이메일 주소가 유효하지 않습니다',
        ],
        referral: [
          value =>
            value !== '선택해주세요' || '펀다를 알게된 경로를 선택해주세요',
        ],
        serviceAgree: [value => value || '서비스 이용약관에 동의해주세요'],
        infoAgree: [value => value || '개인정보 취급방침에 동의해주세요'],
      },
    }
  },
  watch: {
    'signupUser.id': {
      immediate: true,
      async handler(newVal) {
        if (!!newVal && newVal.length > 4) {
          await this.idDuplicate(newVal)
          if (this.idValid === true) {
            this.idDuplicateMessage = []
            this.signupUser.email = newVal
          } else {
            this.idDuplicateMessage = '이미 등록된 아이디 입니다'
          }
        }
      },
    },
    'signupUser.email': {
      immediate: true,
      async handler(newVal) {
        let valid = this.rules.email[0](newVal)
        if (valid === true) {
          await this.emailDuplicate(newVal)
          !this.emailValid
            ? (this.idDuplicateMessage = '이미 가입된 이메일 주소입니다.')
            : (this.idDuplicateMessage = [])
        } else {
          this.emailDuplicateMessage = valid
        }
      },
    },
    'signupUser.code': async function(newVal) {
      await this.confirmCodeValid(newVal)
      !this.codeValid
        ? (this.codeValidateMessage = '추천인 코드가 유효하지 않습니다')
        : (this.codeValidateMessage = [])
    },
    'signupUser.affiliateCode': async function(newVal) {
      await this.confirmAffiliateCodeValid(newVal)
      !this.affiliateCodeValid
        ? (this.affiliateCodeValidateMessage =
            '프로모션 코드가 유효하지 않습니다')
        : (this.affiliateCodeValidateMessage = [])
    },
    recommenderCode: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.signupUser.code = newVal
          this.$cookies.set('code', newVal, 0)
        } else {
          this.signupUser.code = this.$cookies.get('code') || ''
        }
      },
    },
    promotionCode: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.signupUser.affiliateCode = newVal
          this.$cookies.set('affiliateCode', newVal, 0)
        } else {
          this.signupUser.affiliateCode =
            this.$cookies.get('affiliateCode') || ''
        }
      },
    },
    'checkOptions.noticeAgree': function(newVal) {
      this.signupUser.newsletterPhone = newVal ? 'checked' : ''
    },
    'checkOptions.serviceAgree': function(newVal) {
      this.signupUser.agree[0] = newVal ? 'y' : 'n'
    },
    'checkOptions.infoAgree': function(newVal) {
      this.signupUser.agree[1] = newVal ? 'y' : 'n'
    },
    signupSocial: {
      immediate: true,
      handler() {
        if (!!this.signupSocial) {
          this.isSocial = true
          this.signupUser.id = this.signupSocial.profile.email
          this.signupUser.email = this.signupSocial.profile.email
          if (this.signupSocial.profile.phone) {
            this.signupUser.phone = this.signupSocial.profile.phone.replace(
              /-/g,
              ''
            )
          }
          const password =
            uuidv4()
              .replace(/-/g, '')
              .substring(0, 13) + '!'
          this.signupUser.password = password
          this.signupUser.rePassword = password
        }
      },
    },
  },
  computed: {
    ...mapGetters('auth', {
      signupSocial: AuthTypes.getters.GET_SIGN_UP_SOCIAL,
    }),
    isVerifyWordValid() {
      return (
        !this.signupUser.phoneVerifyWord ||
        this.signupUser.phoneVerifyWord.length > 6
      )
    },
    isFormValid() {
      return (
        this.signupUser.id &&
        this.signupUser.password &&
        this.signupUser.rePassword &&
        this.signupUser.phone &&
        this.signupUser.phoneVerifyWord &&
        this.signupUser.phoneUserAuthIdx &&
        this.signupUser.email &&
        this.signupUser.referral !== '선택해주세요' &&
        this.phoneValid &&
        this.emailValid &&
        this.codeValid &&
        this.affiliateCodeValid &&
        this.checkOptions.serviceAgree &&
        this.checkOptions.infoAgree
      )
    },
  },

  created() {
    _seo({
      titles: ['회원가입'],
    })
  },
  methods: {
    ...mapActions('auth', ['signinKakao', 'signinSns']),
    checkAll() {
      this.checkOptions.isCheckAll = !this.checkOptions.isCheckAll
      Object.keys(this.checkOptions).forEach((value, index) => {
        this.checkOptions[value] = this.checkOptions.isCheckAll
      })
    },
    formatPhone($event) {
      this.signupUser.phone = $event.replace('-', '')
    },
    async signup(signupUser) {
      try {
        this.signupLoading = true
        const { user } = await this.$fundaApi.mutation(
          gql`
            {
              user: signupUser(signupUser: $signupUser) {
                id
                idx
              }
            }
          `,
          'signupUser($signupUser: SignupUser!)',
          {
            signupUser,
          }
        )

        await AuthService.signin({
          username: user.id,
          password: signupUser.password,
        })
        if (this.isSocial === true) {
          this.signinSns(this.signupSocial)
        }

        this.$router.push({ name: 'register' })
      } catch (e) {
        if (e.code) {
          this.$swal.basic.error(e.errors[0].message)
        } else {
          throw e
        }
      } finally {
        this.signupLoading = false
      }
    },
    async idDuplicate(id) {
      let { idValid } = await this.$fundaApi.query(gql`{
        idValid : getSignupUserIdValid(id: "${id}")
      }`)
      this.idValid = idValid
    },
    async emailDuplicate(email) {
      if (email.length < 3) {
        return
      }
      try {
        let { emailValid } = await this.$fundaApi.query(gql`{
          emailValid : getSignupUserEmailValid(id: "${email}")
        }`)
        this.emailValid = emailValid
      } catch (e) {
        if (
          e.status === 400 &&
          e.errors[0].message === '이메일 주소가 유효하지 않습니다.'
        ) {
        } else {
          throw e
        }
      }
    },
    async requestPhoneValid(signupUser) {
      try {
        this.requestPhoneValidLoading = true
        let { phoneUserAuthIdx } = await this.$fundaApi.mutation(gql`{
          phoneUserAuthIdx: requestSignupPhoneAuth(phone: "${signupUser.phone}")
        }`)
        signupUser.phoneUserAuthIdx = phoneUserAuthIdx
      } catch (e) {
        if (e.code) {
          this.$swal.basic.error(e.errors[0].message)
        } else {
          throw e
        }
      } finally {
        this.requestPhoneValidLoading = false
      }
    },
    async confirmPhoneValid(signupUser) {
      try {
        this.confirmPhoneValidLoading = true
        await this.$fundaApi.mutation(gql`{
          confirmSignupPhoneAuth(
            userAuthIdx: ${signupUser.phoneUserAuthIdx}, 
            phone: "${signupUser.phone}", 
            verifyWord: "${signupUser.phoneVerifyWord}")
        }`)
        this.phoneValid = true
      } catch (e) {
        if (e.code) {
          this.$swal.basic.error(e.errors[0].message)
        } else {
          throw e
        }
      } finally {
        this.confirmPhoneValidLoading = false
      }
    },
    async confirmCodeValid(code) {
      if (!code || code === '') {
        this.codeValid = true
        return
      }

      try {
        let isValid = await this.$fundaApi.query(gql`{
          data: searchRecommenderCode(
            code: "${code}"
            ) {
              code,
              shortenUrl
            }
        }`)
        this.codeValid = !!isValid.code
      } catch (e) {
        this.codeValid = false
      }
    },
    async confirmAffiliateCodeValid(affiliateCode) {
      if (!affiliateCode || affiliateCode === '') {
        this.affiliateCodeValid = true
        return
      }

      try {
        let isValid = await this.$fundaApi.query(gql`{
          data: getPromotionCode(
            promotionCode: "${affiliateCode}"
            ) {
              idx,
              title
            }
        }`)
        this.affiliateCodeValid = !!isValid.idx
      } catch (e) {
        this.affiliateCodeValid = false
      }
    },
    async clickKakao() {
      try {
        this.signupLoading = true
        let resp = await this.signinKakao()
        if (resp === true) {
          await this.$swal.basic.alert('이미 가입된 회원입니다.')
          this.$router.push({ name: 'main' })
        }
      } catch (e) {
        this.$swal.basic.alert('추가정보를 입력하고 회원가입을 완료해주세요.')
      } finally {
        this.signupLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.signUp-box-header {
  font-family: 'Noto Sans', sans-serif !important;
  text-transform: none;
  font-size: 12px;

  & > .container {
    padding-top: 0px;
  }

  img {
    width: 100%;
  }
}

.register {
  margin: 70px 0 120px;
  color: #2b2b2b;
  text-align: left;
  padding: 35px;
  padding-left: 0px;

  p {
    line-height: 20px;
    margin-bottom: 50px;
  }
  .last {
    margin-bottom: 15px;
  }
}

.text-blue {
  color: #25aae1;
}

h3 {
  font-size: 22px;
  -webkit-font-smoothing: antialiased;
  margin-top: 20px;
  margin-bottom: 10px;

  @media screen and (max-width: 767px) {
    font-size: 19px;
  }
}

.signUp-box {
  margin: 20px 0 120px;
  color: #2b2b2b;
  text-align: left;
  background-color: #fff;
  -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
  padding: 30px;
}

.sign-up__sub-header {
  width: 110px;
  padding-bottom: 5px;
  border-bottom: 1px solid black;
  font-size: 17px;
  color: black;
}

.v-form {
  margin-bottom: 0px;
  margin-top: 15px;
  width: 100%;
  letter-spacing: 0;

  .container {
    padding-bottom: 0;
  }

  .v-input--selection-controls {
    padding-top: 0;
  }

  &::v-deep .v-text-field.v-input {
    font-size: 12px;

    .v-input__control {
      & > .v-input__slot {
        height: 34px;
        min-height: 34px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: none;

        &:before {
          border-style: none;
        }
      }

      & > .v-text-field__details {
        margin-bottom: 0;
      }

      &::v-deep .v-label {
        font-size: 12px;
        left: 12px !important;
      }
    }

    .v-label {
      font-size: 12px;
      top: 4px;
    }

    &:nth-of-type(7) {
      .v-input__control > .v-input__slot {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  &::v-deep .v-input__append-outer {
    margin-top: 0px !important;
    margin-left: 0px;

    .v-btn {
      height: 34px;
      min-width: 54.5px;
      font-weight: 600;
      letter-spacing: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &::v-deep .v-select__selection {
    font-size: 12px;
  }

  &::v-deep .v-input--selection-controls {
    margin-top: 0px;

    .v-input__slot > .v-label {
      color: #2b2b2b;
      font-size: 12px;
      font-weight: 900;
    }
  }

  &::v-deep .check-all.v-input--selection-controls .v-input__slot > .v-label {
    color: #f9356a;
  }

  .v-btn[type='submit'] {
    margin-bottom: 10px;
    margin-top: 15px;

    .v-btn__content {
      letter-spacing: 0;
    }
  }

  @media (max-width: 991px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 991px) {
  #register-box {
    margin-bottom: 50px;
  }

  .tablet-none {
    display: none !important;
  }
}

.mobile-display {
  display: none;
}

@media screen and (max-width: 767px) {
  .mobile-display {
    display: block;
  }

  .signUp-box {
    margin-top: 20px !important;
  }
}

.v-icon {
  color: #555;
}

#dimmer {
  z-index: 99;
  position: fixed;
  background-color: #000000;
  width: 100%;
  float: left;
  height: 100%;
  visibility: hidden;
  top: 0px;
  left: 0px;
}

.loader-box {
  z-index: 9999;
  display: none;
  position: fixed;
  width: 300px;
  padding: 15px 25px;
  top: 40%;
  left: 39.5%;
  border-radius: 3px;
  background-color: #fff;
}
</style>
