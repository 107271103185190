<template>
  <div>
    <v-row no-gutters>
      <v-col
        v-if="!!tempFile"
        cols="12"
        class="d-flex justify-center my-3 font-size--15"
      >
        <label class="mr-2 font-weight-bold" for="id-card">
          파일명:
        </label>

        <input readonly id="id-card" type="text" :value="tempFile.orgName" />
      </v-col>

      <v-col cols="12" :class="{ float: tempFile === null }">
        <S3FileUploadButton
          ref="fileUploadDriverLicense"
          uploadOnSelect
          :bucketName="bucketName"
          :path="path"
          :tempFile.sync="tempFile"
          :savedFile.sync="savedFile"
        >
          업로드
        </S3FileUploadButton>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import S3FileUploadButton from '@/components/common/form/S3FileUploadButton'

export default {
  components: {
    S3FileUploadButton,
  },
  data() {
    return {
      isLoading: false,
      bucketName: this.$store.state.env.aws.bucket.cdd,
      path: '/investor_type/',
      tempFile: null,
      savedFile: null,
      tempFile2: null,
      savedFile2: null,
    }
  },
  computed: {
    ...mapGetters('auth', {
      userIdx: AuthTypes.getters.GET_USER_IDX,
      profile: AuthTypes.getters.GET_PROFILE,
    }),
  },
}
</script>

<style lang="scss" scoped></style>
