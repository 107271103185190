<template>
  <div class="my-verify-form">
    <h3 class="text-center">출금계좌 인증</h3>
    <p
      class="font-weight-bold my-2 text-center"
    >
      입력하신 계좌로 1원을 보내드렸습니다.<br />
      입금자로 표시된 숫자를 입력해주세요.
    </p>
    <div class="my-verify-form__content rounded--6">
      <ValidationObserver ref="validForm" v-slot="{ invalid }">
        <ValidationProvider
          v-slot="{ errors }"
          name="code"
          rules="required|numeric|min:3|max:6"
          slim
        >
          <v-row no-gutters>
            <v-col
              cols="12"
              lg="12"
              md="12"
              sm="12"
              class="position--relative d-flex align-center"
            >
              <input
                v-model="code"
                type="tel"
                class="rounded--4 width-100 font-weight-black"
                :class="{ 'color--error': errors.length > 0 }"
                @input="inputCode()"
              />
            </v-col>
            <v-col
              cols="12"
              lg="12"
              md="12"
              sm="12"
              class="pl-sm-0 mt-2"
            >
              <button
                v-if="expireTime != null && minutes == 0 && seconds == 0"
                @click="requestResend"
                type="button"
                class="rounded--6 btn-funda--blue width-100 font-weight-black"
                :class="isMobile == true ? 'font-size--16' : 'font-size--15'"
                :disabled="isLoading"
                v-ripple
              >
                <template v-if="isLoading">
                  <span class="loading-spinner"></span>
                </template>
                <template v-else> 재전송 </template>
              </button>
              <button
                v-else
                @click="requestConfirm"
                type="button"
                class="rounded--6 btn-funda--blue width-100 font-weight-black"
                :class="isMobile == true ? 'font-size--16' : 'font-size--15'"
                :disabled="invalid || isLoading"
                v-ripple
              >
                <template v-if="isLoading">
                  <span class="loading-spinner"></span>
                </template>
                <template v-else>확인 </template>
              </button>
            </v-col>
          </v-row>
          <v-slide-y-transition hide-on-leave>
            <p
              v-if="errors.length > 0"
              class="mt-2 mb-0 color--error font-size--13"
            >
              {{ errors[0] }}
            </p>
          </v-slide-y-transition>
        </ValidationProvider>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errorMsg: {
      type: String,
      default: null,
    },
    expireTime: {
      type: Date,
      default: null,
      // required: true
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expireInterval: null,
      minutes: null,
      seconds: null,
      code: null,
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },
  watch: {
    errorMsg(v) {
      if (v != null) {
        this.$refs.validForm.setErrors({
          code: [v],
        })
      }
    },
    expireTime(v) {
      this.setExpireTime()
    },
  },
  created() {
    this.setExpireTime()
  },
  methods: {
    setExpireTime() {
      if (this.expireTime == null) {
        return
      }

      if (this.expireInterval != null) {
        clearInterval(this.expireInterval)
      }
      this.setMinutesSeconds()
      this.expireInterval = setInterval(_ => {
        this.setMinutesSeconds()
      }, 1000)
    },
    setMinutesSeconds() {
      let now = new Date().getTime()
      let expireIn = Math.floor((this.expireTime - now) / 1000)

      if (expireIn < 1) {
        clearInterval(this.expireInterval)
        this.minutes = 0
        this.seconds = 0
        return
      }

      this.minutes = Math.floor(expireIn / 60)
      this.seconds = expireIn % 60
    },
    inputCode() {
      if (!!this.code && this.code.length > 0) {
        let validCode = this.code.replace(/[^\d]/g, '').substring(0, 6)
        this.code = validCode
      }
    },
    requestResend() {
      this.code = null
      this.$emit('request-resend')
    },
    requestConfirm() {
      this.$emit('request-confirm', this.code)
    },
  },
  filters: {
    twoDigits(value) {
      let num = Number(value)
      return num < 10 ? '0' + num : num
    },
  },
}
</script>

<style lang="scss" scoped>
.my-verify-form {
  p {
    letter-spacing: -0.2px;
  }
  &__content {
    input {
      border: solid 1px #d7e1ea;
      background-color: white;
      padding: 6px 45px 6px 12px;
      font-size: 18px;
      height: 46px;
    }
    .expire-in {
      position: absolute;
      right: 0;
      letter-spacing: -0.2px;
      font-size: 14px;
      font-weight: 700;
    }
    button {
      height: 46px;
    }
  }
}
</style>
