<template>
  <div class="comp-wrapper">
    <div v-if="!!visitorText" class="counter-visitor" :class="{ show: isShow }">
      <v-row class="height-100" no-gutters>
        <v-col cols="11" class="counter-visitor__txt">
          <p v-html="visitorText" class="ma-0"></p>
        </v-col>
        <v-col cols="1" class="counter-visitor__close" @click="isShow = false">
          <p class="ma-0" style="line-height: 1">X</p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      curVisitors: 0,
    }
  },
  computed: {
    visitorText() {
      if (this.curVisitors < 1) {
        return null
      }
      return `현재 <span class="color-funda--basic font-size--15 font-weight-bold">${this.curVisitors}명</span>의 투자자가 페이지를 보고 있어요`
    },
  },
  watch: {
    visitorText() {
      this.isShow = true
      // setTimeout(() => {
      //   this.isShow = false
      // }, 4000)
    },
  },
  created() {
    this.getCurVisitors()
  },
  methods: {
    async getCurVisitors() {
      const q = 'data: getNumCurrentVisitors'
      try {
        const data = await this.$fundaApi.query(gql`{${q}}`)
        this.curVisitors = data
      } catch {}
    },
  },
}
</script>

<style lang="scss" scoped>
.comp-wrapper {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  max-width: 768px;
  width: 100%;
}

.counter-visitor {
  visibility: hidden;
  max-width: 320px;
  width: auto;
  height: 34px;
  background-color: rgb(51, 51, 51, 0.7);
  color: #fff;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  position: fixed;
  z-index: 1;
  top: 65px;
  white-space: nowrap;

  padding: 0 10px;
}

.counter-visitor__txt {
  display: flex;
  align-items: center;
  height: 100%;

  color: #fff;

  font-size: 14px;
  font-weight: bold;

  @media screen and (max-width: 320px) {
    font-size: 13px;
  }
}

.counter-visitor__close {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  cursor: pointer;
  padding-left: 10px;
}

.counter-visitor.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
}

@-webkit-keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 65px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 65px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    top: 65px;
    opacity: 1;
  }
  to {
    top: 0px;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    top: 65px;
    opacity: 1;
  }
  to {
    top: 0px;
    opacity: 0;
  }
}
</style>
