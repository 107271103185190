<template>
  <div>
    <div class="text-popup">
      <p class="text-body">
        안녕하세요. 펀다입니다. <br />
        설 휴무 및 사이트 점검 일정 안내드립니다.
      </p>
      <div class="table-area">
        <table>
          <tbody>
            <tr>
              <td class="td-first" width="25%">점검 일정</td>
              <td>
                2021년 2월 10일(수) 22시 <br class="mobile-display" />~ 2월
                15일(월) 10시
              </td>
            </tr>
          </tbody>
        </table>
        <p class="margin-t-5 mb-0">
          - 설 연휴 휴무: 2월 11일(목) ~ 2월 14일(일)
        </p>
      </div>
      <p class="text-body mb-0">
        사이트 점검 기간 동안
        <span class="font-weight-black" style="text-decoration: underline"
          >모든 서비스 이용이 제한</span
        >될 예정이오니 <br class="mobile-none" />이용에 참고 부탁드립니다.
        <br /><br />
        감사합니다.
      </p>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
