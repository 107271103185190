<template>
  <section class="main-invest-return-section">
    <div class="container">
      <div class="mirs-container">
        <h3 class="mirs-text mirs-title">
          <span class="mirs-text mirs-title color-default--gray">
            {{ statAmount | commaFormat }} </span
          >만원 투자자들의 실제 평균 수익금
        </h3>
        <div class="mirs-flex-wrapper">
          <div>
            <p>
              평균<br />
              수익금
            </p>
            <p>
              {{ investCumulativeAmountStats.averageReturn | commaFormat }}원
            </p>
          </div>
          <div>
            <p>
              평균<br />
              투자기간
            </p>
            <p>{{ investCumulativeAmountStats.averagePeriod }}개월</p>
          </div>
          <div>
            <p>
              원금 손실<br />
              고객비율&#42;
            </p>
            <p>{{ investCumulativeAmountStats.lossRate }}%</p>
          </div>
        </div>
        <p class="mirs-text mirs-summary">
          <span
            >약
            <b> {{ investCumulativeAmountStats.totalCount | commaFormat }}</b
            ><b>명</b>의 고객이</span
          >
          <span
            ><b> {{ statAmount | commaFormat }}</b
            ><b>만원을 투자</b>하였습니다.</span
          >
        </p>
        <p class="mirs-text mirs-summary">&#42;&nbsp;신용대출 기준</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    statIndex: {
      type: Number,
      default: 3,
    },
    statAmount: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      investCumulativeAmountStats: {
        averageReturn: 0,
        averagePeriod: 0,
        lossRate: 0.0,
        totalCount: 0,
      },
      investCumulativeAmountStatses: [],
    }
  },
  watch: {
    statIndex() {
      this.setPosition()
    },
  },
  created() {
    this.getInvestCumulativeAmountStats()
  },
  methods: {
    async getInvestCumulativeAmountStats() {
      let response = await this.$fundaApi.query(gql`
        {
          data: getInvestCumulativeAmountStats {
            from
            to
            averageReturn
            averagePeriod
            lossRate
            totalCount
          }
        }
      `)
      this.investCumulativeAmountStatses = response

      this.setPosition()
    },
    setPosition() {
      let selected = this.statIndex - 1
      if (selected < 0) {
        selected = 0
      }
      this.investCumulativeAmountStats = this.investCumulativeAmountStatses[
        selected
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.main-invest-return-section {
  background-color: #f2f9ff;
  min-height: 280px;
  user-select: none;
  -ms-user-select: none;
}
.mirs-container {
  max-width: 502px;
  margin: 60px auto 60px;
  color: #333333;
}
.mirs-container .mirs-title {
  font-size: 19px;
  font-weight: 900;
  line-height: 1.47;
  letter-spacing: -0.31px;
  margin: 0 0 30px;
}
.mirs-container .mirs-text {
  text-align: center;
}
.mirs-container .mirs-flex-wrapper {
  display: flex;
  justify-content: space-between;
}
.mirs-container .mirs-flex-wrapper div {
  flex-grow: 1;
  margin-bottom: 20px;
  position: relative;
}
.mirs-container .mirs-flex-wrapper div:nth-child(1) {
  padding-right: 80px;
  max-width: 161px;
}
.mirs-container .mirs-flex-wrapper div p:nth-of-type(1) {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 8px;
}
.mirs-container .mirs-flex-wrapper div p {
  letter-spacing: -0.3px;
  text-align: center;
}
.mirs-container .mirs-flex-wrapper div:nth-child(2) {
  padding: 0 60px;
}
.mirs-container .mirs-flex-wrapper div p:nth-of-type(2) {
  font-size: 19px;
  font-weight: 900;
  margin-bottom: 0px;
}
.mirs-container .mirs-flex-wrapper div:nth-child(2)::before {
  content: '';
  width: 1px;
  height: 30px;
  background: #b9c8dd;
  position: absolute;
  left: 0;
  top: 14px;
}
.mirs-container .mirs-flex-wrapper div:nth-child(2)::after {
  content: '';
  width: 1px;
  height: 30px;
  background: #b9c8dd;
  position: absolute;
  right: 0;
  top: 14px;
}
.mirs-container .mirs-flex-wrapper div:nth-child(3) {
  padding-left: 80px;
}
.mirs-container .mirs-summary {
  font-size: 15px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: -0.43px;
  margin-bottom: 0px;
}
.mirs-container .mirs-summary b {
  font-weight: 900;
}

@media screen and (max-width: 560px) {
  .mirs-container {
    margin: 40px auto 40px;
  }
  .mirs-container .mirs-title {
    margin: 0 0 35px;
  }
  .mirs-container .mirs-summary span {
    display: block;
  }
  .mirs-container .mirs-flex-wrapper div:nth-child(1) {
    padding-right: 4%;
  }
  .mirs-container .mirs-flex-wrapper div:nth-child(2) {
    padding: 0 4%;
  }
  .mirs-container .mirs-flex-wrapper div:nth-child(3) {
    padding-left: 4%;
  }
}
</style>
