<template>
  <v-row no-gutters>
    <v-col cols="12">
      <p class="mb-1 ani-twinkle color--warning">
        상점주들의 한마디!
      </p>
    </v-col>
    <v-col cols="12">
      <div class="rolling-display">
        <v-row class="rolling-display__scroll" no-gutters>
          <v-col cols="12">
            <div
              v-for="(roll, index) in rollingTable"
              :key="index"
              class="comment"
            >
              <p class="comment__name mb-1">
                {{ roll.fnStore.storeName }} 상점주:
              </p>
              <p
                v-html="roll.comment"
                class="comment__txt ma-0 font-size--14 text-ellipsis"
              ></p>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      comments: [],
      rollingTable: [],
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    getComments() {
      this.comments = []
    },
    async fetchData() {
      if (this.isLoading) {
        return
      }
      this.isLoading = true

      this.getComments()
      this.rollingTable = [...this.comments]

      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.rolling-display {
  padding: 15px 10px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(23, 25, 26, 0.1);
  font-weight: bold;
}

.rolling-display__scroll {
  position: relative;
  min-height: 33px;
  max-height: 33px;

  overflow: hidden;

  > div {
    position: absolute;
  }
}
</style>
