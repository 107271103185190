<template>
  <div class="ai-details">
    <div class="ai-details__avatar">
      <p class="ai-details__avatar__img" :class="{ rounded: !isFunda }">
        <img :src="require(`@/${myProfile.img}`)" :alt="myProfile.alt">
      </p>
      <p v-if="!isFunda" class="ai-details__avatar__name ml-1">
        {{ myProfile.text }}
      </p>
    </div>

    <p class="ai-details__talk" :style="{'--bubbleColor': bubbleColor}">
      <slot name="default"></slot>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    profile: {
      type: String,
      default: 'AI',
    },
    bubbleColor: {
      type: String,
      default: '#F8F8F8',
    },
  },
  data() {
    return {
      testC: 'red',
      profileTypes: {
        AI: {
          text: '펀다 AI가 매출을 어떻게 예측했을까요?',
          alt: 'funda-ai',
          img: 'assets/images/funding/funda-ai.svg',
        },
        funda: {
          text: 'FUNDA',
          alt: 'funda',
          img: 'assets/images/logo.png',
        },
        manager: {
          text: '펀다 투자 전문가',
          alt: '펀다 투자 전문가',
          img: 'assets/images/funding/manager.svg',
        },
      }
    }
  },
  computed: {
    myProfile() {
      return this.profileTypes[this.profile]
    },
    isFunda() {
      return this.profile === 'funda'
    }
  },
}
</script>

<style lang="scss" scoped>
.ai-details {
  &__avatar {
    display: flex;
    align-items: center;

    margin-bottom: 8px;

    p {
      margin: 0;
    }

    &__img {
      display: flex;
      width: 50px;
      height: 26px;

      img {
        width: 100%;
        object-fit: contain;
      }

      &.rounded {
        width: 26px;
        img { border-radius: 100%; }
      }
    }

    &__name {
      font-weight: 900;
    }
  }

  &__talk {
    position: relative;
    min-height: 60px;
    background-color: var(--bubbleColor);
    border-radius: 8px;

    font-size: 13px;
    line-height: 1.3;
    word-break: break-all;

    margin: 0;
    padding: 12px 8px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom-color: var(--bubbleColor);
      border-top: 0;
      border-right: 0;
      margin-left: 1px;
      margin-top: -6px;
    }
  }
}
</style>
