<template>
  <div class="invest">
    <div id="fundanow-img">
      <picture>
        <source
          media="(max-width: 768px)"
          srcset="//cdn.funda.kr/funding/clip/m_fundanow_manual_202101_2.png"
        />
        <img
          src="//cdn.funda.kr/funding/clip/pc_fundanow_manual_202101_2.png"
        />
      </picture>
    </div>
    <section class="qna-section">
      <div class="container padding-x-20">
        <div class="row margin-b-15">
          <div>
            <h4 class="font-weight--black">자주 묻는 질문</h4>
          </div>
        </div>
        <div class="row">
          <v-expansion-panels v-model="panel">
            <v-expansion-panel v-for="(item, i) in qnaItems" :key="i">
              <v-expansion-panel-header>
                <span>Q. </span>
                {{ item.title }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{ item.content }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </section>
    <div class="fixed-footer">
      <div class="container padding-x-20">
        <div class="row">
          <router-link :to="{ name: 'myfunda-auto-invest' }">
            <button class="btn-funda-basic--reverse font-weight-bold">
              자동투자 시작하기
              <span>
                <img
                  src="@/assets/images/common/icons/ic-ic-safe-btn-arrow-sw.svg"
                />
              </span>
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      panel: 0,
      qnaItems: [
        {
          title: '초단기 상품은 무엇인가요?',
          content: `펀다가 새로 출시한 펀다나우 1.5개월 초단기 상품은 대출자에게 '펀다나우 비상금 대출'이라는 이름으로 제공되는 초단기 대출상품입니다. 
          상점들의 일매출 수준을 분석하여 짧게는 0.5주에서 길게는 최대 2주치 매출만큼을 최대 한도로 빌려주고, 익일부터 발생하는 카드매출의 일부를(최대 50%) 펀다에 상환하는 구조입니다.`,
        },
        {
          title: '왜 자동투자 이용을 권장하나요?',
          content: `초단기 상품은 투자한 날로부터 1~5영업일 후(상점에 따라 상이)부터 일단위 상환이 진행됩니다.
          매일 일부씩 상환되는 금액은 당일 바로 재투자될 때 이 상품의 연수익률 달성 및 복리효과 등의 수익률 상승효과를 기대할 수 있기 때문에, 자동 분산투자를 통해 투자 기회를 제공합니다.`,
        },
      ],
    }
  },
  created() {
    _seo({
      titles: ['투자 상품'],
    })
  },
}
</script>
<style lang="scss" scoped>
#fundanow-img img {
  width: 100%;
}

.fixed-footer {
  position: fixed;
  width: 100%;
  height: 80px;
  bottom: 0;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;

  & > .padding-x-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: initial;
    padding-bottom: initial;
  }

  button {
    width: 240px;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 16px;
    text-align: left;
    box-shadow: 0 8px 16px 0 rgba(0, 38, 71, 0.16);

    span {
      float: right;
    }

    img {
      vertical-align: middle;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      height: 60px;
      font-size: 20px;
      box-shadow: 0 6px 12px 0 rgba(0, 38, 71, 0.16);
    }
  }

  @media screen and (max-width: 768px) {
    background-color: transparent;
    bottom: 70px;
    height: 100px;

    a {
      width: 100%;
    }
  }
}

.qna-section {
  padding: 80px 0px 160px;
  background-color: #f5f5f5;

  & > .padding-x-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: initial;
    padding-bottom: initial;
  }

  @media screen and (max-width: 768px) {
    padding: 40px 20px 80px;
  }
}

.qna-card {
  background-color: #ffffff;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.font-weight--bold {
  font-weight: bold;
}

.btn-funda-basic--reverse {
  background-color: #1baaf1;
  border: none;
  color: white;
}

.margin-b-15 {
  margin-bottom: 15px !important;
}

.font-weight--black {
  font-weight: 900;
}

h4 {
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  color: #444;
}

.v-expansion-panel-header {
  font-weight: 900;

  & > span {
    margin-right: 5px;
    flex: none !important;
    color: #1baaf1;
  }
}

::v-deep .v-expansion-panel-content {
  font-size: 15px;
  color: #727d8c;
  line-height: 1.5;
  border-top: 1px solid #f5f5f5;

  &__wrap {
    padding: 20px 24px;
  }
}
</style>
