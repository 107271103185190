<template>
  <section class="main-expected-rate-section">
    <div class="container">
      <div class="mers-container">
        <h3 class="mers-text">매일 상환되는</h3>
        <h1 class="mers-text">FUNDA 투자</h1>
        <v-slider
          v-model="slider.value"
          @change="setPosition"
          :track-color="slider.trackColor"
          color="white"
          min="0"
          max="5"
          thumb-color="white"
          thumb-label="always"
        >
          <template v-slot:thumb-label="{ value }">
            {{ slider.amounts[value] | commaFormat }}만원
          </template>
        </v-slider>
        <h4 class="mers-text mers-return-title">
          투자 시 {{ slider.investPeriod }}개월 기대 후 수익은?
        </h4>
        <div class="mers-return">
          <span>
            <ICountUp
              :endVal="
                slider.amounts[slider.value] *
                  10000 *
                  (slider.expectedReturnOfRate / 100)
              "
            />원
          </span>
          <span class="pl-1">
            <span>(연{{ slider.expectedReturnOfRate }}%) </span>
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      slider: {
        trackColor: '#0977ca',
        value: 3,
        amounts: [0, 10, 50, 100, 500, 1000],
        investPeriod: 12,
        expectedReturnOfRate: 8,
      },
    }
  },
  methods: {
    async setPosition(e) {
      if (e < 1) {
        this.$nextTick(() => {
          this.slider.value = 1
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.main-expected-rate-section {
  padding-top: 62px;
  background-color: #0caeff;
  min-height: 406px;
  position: relative;
  overflow: hidden;
  background-image: url('/assets/images/bg-bg-new-main-top.svg');

  .container {
    position: relative;
    height: 100%;
    padding: 0;
  }

  .v-input {
    margin-top: 90px;
    margin-bottom: 4px;

    .v-messages {
      display: none;
    }
  }
}

::v-deep .v-slider {
  &__thumb {
    width: 25px;
    height: 25px;

    &:before {
      left: -6px;
      top: -5.56px;
    }

    &-label {
      border-radius: 6px;
      transform: translate(-30%, -26px) !important;
      position: absolute;
      display: inline-block;
      font-size: 19px;
      font-weight: 900;
      letter-spacing: -0.41px;
      padding: 6px 16px;
      background-color: white;
      color: #1baaf1;
      left: -16px;
      height: initial !important;
      width: initial !important;

      & > div {
        transform: none;
      }

      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -7px;
        border-width: 7px;
        border-style: solid;
        border-color: white transparent transparent transparent;
      }
    }
  }

  &--horizontal {
    margin-left: 0px;
    margin-right: 0px;
    transform: unset;
  }

  &__track {
    &-container {
      height: 10px !important;
    }

    &-background,
    &-fill {
      border-radius: 6px;
    }
  }
}

.mers-container {
  max-width: 490px;
  margin: 0 auto;
  padding: 0 45px;
}
.mers-container h3.mers-text {
  margin-top: 0px;
  font-size: 25px;
  font-weight: 500;
}
.mers-container .mers-text {
  text-align: center;
  color: #ffffff;
}
.mers-container h1.mers-text {
  margin-top: 10px;
  letter-spacing: -0.23px;
  font-weight: 900;
}
#input-mers-range-bar {
  margin-bottom: 25px;
  outline: none;
  height: 10px;
  border-radius: 6px;
  background-color: white;
  padding: 0;
  cursor: pointer;
}
input[type='range'] {
  display: block;
  width: 100%;
}
.mers-container .mers-return-title {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.51px;
}
.mers-container .mers-return {
  width: 100%;
  height: 56px;
  margin: 23px 0 50px;
  padding: 13px 0 12px;
  border-radius: 8px;
  box-shadow: 0 10px 18px 0 rgba(8, 68, 118, 0.14);
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: baseline;
  line-height: 31px;
  color: #1baaf1;
}
.mers-container .mers-return > span:nth-of-type(1) {
  font-size: 28px;
  font-weight: 900;
  letter-spacing: -0.97px;
}
.mers-container .mers-return > span:nth-of-type(2) {
  font-size: 16px;
  letter-spacing: -0.74px;
}

@media screen and (max-width: 767px) {
  .main-expected-rate-section {
    background-color: #0caeff;
    min-height: 406px;
    position: relative;
    overflow: hidden;
    background-image: url('/assets/images/bg-bg-new-main-top.svg');
    background-size: cover;
  }
}
</style>
