<template>
  <div>
    <div
      v-if="isInProgress == true && myAutoInvest == null"
      class="dashboard-box--pa-0 d-flex justify-center align-center margin-t-5"
      style="height: 528px"
    >
      <MyLoader />
    </div>
    <div v-else>
      <div
        v-if="isAddOrUpdateMode && !!myDashboardData"
        class="custom-popup auto-invest-popup"
        data-popup="vaccountGuide"
        :class="{ show: isAgreeFormShow }"
      >
        <div
          class="auto-invest-popup__contents custom-popup__contents color--gray-3"
        >
          <div
            class="popup-header d-flex justify-space-between align-center padding-x-25 margin-t-30"
          >
            <h2 class="font-size--20 font-weight-black my-0">투자위험 고지</h2>
            <a @click="isAgreeFormShow = false" class="cursor--pointer">
              <img src="@/assets/images/myfunda/btn-close.svg" />
            </a>
          </div>
          <div
            class="auto-invest-popup__btn mt-5 font-size--16 font-weight-black padding-x-25"
          >
            <div class="position--relative">
              <button
                type="button"
                @click="agree.investAgree = !agree.investAgree"
                :class="{ active: agree.investAgree === true }"
              >
                투자약관동의
              </button>
              <router-link
                :to="{ name: 'terms', query: { mode: 'investor' } }"
                target="_blank"
              />
            </div>
            <div class="position--relative">
              <button
                type="button"
                class="margin-t-10"
                @click="agree.autoInvestAgree = !agree.autoInvestAgree"
                :class="{ active: agree.autoInvestAgree === true }"
              >
                자동투자약관 동의
              </button>
              <router-link
                :to="{ name: 'terms', query: { mode: 'auto_invest' } }"
                target="_blank"
              />
            </div>
          </div>
          <div
            class="auto-invest-popup__terms margin-t-15 font-size--15 rounded--8 padding-x-25"
          >
            <div class="padding-x-15 padding-y-15 color--gray-3">
              본 투자상품은 원금이 보장되지 않습니다. 모든 투자상품은 현행 법률
              상 ‘유사수신 행위의 규제에 관한 법률’에 의거하여 원금과 수익을
              보장할 수 없습니다. 또한 차입자가 원금의 전부 또는 일부를 상환하지
              못 할 경우 발생하게 되는 투자금 손실 등 투자 위험은 투자자가
              부담하게 됩니다.
            </div>
          </div>
          <div
            class="auto-invest-popup__agree margin-t-15 font-weight-bold d-flex padding-x-25 align-center"
          >
            <div class="margin-r-10">
              나
              <b class="font-weight-black">{{ myProfile.name }}</b
              >은 상기 내용을 확인하였으며, 그 내용에
            </div>
            <div class="position--relative">
              <input
                type="text"
                class="width-100 rounded--4 text-center font-size--16 color--gray-3 font-weight-black outline-color--funda-blue"
                placeholder="동의함"
                v-model="agree.iAgree"
                @input="inputIAgree"
                @value="agree.iAgree"
              />
            </div>
          </div>
          <p
            class="margin-t-5 mb-5 font-size--13 text-center color--gray"
            style="line-height: 1.5"
          >
            &#42; 빈칸에 &apos;동의함&apos;을 직접 입력해 주세요.
          </p>
          <div class="popup-submit-footer">
            <button
              type="button"
              class="width-100 border--none py-5 font-size--18 font-weight-black outline--none btn-funda--blue"
              @click="setAutoInvest"
              :disabled="cannotSubmit"
            >
              자동투자 시작하기
            </button>
          </div>
        </div>
      </div>
      <div class="dashboard-box margin-t-5">
        <v-row v-if="isNotEventTarget === false" no-gutters>
          <v-col cols="12">
            <div
              class="auto-invest-card auto-invest-card__event mb-4"
              @click="openEventNotice()"
            >
              <p class="mb-2 font-size--15 font-weight-black">
                투자 예치금 1% 포인트백
              </p>

              <p class="ma-0 font-size--13">
                부자언니 6월 이벤트 자세히 보기 &gt;
              </p>
            </div>
          </v-col>
        </v-row>

        <v-row class="margin-b-25" no-gutters>
          <v-col cols="12" sm="12" md="12" lg="12">
            <p
              class="ma-0 color--gray-3 font-weight-black padding-x-10 font-size--18 line-height--25"
              id="goto-auto-invest-title"
            >
              {{
                isAddOrUpdateMode
                  ? '지금 바로 자동 분산투자를 시작해보세요!'
                  : '일상환 상품 자동 분산투자'
              }}
            </p>
          </v-col>
        </v-row>

        <template v-if="myAutoInvest != null">
          <template v-if="isAddOrUpdateMode">
            <v-row>
              <v-col cols="12">
                <p class="mx-0 mb-4 font-size--18">1. 상품유형</p>
              </v-col>
            </v-row>
            <v-row class="margin-b-15">
              <v-col
                cols="12"
                sm="12"
                md="12"
                :class="{ 'margin-b-15': isMobile }"
              >
                <div class="auto-invest-card auto-invest-card__funding">
                  <v-row
                    v-for="autoInvestType in autoInvestTypes"
                    :key="autoInvestType.value"
                    class="mb-5"
                    no-gutters
                  >
                    <v-col cols="12" class="d-flex width-100">
                      <div class="margin-r-10 padding-t-5">
                        <input
                          v-model="userAutoInvests"
                          class="outline-color--funda-blue"
                          type="checkbox"
                          :value="autoInvestType.value"
                        />
                      </div>
                      <div class="width-100">
                        <div
                          class="d-flex flex-column height-100 justify-space-between width-100"
                        >
                          <div class="color--gray-3">
                            <p
                              class="d-flex align-center justify-space-between margin-b-10 cursor--pointer font-size--17 font-weight-black"
                              @click="addOrRemove(autoInvestType.value)"
                            >
                              {{ autoInvestType.name }}
                              <span
                                @click.stop="
                                  autoInvestType.showDesc = !autoInvestType.showDesc
                                "
                              >
                                <v-icon class="font-size--35">
                                  {{
                                    autoInvestType.showDesc
                                      ? 'mdi-chevron-up'
                                      : 'mdi-chevron-down'
                                  }}
                                </v-icon>
                              </span>
                            </p>

                            <div v-show="autoInvestType.showDesc">
                              <template
                                v-if="autoInvestType.value === 'FUNDA_PREMIUM'"
                              >
                                <p
                                  class="margin-b-5 font-size--13 line-height--18"
                                >
                                  - 180일 일상환
                                </p>
                                <p
                                  class="margin-b-5 font-size--13 line-height--18"
                                >
                                  - 연 15%
                                </p>
                                <p
                                  class="margin-b-5 font-size--13 line-height--18"
                                >
                                  - 펀다 대출 2회 이상, 최소 90일간 연체 없이
                                  상환해 온 우수 상점에 투자
                                </p>
                              </template>

                              <template
                                v-else-if="
                                  autoInvestType.value === 'FUNDANOW_DELIVERY'
                                "
                              >
                                <p
                                  class="margin-b-5 font-size--13 line-height--18"
                                >
                                  - 90일 일상환
                                </p>
                                <p
                                  class="margin-b-5 font-size--13 line-height--18"
                                >
                                  - 연 5 ~ 17.9%
                                </p>
                                <p class="ma-0 font-size--13 line-height--18">
                                  - 우량한 배달 매출 상점에 투자하는 상품
                                </p>
                              </template>

                              <template
                                v-else-if="autoInvestType.value === 'FUNDANOW'"
                              >
                                <p
                                  class="margin-b-5 font-size--13 line-height--18"
                                >
                                  - 45일 일상환
                                </p>
                                <p
                                  class="margin-b-5 font-size--13 line-height--18"
                                >
                                  - 연 5 ~ 17.9%
                                </p>
                                <p class="ma-0 font-size--13 line-height--18">
                                  - 상점의 카드매출을 기반으로 상환하는 높은
                                  유동성의 초단기 상품
                                </p>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <p class="mx-0 mb-4 font-size--18">2. 투자유형</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" class="mb-5">
                <div
                  class="auto-invest-card"
                  :class="myAutoInvest.isDiversified ? 'active' : ''"
                  @click="myAutoInvest.isDiversified = true"
                >
                  <div class="d-flex width-100">
                    <div class="margin-r-10">
                      <input
                        class="outline-color--funda-blue"
                        v-model="myAutoInvest.isDiversified"
                        type="radio"
                        :value="true"
                      />
                    </div>
                    <div class="height-100 width-100">
                      <div
                        class="d-flex flex-column height-100 justify-space-between width-100"
                      >
                        <div class="color--gray-3">
                          <p class="ma-0 font-size--17 font-weight-black">
                            완전분산형
                          </p>
                          <p
                            class="margin-t-10 font-size--15 font-weight-normal margin-b-25"
                          >
                            <span class="font-weight-black">
                              보유 예치금 내
                            </span>
                            에서 최대한 분산투자 합니다.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>

              <div
                class="col-12 col-sm-12 col-md-6 col-lg-6"
                :class="{ 'mb-2': isMobile }"
              >
                <div
                  class="auto-invest-card flex-wrap flex-column justify-space-between"
                  :class="myAutoInvest.isDiversified === true ? '' : 'active'"
                  @click="myAutoInvest.isDiversified = false"
                >
                  <div class="d-flex">
                    <div>
                      <input
                        class="outline-color--funda-blue"
                        v-model="myAutoInvest.isDiversified"
                        type="radio"
                        :value="false"
                      />
                    </div>
                    <div class="width-100 height-100 margin-l-10">
                      <div
                        class="d-flex flex-column height-100 justify-space-between"
                      >
                        <div class="color--gray-3">
                          <p class="ma-0 font-size--17 font-weight-black">
                            금액선택형
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="per-product width-100">
                    <div
                      class="rounded--6 background--light-blue pa-2 font-weight-bold font-size--16 color--gray-3"
                    >
                      <div class="d-flex align-center justify-space-between">
                        <span>상점당</span>
                        <div
                          class="input-wrapper position--relative d-flex align-center"
                        >
                          <input
                            class="color--gray font-size--18 font-weight-black outline-color--funda-blue"
                            v-input-currency="myAutoInvest.maxAmountPerProduct"
                            v-model="myAutoInvest.maxAmountPerProduct"
                            placeholder="최소 1"
                            type="tel"
                          />
                        </div>
                        <span v-if="isMobile === false">씩 투자해주세요!</span>
                        <span v-else>씩</span>
                        <span class="d-none small-mobile">
                          &nbsp;투자해주세요!
                        </span>
                      </div>
                      <p
                        :class="isMobile ? 'd-block' : 'd-none'"
                        class="mb-0 margin-t-10"
                      >
                        투자해주세요!
                      </p>

                      <p
                        class="mt-4 mb-0 color--gray font-size--14 line-height--18"
                      >
                        TIP 상점 당 <b>6만원까지</b> 투자 시 <b>절세효과</b>가
                        발생합니다.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </v-row>
          </template>

          <template v-else>
            <v-row
              class="my-auto-invest-card rounded--8 padding-x-25 py-5"
              no-gutters
            >
              <v-col
                cols="12"
                sm="12"
                md="12"
                class="my-0 margin-b-10 font-weight-bold font-weight-black color-funda--basic font-size--16"
              >
                <span class="ani-twinkle font-size--14"> NOW </span>
                <br v-if="isMobile" />
                자동 분산투자를 이용하고 있습니다.
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <p class="my-3 font-weight-bold color--gray-3 font-size--13">
                  나의 설정
                </p>
                <p
                  class="margin-b-10 color--light-gray font-weight-black font-size--16"
                >
                  {{ mySettingAutoInvest }}
                </p>
                <p
                  class="my-0 color--light-gray font-weight-black font-size--16"
                  style="margin-top: 2px"
                >
                  <template v-if="myAutoInvest.isDiversified">
                    완전분산형
                  </template>
                  <template v-else>
                    금액선택형 : 상점당
                    {{ myAutoInvest.maxAmountPerProduct | commaFormat }}만원
                  </template>
                </p>
              </v-col>
            </v-row>
          </template>

          <v-row class="padding-t-5 pb-5" no-gutters>
            <v-col cols="12" sm="12" md="12">
              <div v-if="isAddOrUpdateMode" class="text-center">
                <button
                  class="btn-funda-basic reverse padding-x-25 padding-y-15 font-size--18 rounded--6 font-weight-black"
                  :class="{ 'width-100': isMobile }"
                  type="button"
                  @click="startAutoInvest"
                  :disabled="isInProgress"
                  style="height: 54px"
                >
                  분산투자 시작하기
                </button>
              </div>

              <div
                v-else
                class="auto-invest__update-button-wrapper mt-5"
                :class="{ mobile: isMobile }"
              >
                <button
                  class="btn-funda-basic reverse padding-x-25 padding-y-15 font-size--18 rounded--6 font-weight-black"
                  type="button"
                  @click="isAddOrUpdateMode = true"
                  :disabled="isInProgress"
                  style="height: 54px"
                >
                  설정을 변경합니다
                </button>
                <button
                  class="btn-funda--white padding-x-25 padding-y-15 font-size--18 rounded--6 font-weight-black"
                  type="button"
                  @click="stopAutoInvest"
                  :disabled="isInProgress"
                  style="height: 54px"
                >
                  투자를 중단합니다
                </button>
              </div>
            </v-col>
          </v-row>
        </template>

        <v-row class="auto-invest__panel padding-x-25 padding-y-25" no-gutters>
          <v-col cols="12" sm="12" md="12" lg="12">
            <ul class="pa-0">
              <li class="margin-b-5">
                자동투자는 설정 시각에 따라 당일 혹은 익일부터 진행됩니다.
              </li>
              <li class="margin-b-5">
                펀다 프리미엄 > 펀다 딜리버리 > 펀다나우 순으로 자동투자 됩니다.
              </li>
              <li class="margin-b-5">
                <span class="font-weight-bold">포인트</span>는 예치금보다 우선
                적용되어 투자됩니다. (일 최대 1만 포인트)
              </li>
              <li class="margin-b-5">
                투자 상점은
                <span class="font-weight-bold"> 투자내역 </span>
                에서 확인할 수 있습니다.
              </li>
              <li class="margin-b-5">
                아래의 경우 투자가 진행되지 않을 수 있습니다.
                <ol class="padding-l-10">
                  <li>투자 시점에 보유 예치금이 없을 때</li>
                  <li>초단기 상품 누적 투자금액이 신청 금액을 초과했을 때</li>
                  <li>
                    펀다 총 투자금액이 투자자 개인의 투자한도를 초과했을 때
                  </li>
                  <li>나의 자동투자 순번에 도달하지 않았을 경우</li>
                </ol>
              </li>
            </ul>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AuthTypes } from '@/store/types/auth'
import MyLoader from '@/components/MyLoader'

export default {
  props: {
    myDashboardData: Object,
  },
  components: {
    MyLoader,
  },
  watch: {
    'myAutoInvest.totalAmount': function(v) {
      v = parseInt(v, 10)
      if (this.myLimit != null && v > this.myLimit.totalAmountLimit) {
        this.myAutoInvest.totalAmount = this.myLimit.totalAmountLimit
        this.$swal.basic.alert(
          '최대 투자금액은 ' +
            this.$options.filters.commaFormat(this.myLimit.totalAmountLimit) +
            '만원을 넘을 수 없습니다.'
        )
      }
    },
    'myAutoInvest.maxAmountPerProduct': function(v) {
      v = parseInt(v, 10)
      if (this.myLimit != null && v > this.myLimit.totalAmountLimit) {
        this.myAutoInvest.maxAmountPerProduct = this.myLimit.totalAmountLimit
        this.$swal.basic.alert(
          '최대 투자금액은 ' +
            this.$options.filters.commaFormat(this.myLimit.totalAmountLimit) +
            '만원을 넘을 수 없습니다.'
        )
      }
    },
    myDashboardData: function() {
      this.setByMyDashBoardData()
    },
    userAutoInvests: {
      deep: true,
      handler(v) {
        if (v.length < 1) {
          this.$swal.basic.alert('상품유형은 하나 이상이어야 합니다')
          this.userAutoInvests.push('FUNDA_PREMIUM')
        }
      },
    },
  },
  data() {
    return {
      autoInvestTypes: [
        { value: 'FUNDA_PREMIUM', name: '펀다 프리미엄', showDesc: true },
        { value: 'FUNDANOW_DELIVERY', name: '펀다 딜리버리', showDesc: false },
        { value: 'FUNDANOW', name: '펀다나우', showDesc: false },
      ],
      userAutoInvests: [],
      isInProgress: false,
      isAddOrUpdateMode: true,
      myAutoInvest: null,
      myLimit: null,
      agree: {
        investAgree: false,
        autoInvestAgree: false,
        iAgree: '',
      },
      isAgreeFormShow: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      userIdx: AuthTypes.getters.GET_USER_IDX,
      myProfile: AuthTypes.getters.GET_PROFILE,
      isRuby: AuthTypes.getters.IS_RUBY,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    mySettingAutoInvest() {
      let txt = ''
      this.userAutoInvests.forEach(ua => {
        let type = this.autoInvestTypes.find(el => el.value === ua)

        if (txt === '') {
          txt += type.name
        } else {
          txt += ` + ${type.name}`
        }
      })

      return txt
    },
    isNotEventTarget() {
      // return this.isRuby === false || this.hadOff
      return true
    },
    cannotSubmit() {
      return (
        this.userAutoInvests.length < 1 ||
        this.agree.investAgree === false ||
        this.agree.autoInvestAgree === false ||
        this.agree.iAgree !== '동의함' ||
        this.isInProgress === true
      )
    },
  },
  created() {
    this.setByMyDashBoardData()
    this.fetchData()
  },
  methods: {
    openEventNotice() {
      window.open('https://bit.ly/3pSycPy')
    },
    addOrRemove(type) {
      let idx = this.userAutoInvests.indexOf(type)
      if (idx < 0) {
        this.userAutoInvests.push(type)
      } else {
        this.userAutoInvests.splice(idx, 1)
      }
    },
    setByMyDashBoardData() {
      if (this.myDashboardData == null) {
        return
      }
      this.myLimit = Object.assign({}, this.myProfile.investmentLimit)
      if (this.myProfile.investmentLimit.totalAmountLimit === 0) {
        this.myLimit.totalAmountLimit = 200000
      } else {
        this.myLimit.totalAmountLimit = this.myLimit.totalAmountLimit / 10000
      }
    },
    async fetchData() {
      this.isInProgress = true
      let q = `
              autoInvest: wwwFnAutoInvestInfo {
                isEnabled
                isDiversified
                receivesSms
                totalAmount
                maxAmountPerProduct
              }

              investmentTypes: wwwUserAutoInvests {
                autoInvestmentType
              }
            `

      try {
        let { autoInvest, investmentTypes } = await this.$fundaApi.query(
          gql`{${q}}`
        )

        if (!!autoInvest) {
          if (autoInvest.totalAmount === 0) {
            autoInvest.totalAmount = 1000
          } else {
            autoInvest.totalAmount = autoInvest.totalAmount / 10000
          }

          if (autoInvest.isDiversified === true) {
            autoInvest.maxAmountPerProduct = null
          } else if (autoInvest.maxAmountPerProduct > 0) {
            autoInvest.maxAmountPerProduct =
              autoInvest.maxAmountPerProduct / 10000
          }

          this.myAutoInvest = autoInvest
        } else {
          this.myAutoInvest = {
            isEnabled: false,
            isDiversified: true,
            receivesSms: false,
            totalAmount: 1000,
            maxAmountPerProduct: null,
          }
        }

        if (this.myAutoInvest.isEnabled) {
          if (investmentTypes.length > 0) {
            this.userAutoInvests = []
            investmentTypes.forEach(type => {
              this.userAutoInvests.push(type.autoInvestmentType)
            })
          } else {
            this.userAutoInvests = ['FUNDANOW']
          }
        } else {
          this.userAutoInvests = [
            'FUNDA_PREMIUM',
            'FUNDANOW_DELIVERY',
            'FUNDANOW',
          ]
        }
      } finally {
        this.isAddOrUpdateMode = this.myAutoInvest.isEnabled === false
        this.isInProgress = false
      }
    },
    inputIAgree(e) {
      this.agree.iAgree = e.target.value
    },
    startAutoInvest() {
      if (
        this.myAutoInvest.isDiversified === false &&
        this.myAutoInvest.maxAmountPerProduct == null
      ) {
        this.$swal.basic.alert('상점 당 최소 투자 금액을 입력해주세요.')
        return
      }
      if (
        this.myAutoInvest.isDiversified === true &&
        this.myAutoInvest.totalAmount < 1
      ) {
        this.$swal.basic.alert('최대 투자 금액을 입력해주세요.')
        return
      }
      if (
        this.myAutoInvest.isDiversified === false &&
        this.myAutoInvest.maxAmountPerProduct < 1
      ) {
        this.$swal.basic.alert(
          '금액선택형 자동투자의 상점 당 최소 투자 금액은 10,000원 입니다.'
        )
        return
      }

      this.myAutoInvest.isEnabled = true
      this.isAgreeFormShow = true
    },
    async setAutoInvest() {
      this.isAgreeFormShow = false
      this.isInProgress = true

      let input = Object.assign({}, this.myAutoInvest)

      if (input.isDiversified === true) {
        input.totalAmount = input.totalAmount * 10000
        input.maxAmountPerProduct = 0
      } else {
        input.totalAmount = 0
        input.maxAmountPerProduct = input.maxAmountPerProduct * 10000
      }
      delete input.isDiversified

      let q1 = `
        data: wwwUpdateFnAutoInvest(inputFnAutoInvest: $input)
          {
            isEnabled
            isDiversified
            totalAmount
            maxAmountPerProduct
          }
        `

      let q2 = `
        wwwUpdateUserAutoInvest(autoInvestmentTypes: $input) {
          autoInvestmentType
        }
      `

      try {
        await this.$fundaApi.mutation(
          gql`{${q1}}`,
          'call($input: InputFnAutoInvest)',
          { input: input }
        )

        if (this.myAutoInvest.isEnabled) {
          await this.$fundaApi.mutation(
            gql`{${q2}}`,
            'call($input: [AutoInvestmentType])',
            { input: this.userAutoInvests }
          )
        }

        this.agree = {
          investAgree: false,
          autoInvestAgree: false,
          iAgree: '',
        }
        // this.isInProgress = false 는 fetchData()에서 처리
        await this.fetchData()

        setTimeout(_ => {
          this.$vuetify.goTo('#goto-auto-invest-title', { offset: 50 })
        }, 100)
      } catch (e) {
        this.$swal.basic.warning(e.message)
        this.isInProgress = false
      }
    },
    async stopAutoInvest() {
      let btnResult = await this.$swal.basic.confirm(
        '자동 투자를 중지하시겠습니까?'
      )
      if (btnResult.isConfirmed !== true) {
        return
      }
      this.myAutoInvest.isEnabled = false
      this.setAutoInvest()
    },
  },
}
</script>

<style lang="scss" scoped>
.auto-invest-card {
  min-height: 171px;
  padding: 20px 25px;
  border-radius: 8px;
  border: 1px solid #e1e9f3;
  background-color: #ffffff;

  &__event {
    padding: 10px 15px;
    min-height: unset;
    border-color: #c72f7a;
    background-color: rgb(255, 227, 255, 0.6);
    text-align: center;

    p {
      color: #c72f7a;
      line-height: 1.4;
    }

    br {
      display: none;

      @media screen and (max-width: 320px) {
        display: block !important;
      }
    }
  }

  &__funding {
    min-height: 160px;

    @media screen and (max-width: 768px) {
      min-height: 80px;
    }

    p {
      line-height: 1.3;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .auto-invest-card {
    min-height: 245px;
  }

  .auto-invest-card .per-product > div > div {
    flex-wrap: wrap;
  }

  .auto-invest-card .per-product > div > div > .input-wrapper,
  .auto-invest-card .per-product > div > div > .input-wrapper input {
    width: 100%;
    max-width: 100%;
  }

  .auto-invest-card .per-product > div > div > .input-wrapper input {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 374px) {
  .auto-invest-card .per-product .small-mobile {
    display: block;
    left: 0;
    right: 0;
    margin-right: auto;
  }

  .auto-invest-card .per-product > div > p:last-child {
    display: none;
  }

  .auto-invest-card .per-product > div > div {
    flex-wrap: wrap;
  }

  .auto-invest-card .per-product > div > div > .input-wrapper,
  .auto-invest-card .per-product > div > div > .input-wrapper input {
    width: 100%;
    max-width: 100%;
  }

  .auto-invest-card .per-product > div > div > .input-wrapper input {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.my-auto-invest-card,
.auto-invest-card.active {
  border: solid 1px #1baaf1;
  background-color: #f6faff;
}
.auto-invest-card input[type='radio'],
.auto-invest-card input[type='checkbox'] {
  width: 24px !important;
  height: 24px;
  margin: 0;
  padding: 0 !important;
  border: none !important;
  background: url('~@/assets/images/common/icons/etc-safe-checkbox.svg')
    transparent no-repeat;
  background-size: contain;
  opacity: 1 !important;
  visibility: visible !important;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.auto-invest-card input[type='radio']:checked,
.auto-invest-card input[type='checkbox']:checked {
  background: url('~@/assets/images/common/icons/etc-checkbox-selected.svg')
    transparent no-repeat;
  background-size: contain;
}

.auto-invest-card .input-area::after {
  content: '만원';
  position: absolute;
  bottom: 11px;
  right: 12px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.auto-invest-card input {
  width: 100%;
  padding: 11px 45px 9px 0px;
  border: 1px solid #d7e1ea;
  border-radius: 4px;
  background-color: white;
  text-align: right;
  font-size: 20px;
}
.auto-invest-card input::-moz-placeholder {
  color: #8a94a2;
  padding-bottom: 5px;
  font-weight: lighter;
  font-size: 15px;
  opacity: 1;
}
.auto-invest-card input::-webkit-input-placeholder {
  color: #8a94a2;
  padding-bottom: 5px;
  font-weight: lighter;
  font-size: 15px;
  opacity: 1;
}
.auto-invest-card input::-ms-input-placeholder {
  color: #8a94a2;
  padding-bottom: 5px;
  font-weight: lighter;
  font-size: 15px;
  opacity: 1;
}

.auto-invest__panel {
  border-radius: 4px;
  border: 1px solid #e7edf6;
  background-color: #f6faff;
  color: #565a5c;
}

.auto-invest__panel ul > li::marker {
  content: '∙  ';
  font-size: 14px;
}

.auto-invest-card .per-product input {
  max-width: 127px;
  padding-right: 45px;
  height: 46px;
}

.auto-invest-card .per-product .input-wrapper::after {
  content: '만원';
  position: absolute;
  right: 12px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.auto-invest__update-button-wrapper button {
  width: 35%;
  margin-right: 10px;
}

.auto-invest__update-button-wrapper.mobile button {
  width: 100%;
  margin-bottom: 10px;
}

.auto-invest-popup .auto-invest-popup__contents {
  max-width: 325px !important;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.auto-invest-popup .auto-invest-popup__contents > .popup-header > a {
  z-index: 999;
  position: relative;
}
.auto-invest-popup .auto-invest-popup__btn > div > button {
  border: none;
  display: flex;
  width: 100%;
  align-items: center;
  height: 56px;
  padding: 0 20px;
  border-radius: 8px;
  border: solid 1px #e1e9f3;
  background-color: #ffffff;
  outline: none;
}

.auto-invest-popup .auto-invest-popup__btn > div > button::before {
  content: '';
  width: 22px;
  height: 22px;
  background: url('~@/assets/images/common/icons/etc-safe-checkbox.svg')
    transparent no-repeat;
  background-size: contain;
  margin-right: 8px;
}

.auto-invest-popup .auto-invest-popup__btn > div > button.active::before {
  background: url('~@/assets/images/common/icons/etc-checkbox-selected.svg')
    transparent no-repeat;
  background-size: contain;
}

.auto-invest-popup .auto-invest-popup__btn > div > button::after {
  content: '';
  width: 9px;
  height: 14px;
  background: url('~@/assets/images/common/icons/ic-ic-arrow-r-gray.svg')
    transparent no-repeat;
  background-size: contain;
  margin-left: auto;
}

.auto-invest-popup .auto-invest-popup__btn > div > a {
  position: absolute;
  width: 45px;
  height: 100%;
  top: 0;
  right: 0;
}

.auto-invest-popup .auto-invest-popup__terms > div {
  background-color: #f4faff;
  font-family: AppleSDGothicNeo, sans-serif !important;
  word-break: break-all;
  line-height: 22px;
  letter-spacing: -0.2px;
}

.auto-invest-popup .auto-invest-popup__agree > div {
  word-break: break-all;
}

.auto-invest-popup .auto-invest-popup__agree > div:last-child > input {
  height: 46px;
  border: 1px solid #d7e1ea;
}

.auto-invest-popup
  .auto-invest-popup__agree
  > div:last-child
  > input::placeholder {
  color: #8a94a2;
  font-size: 16px;
  letter-spacing: -0.3px;
  text-align: center;
}

.auto-invest-popup .popup-submit-footer button {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  line-height: 1.2;
  transition: all 0.3s;
}

@media screen and (max-width: 359px) {
  .auto-invest-popup .auto-invest-popup__terms > div {
    line-height: 18px;
    font-size: 12px !important;
  }
  .auto-invest-popup .auto-invest-popup__agree {
    font-size: 12px;
  }
}
</style>
