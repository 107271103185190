<template>
  <div
    class="custom-popup waccount-guide-popup"
    data-popup="vaccountGuide"
    :class="{ show: isVAccountGuideShow }"
    @click="isVAccountGuideShow = false"
  >
    <div
      class="custom-popup__contents waccount-guide-popup__contents"
      @click.stop="isVAccountGuideShow = true"
    >
      <div class="waccount-guide-popup__body">
        <h2 class="font-weight-black mt-0 margin-b-25">내 등록 계좌란?</h2>
        <div class="waccount-guide-popup__body-text">
          <p
            class="margin-b-5 position--relative padding-l-10"
            style="line-height: 1.5"
          >
            펀다에서는 보이스피싱 등 금융사고 방지를&nbsp; 위해
            <b class="font-weight-black">
              지정된 계좌에서만 예치금 입금 및&nbsp; 출금이 가능하도록 제한
            </b>
            하고 있습니다.
          </p>
          <p
            class="margin-b-15 position--relative padding-l-10"
            style="line-height: 1.5"
          >
            등록 계좌는 한 가지로 동일해야 하며,<br />
            별도 지정할 수 없습니다.
          </p>
        </div>
        <div class="waccount-guide-popup__body-diagram text-center mb-5">
          <div class="diagram__circle">
            <div>
              펀다<br />
              지정 계좌
            </div>
          </div>
          <div class="diagram__arrow color-funda--basic">
            <p class="ma-0">입금</p>
            <img src="@/assets/images/myfunda/etc-etc-new-arrow-r-b-slim.svg" />
            <img src="@/assets/images/myfunda/etc-etc-new-arrow-l-b.svg" />
            <p class="ma-0">출금</p>
          </div>
          <div class="diagram__circle">
            <div>
              등록<br />
              계좌
            </div>
          </div>
        </div>
        <div class="waccount-guide-popup__body-diagram text-center">
          <div class="diagram__circle">
            <div>
              나의<br />
              다른 계좌
            </div>
          </div>
          <div class="diagram__arrow">
            <p class="ma-0">입금</p>
            <img src="@/assets/images/myfunda/etc-etc-new-arrow-r-dg.svg" />
            <img src="@/assets/images/myfunda/etc-etc-new-arrow-l-dg.svg" />
            <p class="ma-0">출금</p>
          </div>
          <div class="diagram__circle">
            <div>
              등록<br />
              계좌
            </div>
          </div>
        </div>
      </div>
      <div class="popup-close-footer">
        <button
          type="button"
          class="width-100 btn-funda--black"
          @click.stop="isVAccountGuideShow = false"
        >
          닫기
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVAccountGuideShow: false,
    }
  },
}
</script>

<style lang="scss" scoped>
/* waccount start */
.waccount-guide-popup {
  &__contents {
    max-width: 325px !important;
    max-height: 100% !important;
  }

  &__body {
    padding: 30px 25px;

    h2 {
      font-size: 22px;
      line-height: 31px;
      text-align: center;
    }

    &-text {
      color: #333333;
      font-size: 15px;
      line-height: 23px;
      letter-spacing: -0.3px;

      p::before {
        content: '∙';
        position: absolute;
        left: 3px;
      }
    }

    &-diagram {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 900;

      > .diagram__circle {
        flex: 1 1 98px;
        min-height: 100px;
        border-radius: 50%;
        position: relative;

        ::after {
          content: '';
          display: block;
          padding-bottom: 100%;
        }

        > div {
          display: flex;
          position: absolute;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }
      }

      > .diagram__arrow {
        flex: 1 1 64px;
      }
    }
  }
}

.waccount-guide-popup__body > div:nth-of-type(2) > div:nth-child(2) div {
  height: 14px;
}

.waccount-guide-popup__body > div:nth-of-type(3) > div:nth-child(2) div {
  height: 12px;
}

.waccount-guide-popup__body > div:nth-of-type(2) > div:nth-child(1),
.waccount-guide-popup__body > div:nth-of-type(2) > div:nth-child(3) {
  background-color: #f2f9ff;
}

.waccount-guide-popup__body > div:nth-of-type(3) > div:nth-child(1),
.waccount-guide-popup__body > div:nth-of-type(3) > div:nth-child(3) {
  background-color: #f6f8fa;
}

.waccount-guide-popup__body > div:nth-of-type(3) > div:nth-child(2)::before {
  content: '';
  width: 50px;
  height: 1px;
  background-color: #333333;
  display: block;
  position: absolute;
  transform: rotateZ(132deg);
  top: 28px;
  left: 0;
  right: 0;
  margin: 17px auto;
}

.waccount-guide-popup .popup-close-footer button {
  font-size: 20px;
  font-weight: 900;
  line-height: 22px;
  text-align: center;
  border: none;
  outline: none;
}

@media screen and (max-width: 359px) {
  .waccount-guide-popup .waccount-guide-popup__body {
    padding: 15px 10px;
  }
}
/* waccount end */
</style>
