<template>
  <v-carousel height="100%" show-arrows-on-hover hide-delimiters cycle>
    <v-carousel-item>
      <v-sheet height="100%" color="#D4F0E0">
        <div id="online-inv" class="mkcs-container">
          <div class="mkcs-banner-wrapper">
            <a
              class="mkcs-banner-link"
              target="_blank"
              href="https://blog.naver.com/fundamaster/222667402625"
            ></a>
            <div class="mkcs__text">
              <p class="ma-0 pt-1">
                <span class="font-size--15" style="line-height: 1">
                  2022.03.11
                </span>
                <br />
                <span class="font-size--16" style="line-height: 1.8">
                  세이프플랜 종료 그 이후,
                </span>
                <br />
                오해와 불안 2탄
                <span class="font-weight-black">&lt;수익률편&gt;</span>
              </p>
            </div>
            <img
              style="align-self: center; height: 75%;"
              src="@/assets/images/main/Img-graph--red.png"
            />
          </div>
        </div>
      </v-sheet>
    </v-carousel-item>

    <v-carousel-item>
      <v-sheet height="100%" color="#C9EBEC">
        <div id="online-inv" class="mkcs-container">
          <div class="mkcs-banner-wrapper">
            <a
              class="mkcs-banner-link"
              target="_blank"
              href="https://blog.naver.com/fundamaster/222660049551"
            ></a>
            <div class="mkcs__text">
              <p class="ma-0 pt-1">
                <span class="font-size--15" style="line-height: 1">
                  2022.03.03
                </span>
                <br />
                <span class="font-size--16" style="line-height: 1.8">
                  세이프플랜 종료 그 이후,
                </span>
                <br />
                오해와 불안 1탄
                <span class="font-weight-black">&lt;부실편&gt;</span>
              </p>
            </div>
            <img
              style="align-self: center; height: 75%;"
              src="@/assets/images/main/img-recession.png"
            />
          </div>
        </div>
      </v-sheet>
    </v-carousel-item>

    <v-carousel-item>
      <v-sheet height="100%" color="#FDF7D2">
        <div id="online-inv" class="mkcs-container">
          <div class="mkcs-banner-wrapper">
            <a
              class="mkcs-banner-link"
              target="_blank"
              href="https://bit.ly/3ktEWRE"
            ></a>
            <div class="mkcs__text">
              <p class="ma-0 pt-1">
                <span class="font-size--15" style="line-height: 1.8">
                  2021.08.26
                </span>
                <br />
                <b>펀다, 온투업 라이센스 획득</b>
              </p>
            </div>
            <img
              style="align-self: center; height: 75%;"
              src="@/assets/images/main/img-online-inv.svg"
            />
          </div>
        </div>
      </v-sheet>
    </v-carousel-item>

    <v-carousel-item>
      <v-sheet height="100%" color="#fff49e">
        <div class="container height-100 pa-0">
          <div class="mkcs-container">
            <div class="mkcs-banner-wrapper">
              <a class="mkcs-banner-link" href="https://bit.ly/2Cjfmwo"></a>
              <span>
                <b>플러스 친구 추가</b>하고<br />
                펀다 소식을 받아보세요!
              </span>
              <img
                class="kakao-banner"
                src="@/assets/images/main/img-new-kakao-ch2@2x.png"
              />
            </div>
          </div>
        </div>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.carousel__picture picture img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.mkcs-container {
  max-width: 790px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
}
.mkcs-banner-wrapper {
  width: 700px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 54px;
  position: relative;
}
.mkcs-container .mkcs-banner-link {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
}

.mkcs-container .mkcs-banner-wrapper .mkcs__text {
  display: flex;
  align-items: center;
}

.mkcs-container .mkcs-banner-wrapper .mkcs__text p {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.51px;
  color: #333333;

  @media screen and (max-width: 480px) {
    font-size: 19px;
  }
}

.mkcs-container .mkcs-banner-wrapper span {
  align-self: center;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: -0.51px;
  color: #333333;
}
.mkcs-container .mkcs-banner-wrapper span b {
  font-weight: 900;
}
.mkcs-container .mkcs-banner-wrapper img {
  resize: both;
}
.mkcs-container .mkcs-banner-wrapper img.kakao-banner {
  align-self: flex-end;
  height: 75%;
  margin-right: 30px;
}

@media screen and (max-width: 767px) {
  .mkcs-container .mkcs-banner-wrapper img.kakao-banner {
    margin-right: 0;
  }
}

@media screen and (max-width: 480px) {
  .mkcs-container .mkcs-banner-wrapper {
    padding: 0;
  }
}

/* event style start */
#online-inv .mkcs-banner-wrapper {
  @media screen and (max-width: 480px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  img {
    @media screen and (max-width: 480px) {
      height: 55% !important;
    }
  }
}
/* event style end */
</style>
