<template>
  <section class="main-banner-section">
    <FundaCarousel />
  </section>
</template>

<script>
import FundaCarousel from '@/components/FundaCarousel'

export default {
  components: {
    FundaCarousel,
  },
}
</script>

<style lang="scss" scoped>
.main-banner-section {
  display: flex;
  width: 100%;
  height: 140px;
}

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}
.msbs-swiper-container > div.msbs-swiper-button-prev {
  left: 0;
  right: 715px;
}
.msbs-swiper-container .msbs-swiper-navigation {
  position: absolute;
  cursor: pointer;
  z-index: 2;
  width: 15px;
  margin: 0 auto;
}
.msbs-swiper-container > div.msbs-swiper-button-next {
  left: 715px;
  right: 0;
}
.msbs-swiper-container .msbs-swiper-navigation {
  position: absolute;
  cursor: pointer;
  z-index: 2;
  width: 15px;
  margin: 0 auto;
}
.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}

@media screen and (max-width: 767px) {
  .msbs-swiper-navigation {
    display: none;
  }
}
</style>
